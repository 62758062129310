import { BigNumber, constants } from 'ethers';
import { useMemo } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';
import useDynamicNftCollectionContext from '../useDynamicNftCollectionContext';

type StakedTraitsCallResult = {
  traitId: BigNumber;
  traitContract: string;
};

const useGetStakedTraitsContractCall = (
  dynamicNftCollectionAddress: string,
  dynamicNftTokenId: number
): [string[] | undefined, () => void] => {
  const { traitCollectionsByAddress } = useDynamicNftCollectionContext();

  const [{ data }, readStakedTraits] = useContractRead(
    {
      addressOrName: config.contracts.traitSwapContract.address,
      contractInterface: config.contracts.traitSwapContract.interface,
    },
    'getStakedTraits',
    {
      args: [dynamicNftCollectionAddress, dynamicNftTokenId],
    }
  );

  const stakedTraitIds = useMemo(() => {
    if (data && traitCollectionsByAddress) {
      const stakedTraits: StakedTraitsCallResult[] = data[1];

      return stakedTraits
        .filter(
          ({ traitContract }: { traitContract: string }) =>
            traitContract !== constants.AddressZero &&
            traitCollectionsByAddress[traitContract]
        )
        .map(({ traitId, traitContract }) => {
          const collection = traitCollectionsByAddress[traitContract];
          return `${collection.traitCollectionId}:${traitId.toString()}`;
        });
    }

    return [];
  }, [data, traitCollectionsByAddress]);

  return [stakedTraitIds, readStakedTraits];
};

export default useGetStakedTraitsContractCall;

/* eslint-disable react-hooks/exhaustive-deps */
import { AspectRatio, Box, ModalProps, useTimeout } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import RefreshCountdown from './RefreshCountdown';
import SimplePopup from './SimplePopup';
import TraitsPreview from './TraitsPreview';
import useCacheContext from '../hooks/useCacheContext';

type StakeTraitsSuccessPopupProps = Omit<ModalProps, 'children' | 'onClose'> & {
  title: string;
  description?: string;
  dynamicNftTokenId: number;
  newTraitIds: string[];
  withConfetti?: boolean;
};

const StakeTraitsSuccessPopup = ({
  title,
  description,
  dynamicNftTokenId,
  newTraitIds,
  withConfetti = false,
  ...rest
}: StakeTraitsSuccessPopupProps) => {
  const { restoreCacheId } = useCacheContext();
  const [isReady, setIsReady] = useState(false);

  useTimeout(() => {
    setIsReady(true);
  }, 1000);

  useEffect(() => {
    restoreCacheId();
  }, []);

  const celebration = useMemo(
    () =>
      isReady && withConfetti ? (
        <Box position="absolute" left="50%" top="4rem">
          <ConfettiExplosion
            floorWidth={800}
            floorHeight={1000}
            particleCount={70}
          />
        </Box>
      ) : null,
    [isReady, withConfetti]
  );

  return (
    <SimplePopup
      title={title}
      description={description}
      onClose={() => {}}
      scrollBehavior="inside"
      isClosable={false}
      {...rest}
    >
      {celebration}

      <AspectRatio w="full" ratio={1} mt="4">
        <TraitsPreview
          traitIds={newTraitIds}
          imagesSize="512"
          w="full"
          h="full"
        />
      </AspectRatio>

      <RefreshCountdown pt="3" />
    </SimplePopup>
  );
};

export default StakeTraitsSuccessPopup;

import * as categoriesApi from '../../api/categoriesApi';
import Category from '../../types/category';
import useApiCall from '../useApiCall';

const useFetchCategoriesApiCall = (collectionAddress: string) =>
  useApiCall<Category[], string>(
    categoriesApi.fetchCategories,
    collectionAddress
  );

export default useFetchCategoriesApiCall;

import useTraitCollectionContext from '../useTraitCollectionContext';
import useHoneyAllowanceContractCall from './useHoneyAllowanceContractCall';

const useHoneyTraitsSaleAllowanceContractCall = (address?: string) => {
  const { traitCollection } = useTraitCollectionContext();
  return useHoneyAllowanceContractCall(
    traitCollection.saleContractAddress,
    address
  );
};

export default useHoneyTraitsSaleAllowanceContractCall;

import config from '../constants/baseConfig';
import { Trait } from '../types';

export const addOrReplaceTrait = (
  currentTraitIds: string[],
  newTraitId: string,
  traitsById: Record<string, Trait>
): string[] => {
  if (currentTraitIds.includes(newTraitId)) {
    return currentTraitIds;
  }

  const newTrait = traitsById[newTraitId];
  const traitIds: string[] = [];

  config.traits.traitCategoriesIds.forEach(categoryId => {
    if (categoryId === newTrait.category) {
      traitIds.push(newTraitId);
    } else {
      const currentTraitId = currentTraitIds.find(traitId => {
        const currentTrait = traitsById[traitId];
        return currentTrait.category === categoryId;
      });

      if (currentTraitId) {
        traitIds.push(currentTraitId);
      }
    }
  });

  return traitIds;
};

export const removeOrReplaceTrait = (
  originalTraitIds: string[],
  currentTraitIds: string[],
  traitIdToRemove: string,
  traitsById: Record<string, Trait>
): string[] => {
  if (originalTraitIds.includes(traitIdToRemove)) {
    return originalTraitIds;
  }

  const traitToRemove = traitsById[traitIdToRemove];
  const traitIds: string[] = [];

  currentTraitIds.forEach(traitId => {
    if (traitId === traitIdToRemove) {
      const originalTraitId = originalTraitIds.find(traitId => {
        const originalTrait = traitsById[traitId];
        return originalTrait.category === traitToRemove.category;
      });

      if (originalTraitId) {
        traitIds.push(originalTraitId);
      }
    } else {
      traitIds.push(traitId);
    }
  });

  return traitIds;
};

export const applyTraitsSwaps = (
  originalTraitIds: string[],
  newTraitIds: string[],
  traitsById: Record<string, Trait>
): string[] => {
  if (!newTraitIds.length) {
    return originalTraitIds;
  }

  let changedTraitIds: string[] = [...originalTraitIds];

  newTraitIds.forEach(traitId => {
    changedTraitIds = addOrReplaceTrait(changedTraitIds, traitId, traitsById);
  });

  return changedTraitIds;
};

export const applyTraitsUnswaps = (
  originalTraitIds: string[],
  currentTraitIds: string[],
  traitIdsToRemove: string[],
  traitsById: Record<string, Trait>
): string[] => {
  if (!traitIdsToRemove.length) {
    return originalTraitIds;
  }

  let changedTraitIds: string[] = [...currentTraitIds];

  traitIdsToRemove.forEach(traitId => {
    changedTraitIds = removeOrReplaceTrait(
      originalTraitIds,
      changedTraitIds,
      traitId,
      traitsById
    );
  });

  return changedTraitIds;
};

export const getTokenIdFromTraitId = (traitId: string) =>
  parseInt(traitId.split(':').pop() || '', 10);

import axios from 'axios';
import config from '../constants/baseConfig';
import { TraitWithQuantity } from '../types/trait';

export type FetchWalletTraitsReqData = {
  walletAddress: string;
  dynamicNftCollectionAddress: string;
  traitCollectionAddress?: string;
};

export const fetchWalletTraits = async ({
  walletAddress,
  dynamicNftCollectionAddress,
  traitCollectionAddress,
}: FetchWalletTraitsReqData): Promise<TraitWithQuantity[]> => {
  const res = await axios.get<TraitWithQuantity[]>(
    `/wallets/${walletAddress}/traits`,
    {
      baseURL: config.urls.traitswapApiUrl,
      params: {
        nftCollectionAddress: dynamicNftCollectionAddress,
        traitCollectionAddress,
      },
    }
  );

  return res.data;
};

export const fetchStakedFancyBears = async (
  walletAddress: string
): Promise<number[]> => {
  const res = await axios.get<{ nftId: string }[]>(
    `/wallets/${walletAddress}/staking-tokens?status=staked`,
    {
      baseURL: config.urls.fancyBearStakingApiUrl,
    }
  );

  return res.data.map(({ nftId }) => parseInt(nftId, 10));
};

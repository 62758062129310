import { Box, BoxProps, Center, HStack, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FaCheck } from 'react-icons/fa';

export type IncentiveTileProps = BoxProps & {
  title: string;
  icon?: React.ReactElement;
  status?: 'active' | 'inactive' | 'done';
  details?: React.ReactElement | 'string';
};

const IncentiveTile = ({
  title,
  status = 'active',
  icon = <Icon as={FaCheck} w="3" h="3" />,
  details,
  children,
  ...rest
}: IncentiveTileProps) => (
  <HStack justify="space-between" spacing="3">
    <Box
      borderRadius="lg"
      bg="dark.800"
      color="bright.50"
      p="3"
      w="full"
      {...rest}
    >
      <HStack justify="space-between" spacing="3">
        <HStack spacing="3">
          <Center
            w="6"
            h="6"
            borderRadius="full"
            bg={status === 'done' ? 'primary.500' : 'dark.300'}
            flex="none"
          >
            {React.cloneElement(icon, { color: 'dark.800' })}
          </Center>

          <Box>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              lineHeight="shorter"
              opacity={status === 'inactive' ? '0.6' : '1'}
            >
              {title}
            </Text>

            {details}
          </Box>
        </HStack>

        <Box>{children}</Box>
      </HStack>
    </Box>
  </HStack>
);

export default IncentiveTile;

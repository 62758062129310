import axios from 'axios';
import config from '../constants/baseConfig';
import { Trait, V2 } from '../types';

export type FetchDynamicNftReqData = {
  dynamicNftTokenId: number;
  collectionAddress: string;
};

export const fetchDynamicNft = async ({
  collectionAddress,
  dynamicNftTokenId,
}: FetchDynamicNftReqData): Promise<V2> => {
  const res = await axios.get<V2>(
    `/nft-collections/${collectionAddress}/nfts/${dynamicNftTokenId}`,
    {
      baseURL: config.urls.traitswapApiUrl,
    }
  );

  return {
    ...res.data,
    tokenId: res.data.nftId,
  };
};

export const fetchDynamicNftIds = async (
  collectionAddress: string
): Promise<number[]> => {
  const res = await axios.get<number[]>(
    `/nft-collections/${collectionAddress}/ids`,
    {
      baseURL: config.urls.traitswapApiUrl,
    }
  );

  return res.data;
};

export const fetchTraits = async (
  collectionAddress: string
): Promise<Trait[]> => {
  const res = await axios.get<Trait[]>(
    `/nft-collections/${collectionAddress}/traits`,
    {
      baseURL: config.urls.traitswapApiUrl,
    }
  );

  return res.data;
};

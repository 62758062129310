import {
  Button,
  Center,
  Container,
  Heading,
  Icon,
  Image,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { FaPlay } from 'react-icons/fa';
import { useConnect } from 'wagmi';
import CoinbaseWalletIcon from '../components/CoinbaseWalletIcon';
import HowItWorksPopup from '../components/HowItWorksPopup';
import JoinTheClubPanel from '../components/JoinTheClubPanel';
import MetaMaskIcon from '../components/MetaMaskIcon';
import OrDivider from '../components/OrDivider';
import Panel from '../components/Panel';
import ReserveV2Panel from '../components/ReserveV2Panel';
import TrustWalletIcon from '../components/TrustWalletIcon';
import WalletConnectIcon from '../components/WalletConnectIcon';
import config from '../constants/baseConfig';
import usePageTracking from '../hooks/usePageTracking';
import useSnackbarRpcError from '../hooks/useSnackbarRpcError';
import useTranslate from '../hooks/useTranslate';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';

const connectorIcons: Record<string, React.FC> = {
  MetaMask: MetaMaskIcon,
  WalletConnect: WalletConnectIcon,
  'Coinbase Wallet': CoinbaseWalletIcon,
  'Trust Wallet': TrustWalletIcon,
};

const ConnectPage = () => {
  const translate = useTranslate();
  const [{ data: connectData, error }, connect] = useConnect();
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useBoolean(false);

  usePageTracking();
  useSnackbarRpcError(error);

  return (
    <>
      <Container
        display="flex"
        pt="12"
        pb="12"
        maxW="md"
        flex="1"
        alignItems="center"
      >
        <VStack w="full" align="center" spacing="6">
          <Image
            src={`${config.urls.publicUrl}/connect-logo.svg`}
            w="40"
            h="auto"
          />

          <Center flexDir="column">
            <Image
              src={`${config.urls.publicUrl}/connect-illustration.png`}
              w="full"
              maxW="100%"
            />
          </Center>

          {!isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
            <VStack textAlign="center" mb="6" mt="1" color="dark.900">
              <Heading fontSize="3xl" w="full" textAlign="center">
                {translate('connect:appTitle')}
              </Heading>

              <Text opacity="0.8" fontWeight="semibold">
                {translate('connect:appDescription')}
              </Text>
            </VStack>
          )}

          {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
            <Panel w="full" p="6">
              <VStack w="full" spacing="3">
                <Heading fontSize="lg" maxW="16em" textAlign="center">
                  {translate('howItWorks:title')}
                </Heading>

                <Button
                  leftIcon={<Icon as={FaPlay} />}
                  size="sm"
                  onClick={setIsVideoPopupOpen.on}
                  variant="outline"
                  colorScheme="primary"
                >
                  {translate('howItWorks:video')}
                </Button>
              </VStack>
            </Panel>
          )}

          <Panel p="6">
            <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
              {translate('connect:title')}
            </Heading>

            <VStack w="full" align="stretch" spacing="3">
              {connectData.connectors.map(connector => {
                return (
                  <Button
                    key={connector.id}
                    onClick={() => connect(connector)}
                    isDisabled={!connector.ready}
                    size="lg"
                    w="full"
                    iconSpacing="3"
                    variant="outline"
                    colorScheme="primary"
                    borderRadius="full"
                    leftIcon={
                      <Icon as={connectorIcons[connector.name]} w="8" h="8" />
                    }
                  >
                    {translate('connect:button:label', {
                      connector: connector.name,
                    })}
                  </Button>
                );
              })}
            </VStack>

            {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
              <>
                <OrDivider my="3" />
                <ReserveV2Panel bg="dark.800" />
              </>
            )}
          </Panel>

          {isFeatureEnabled(FeatureFlag.DynamicNftSale) && <JoinTheClubPanel />}
        </VStack>
      </Container>

      <HowItWorksPopup
        isOpen={isVideoPopupOpen}
        onClose={setIsVideoPopupOpen.off}
      />
    </>
  );
};

export default ConnectPage;

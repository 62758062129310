import React from 'react';
import { useConnect, useNetwork } from 'wagmi';
import UnsupportedNetworkPopup from '../components/UnsupportedNetworkPopup';

const NetworkErrorsContainer: React.FC = () => {
  const [
    {
      data: { connected: isConnected },
    },
  ] = useConnect();
  const [network] = useNetwork();
  const isWrongNetworkErrorOpen =
    isConnected && !!network.data.chain?.unsupported;

  return isWrongNetworkErrorOpen ? <UnsupportedNetworkPopup isOpen /> : null;
};

export default NetworkErrorsContainer;

import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { useMemo } from 'react';
import {
  calculateHoneyConsumptionRequirement,
  calculateLevel,
} from '../utils/levelUtils';

const useFancyBearLevelDetailsUtil = (consumedHoney?: BigNumber) =>
  useMemo(() => {
    if (!consumedHoney) {
      return;
    }

    const currentLevel = calculateLevel(consumedHoney);
    const nextLevel = currentLevel + 1;

    const currentLevelHoneyRequirement =
      calculateHoneyConsumptionRequirement(currentLevel);
    const nextLevelHoneyRequirement =
      calculateHoneyConsumptionRequirement(nextLevel);

    const honeyAmountToReachNextLevel =
      nextLevelHoneyRequirement.sub(consumedHoney);

    const honeyDifferenceBetweenCurrentAndNextLevel =
      nextLevelHoneyRequirement.sub(currentLevelHoneyRequirement);

    const nextLevelProgress = Math.round(
      (parseInt(
        formatEther(consumedHoney.sub(currentLevelHoneyRequirement)),
        10
      ) /
        parseInt(
          formatEther(
            nextLevelHoneyRequirement.sub(currentLevelHoneyRequirement)
          ),
          10
        )) *
        100
    );

    return {
      currentLevel,
      nextLevel,
      consumedHoney,
      nextLevelHoneyRequirement,
      currentLevelHoneyRequirement,
      honeyAmountToReachNextLevel,
      honeyDifferenceBetweenCurrentAndNextLevel,
      nextLevelProgress,
    };
  }, [consumedHoney]);

export default useFancyBearLevelDetailsUtil;

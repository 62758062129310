import { Flex, FlexProps, Icon, IconProps, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import useCurrencyContext from '../hooks/useCurrencyContext';
import { Currency } from '../types';
import { getCurrencyIcon } from '../utils/currencyUtils';
import EtherAmount from './EtherAmount';

type EtherPriceProps = FlexProps & {
  amount?: BigNumber;
  asPrice?: boolean;
  currency?: Currency;
  iconColor?: IconProps['color'];
  iconSize?: IconProps['w'];
};

const EtherPrice = ({
  amount,
  asPrice,
  currency,
  iconColor,
  iconSize,
  ...rest
}: EtherPriceProps) => {
  const { currency: currentCurrency } = useCurrencyContext();

  return (
    <Flex align="center" gap="0.5" flex="none" {...rest}>
      <Icon
        as={getCurrencyIcon(currency || currentCurrency)}
        color={iconColor}
        w={iconSize}
        h={iconSize}
      />
      <Text>
        <EtherAmount amount={amount} asPrice={asPrice} />
      </Text>
    </Flex>
  );
};

export default EtherPrice;

import { useEffect, useMemo } from 'react';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import config from '../../constants/baseConfig';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';

export type StakeTraitsCallData = {
  dynamicNftTokenId: number;
  dynamicNftCollectionAddress: string;
  traitCollectionAddresses: string[];
  traitTokenIdsPerCollection: number[][];
};

const useStakeTraitsContractCall = (
  callData?: StakeTraitsCallData,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const args = useMemo(
    () =>
      callData?.dynamicNftCollectionAddress &&
      callData?.dynamicNftTokenId &&
      callData?.traitCollectionAddresses &&
      callData?.traitTokenIdsPerCollection
        ? [
            [callData.dynamicNftCollectionAddress],
            [callData.dynamicNftTokenId.toString()],
            callData.traitCollectionAddresses,
            callData.traitTokenIdsPerCollection.map(tokenIds =>
              tokenIds.map(tokenId => tokenId.toString())
            ),
          ]
        : undefined,
    [
      callData?.dynamicNftCollectionAddress,
      callData?.dynamicNftTokenId,
      callData?.traitCollectionAddresses,
      callData?.traitTokenIdsPerCollection,
    ]
  );

  const [{ data, loading: isLoading, error: writeError }, stake] =
    useContractWrite(
      {
        addressOrName: config.contracts.traitSwapContract.address,
        contractInterface: config.contracts.traitSwapContract.interface,
      },
      'stakeTraits',
      { args }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      confirmations: 1,
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(config.contracts.traitSwapContract.address, 'stakeTraits', args);

  console.log('stakeTraits', writeError);
  console.log('stakeTraits', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData, onSuccess]);

  return [stake, isLoading || isWaiting];
};

export default useStakeTraitsContractCall;

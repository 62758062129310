import {
  Box,
  ModalProps,
  SimpleGrid,
  useTimeout,
  VStack,
} from '@chakra-ui/react';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import RefreshCountdown from './RefreshCountdown';
import SimplePopup from './SimplePopup';
import StaticNftCard from './StaticNftCard';

type MintSuccessPopupProps = Omit<ModalProps, 'children'> & {
  tokenIds: number[];
  onSuccess: () => void;
};

const MintSuccessPopup = ({
  tokenIds,
  onSuccess,
  ...rest
}: MintSuccessPopupProps) => {
  const translate = useTranslate();
  const [isReady, setIsReady] = useState(false);

  useTimeout(() => setIsReady(true), 1000);

  const celebration = useMemo(
    () =>
      isReady ? (
        <Box position="absolute" left="50%" top="4rem">
          <ConfettiExplosion
            floorWidth={700}
            floorHeight={1000}
            particleCount={70}
          />
        </Box>
      ) : null,
    [isReady]
  );

  return (
    <SimplePopup
      title={translate('mint:success:title')}
      isClosable={false}
      description={
        tokenIds.length > 1
          ? translate('mint:success:description:multi', {
              dynamicNftName: pluralize(
                config.dynamicNft.dynamicNftName,
                tokenIds.length
              ),
            })
          : translate('mint:success:description', {
              tokenId: tokenIds[0],
              dynamicNftName: pluralize(
                config.dynamicNft.dynamicNftName,
                tokenIds.length
              ),
            })
      }
      scrollBehavior="inside"
      {...rest}
    >
      {celebration}

      <VStack pt="3" spacing="6">
        <SimpleGrid
          columns={tokenIds.length > 1 ? 2 : 1}
          spacing="2"
          w="full"
          borderRadius="lg"
        >
          {tokenIds.map(tokenId => (
            <StaticNftCard key={tokenId} p="0" tokenId={tokenId} hasV2Badge />
          ))}
        </SimpleGrid>

        <RefreshCountdown />
      </VStack>
    </SimplePopup>
  );
};

export default MintSuccessPopup;

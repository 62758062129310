import { BigNumber } from 'ethers';
import React from 'react';

export type WalletAssetsContextValue = {
  ethBalance?: BigNumber;
  honeyBalance?: BigNumber;
  readHoneyBalance: () => void;
  erc20Balance?: BigNumber;
  readErc20Balance: () => void;
  staticNftsInWallet?: number[];
  readStaticNftsInWallet: () => void;
  dynamicNftsInWallet?: number[];
  readDynamicNftsInWallet: () => void;
  fancyBearsInWallet?: number[];
  readFancyBearsInWallet: () => void;
  honeyJarsInWallet?: number[];
  readHoneyJarsInWallet: () => void;
  stakedFancyBears?: number[];
  fetchStakedFancyBears: () => void;
  allOwnedFancyBears?: number[];
  isLoading: boolean;
};

// @ts-ignore
const WalletAssetsContext = React.createContext<WalletAssetsContextValue>();

WalletAssetsContext.displayName = 'WalletAssetsContext';

export default WalletAssetsContext;

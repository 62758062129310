import { useInView } from 'react-cool-inview';

type InViewCheckerProps = {
  children: (isInView: boolean) => React.ReactElement;
};

const InViewChecker = ({ children }: InViewCheckerProps) => {
  const { observe, inView } = useInView({
    threshold: 0,
    onEnter: ({ unobserve }) => {
      unobserve();
    },
  });

  return <div ref={observe}>{children(inView)}</div>;
};

export default InViewChecker;

import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';
import useBigNumbersSumUtil from '../useBigNumbersSumUtil';

const useHiveBalanceInTokensOfCollectionContractCall = (
  collectionContractAddress: string,
  tokenIds?: number[]
): [
  balanceInTokens: Record<number, BigNumber> | undefined,
  balanceInTokensSum: BigNumber | undefined,
  readBalanceInTokens: () => void
] => {
  const [balanceInTokens, setBalance] = useState<Record<number, BigNumber>>();
  const balanceInTokensSum = useBigNumbersSumUtil(
    balanceInTokens ? Object.values(balanceInTokens) : []
  );

  const args = useMemo(() => {
    if (!tokenIds) {
      return undefined;
    }

    return [collectionContractAddress, tokenIds];
  }, [tokenIds, collectionContractAddress]);

  const [{ data: balanceInTokensData }, readBalanceInTokens] = useContractRead(
    {
      addressOrName: config.contracts.hiveContract.address,
      contractInterface: config.contracts.hiveContract.interface,
    },
    'getBalanceByTokenIdsOfCollection',
    {
      args,
      skip: true,
    }
  );

  useEffect(() => {
    if (args) {
      readBalanceInTokens();
    }
  }, [args, readBalanceInTokens]);

  useEffect(() => {
    if (tokenIds && balanceInTokensData !== undefined) {
      const data = balanceInTokensData as [BigNumber[], BigNumber[]];

      setBalance(
        tokenIds.reduce(
          (prev, tokenId, i) => ({
            ...prev,
            [tokenId]: data[i],
          }),
          {}
        ) as Record<number, BigNumber>
      );
    }
  }, [tokenIds, balanceInTokensData]);

  return [balanceInTokens, balanceInTokensSum, readBalanceInTokens];
};

export default useHiveBalanceInTokensOfCollectionContractCall;

import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  useBoolean,
} from '@chakra-ui/react';

import BadgeIcon from '../components/BadgeIcon';
import BoldSpinner from '../components/BoldSpinner';
import { FaInfo } from 'react-icons/fa';
import FancyBearLevel from '../components/FancyBearLevel';
import FancyBearLevelDetailsPopup from '../components/FancyBearLevelDetailsPopup';
import config from '../constants/baseConfig';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useFancyBearLevelDetailsUtil from '../hooks/useFancyBearLevelDetailsUtil';
import useGetConsumedERC20ContractCall from '../hooks/bc/useGetConsumedERC20ContractCall';

const FancyBearLevelContainer = () => {
  const { dynamicNft } = useDynamicNftContext();

  const [isLevelDetailsPopupOpen, setLevelDetailsPopupOpen] = useBoolean();

  const [consumedHoney] = useGetConsumedERC20ContractCall(
    config.contracts.fancyBearsContract.address,
    dynamicNft.tokenId
  );

  const levelDetails = useFancyBearLevelDetailsUtil(consumedHoney);

  const isLoading = levelDetails === undefined;

  return (
    <>
      <Box w="full" px="1.5" py="1">
        <HStack w="full" spacing="0">
          {isLoading ? (
            <Center w="10" h="10" bg="dark.800" borderRadius="full">
              <BoldSpinner />
            </Center>
          ) : (
            <>
              <Center
                w="10"
                h="10"
                bg="primary.500"
                flex="none"
                borderRadius="full"
                mr="3"
                display={{
                  base: 'none',
                  sm: 'flex',
                }}
              >
                <Icon as={BadgeIcon} w="6" h="auto" color="dark.600" />
              </Center>

              <Box w="full">
                <FancyBearLevel
                  level={levelDetails?.currentLevel}
                  nextLevelHoneyRequirement={
                    levelDetails?.nextLevelHoneyRequirement
                  }
                  nextLevelProgress={levelDetails?.nextLevelProgress}
                />
              </Box>

              <IconButton
                aria-label=""
                pl="3"
                minW="auto"
                icon={<Icon as={FaInfo} w="4" h="auto" />}
                variant="link"
                onClick={setLevelDetailsPopupOpen.on}
              />
            </>
          )}
        </HStack>
      </Box>

      {isLevelDetailsPopupOpen && consumedHoney && (
        <FancyBearLevelDetailsPopup
          isOpen
          consumedHoney={consumedHoney}
          onClose={setLevelDetailsPopupOpen.off}
        />
      )}
    </>
  );
};

export default FancyBearLevelContainer;

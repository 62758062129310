import { Box, Button, ModalProps } from '@chakra-ui/react';
import useTranslate from '../hooks/useTranslate';
import IncentivesContainer from './IncentivesContainer';
import SimplePopup from './SimplePopup';

type IncentivesPopupProps = Omit<ModalProps, 'children'> & {};

const IncentivesPopup = ({ onClose, ...rest }: IncentivesPopupProps) => {
  const translate = useTranslate();

  return (
    <SimplePopup
      size="md"
      title={translate('incentives:title')}
      description={translate('incentives:description')}
      onClose={onClose}
      {...rest}
    >
      <Box mt="4">
        <IncentivesContainer />

        <Button w="full" colorScheme="dark" mt="6" onClick={onClose}>
          {translate('common:close')}
        </Button>
      </Box>
    </SimplePopup>
  );
};

export default IncentivesPopup;

import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useContract, useProvider } from 'wagmi';
import config from '../../constants/baseConfig';

const useStaticNftsInWalletContractCall = (
  walletAddress?: string
): [tokenIds: number[] | undefined, readNftsInWallet: () => void] => {
  const [tokenIds, setTokenIds] = useState<number[]>();
  const provider = useProvider();
  const contract = useContract({
    addressOrName: config.contracts.staticNftContract.address,
    contractInterface: config.contracts.staticNftContract.interface,
    signerOrProvider: provider,
  });

  const readNftsInWallet = useCallback(async () => {
    if (!contract || !walletAddress) {
      return;
    }

    try {
      const tokens: number[] = [];
      const balance: number = (
        (await contract.balanceOf(walletAddress)) as BigNumber
      ).toNumber();
      if (balance === 0) {
        setTokenIds([]);
        return;
      }

      for (let i = 0; i < balance; i++) {
        const tokenId: BigNumber = await contract.tokenOfOwnerByIndex(
          walletAddress,
          i
        );
        tokens.push(tokenId.toNumber());
      }

      setTokenIds(tokens);
    } catch (err) {}
  }, [walletAddress, contract]);

  useEffect(() => {
    readNftsInWallet();
  }, [readNftsInWallet]);

  return [tokenIds, readNftsInWallet];
};

export default useStaticNftsInWalletContractCall;

import axios from 'axios';
import config from '../constants/baseConfig';
import Category from '../types/category';

export const fetchCategories = async (
  collectionAddress: string
): Promise<Category[]> => {
  const res = await axios.get<Category[]>('/trait-categories', {
    baseURL: config.urls.traitswapApiUrl,
    params: {
      collectionAddress,
    },
  });

  return res.data;
};

import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useDynamicNftSalePriceContractCall = (): [
  price: BigNumber | undefined,
  readSalePrice: () => void
] => {
  const [{ data: priceData }, readSalePrice] = useContractRead(
    {
      addressOrName: config.contracts.dynamicNftSaleContract.address,
      contractInterface: config.contracts.dynamicNftSaleContract.interface,
    },
    'price'
  );

  if (priceData === undefined) {
    return [undefined, readSalePrice];
  }

  return [priceData as any as BigNumber, readSalePrice];
};

export default useDynamicNftSalePriceContractCall;

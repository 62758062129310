import axios from 'axios';
import config from '../constants/baseConfig';
import { Metadata } from '../types';

export const fetchMetadata = async (
  tokenId: number,
  cacheId?: string | null
): Promise<Metadata> => {
  const res = await axios.get<Metadata>(`${tokenId}`, {
    baseURL: config.urls.dynamicNftMetadataUrl,
    params: { cacheId },
  });

  return res.data;
};

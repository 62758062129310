import config from '../constants/baseConfig';
import useHiveBalanceInTokensOfCollectionContractCall from './bc/useHiveBalanceInTokensOfCollectionContractCall';
import { useMemo } from 'react';
import useVestingAmountRemainingInBearContractCall from './bc/useVestingAmountRemainingInBearContractCall';

const useFancyBearHoneyStats = (tokenId: number) => {
  const tokenIdInArray = useMemo(
    () => (tokenId ? [tokenId] : undefined),
    [tokenId]
  );

  const [hiveBalanceInBears] = useHiveBalanceInTokensOfCollectionContractCall(
    config.contracts.fancyBearsContract.address,
    tokenIdInArray
  );

  const [vestingBalanceInBear] =
    useVestingAmountRemainingInBearContractCall(tokenId);

  if (hiveBalanceInBears === undefined || vestingBalanceInBear === undefined) {
    return;
  }

  return {
    honeyInHive: hiveBalanceInBears?.[tokenId],
    honeyInVesting: vestingBalanceInBear,
  };
};

export default useFancyBearHoneyStats;

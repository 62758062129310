import { ThemeTypings, useTheme } from '@chakra-ui/react';
import React from 'react';

type HowItWorksIllustrationProps = React.SVGProps<SVGSVGElement> & {
  colorScheme?: ThemeTypings['colorSchemes'];
  linesColor?: string;
};

const HowItWorksIllustration = ({
  colorScheme = 'primary',
  linesColor = 'currentColor',
  ...rest
}: HowItWorksIllustrationProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="145"
      height="62"
      viewBox="0 0 145 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#hiwClipPath)">
        <path
          d="M0 45.3199L27.0212 5.33771L35.5124 26.9489L60.089 0L64.2984 10.8056L85.114 0.940247L76.5072 41.935L106.277 9.74964L110.371 29.4514L144.899 5.33771L133.197 42.1665L122.565 28.1495L94.4297 52.8563L93.6486 43.6564L67.3651 61.55L53.9412 29.5961L0 45.3199Z"
          fill={colors[colorScheme][600]}
        />
        <path
          d="M61.5443 13.3965L35.4046 11.275L32.8993 42.1438L59.039 44.2653L61.5443 13.3965Z"
          fill={colors[colorScheme][500]}
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M56.669 16.5595L39.7856 15.1892C38.8619 15.1142 38.0523 15.8023 37.9773 16.7259L36.7311 32.081C36.6561 33.0047 37.3442 33.8143 38.2678 33.8893L55.1512 35.2595C56.0749 35.3345 56.8845 34.6465 56.9594 33.7228L58.2057 18.3677C58.2806 17.444 57.5926 16.6344 56.669 16.5595Z"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M35.5124 38.1306L53.9412 39.635"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M34.8354 16.5927L7.54927 28.13L21.171 60.3457L48.4571 48.8084L34.8354 16.5927Z"
          fill={colors[colorScheme][500]}
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M31.5359 21.7199L13.5095 29.342C12.6559 29.7029 12.2565 30.6874 12.6174 31.541L19.5635 47.9686C19.9244 48.8222 20.9089 49.2215 21.7625 48.8606L39.7889 41.2385C40.6425 40.8776 41.0418 39.8931 40.6809 39.0396L33.7349 22.6119C33.374 21.7584 32.3894 21.359 31.5359 21.7199Z"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M21.4376 54.5054L40.6765 46.3759"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M128.55 18.182L98.1182 11.9076L90.7076 47.8501L121.139 54.1245L128.55 18.182Z"
          fill={colors[colorScheme][500]}
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M122.477 20.6253L102.742 16.5563C101.686 16.3385 100.653 17.0183 100.435 18.0746L96.7345 36.0246C96.5167 37.0809 97.1964 38.1137 98.2527 38.3315L117.988 42.4005C119.044 42.6183 120.077 41.9385 120.295 40.8823L123.996 22.9322C124.213 21.8759 123.534 20.8431 122.477 20.6253Z"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M94.4297 42.9331L115.896 47.3595"
          stroke={linesColor}
          strokeMiterlimit="10"
        />
        <path
          d="M132.054 15.0728V15.5212C132.054 16.0138 132.174 16.499 132.403 16.935C132.633 17.371 132.965 17.7446 133.37 18.0238L133.732 18.2697C134.201 18.5895 134.57 19.0348 134.798 19.5546C135.025 20.0745 135.102 20.6479 135.019 21.2093C134.936 21.7707 134.697 22.2974 134.328 22.7292C133.96 23.1609 133.478 23.4803 132.936 23.6508L132.517 23.7954C132.046 23.9454 131.62 24.2084 131.274 24.5615C130.929 24.9145 130.675 25.3468 130.535 25.8206L130.419 26.2401C130.257 26.7821 129.946 27.2678 129.521 27.6424C129.097 28.0171 128.577 28.2658 128.019 28.3606C127.461 28.4553 126.888 28.3923 126.364 28.1787C125.839 27.965 125.386 27.6092 125.053 27.1514L124.792 26.7897C124.502 26.3923 124.121 26.0704 123.68 25.8514C123.24 25.6324 122.753 25.5226 122.261 25.5313H121.813C121.247 25.5443 120.689 25.3992 120.202 25.1123C119.714 24.8254 119.317 24.4082 119.054 23.9075C118.791 23.4068 118.672 22.8427 118.713 22.2785C118.753 21.7143 118.95 21.1726 119.281 20.7143L119.556 20.3527C119.843 19.9542 120.03 19.4924 120.1 19.0064C120.171 18.5203 120.123 18.0245 119.961 17.5609L119.816 17.1414C119.63 16.6073 119.596 16.0319 119.719 15.4797C119.842 14.9276 120.117 14.4208 120.512 14.0163C120.908 13.6119 121.408 13.3259 121.957 13.1905C122.507 13.0551 123.083 13.0758 123.621 13.2502L124.04 13.3804C124.505 13.5381 125.002 13.5784 125.487 13.4977C125.972 13.4169 126.429 13.2176 126.818 12.9175L127.179 12.6426C127.63 12.3 128.169 12.0903 128.733 12.0374C129.297 11.9845 129.865 12.0905 130.372 12.3433C130.879 12.5961 131.305 12.9856 131.602 13.468C131.9 13.9503 132.056 14.5062 132.054 15.0728V15.0728Z"
          fill="black"
        />
        <path
          d="M91.5222 15.6225C75.3933 -8.21634 53.1312 7.6666 53.1312 7.6666"
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M83.3782 13.6697L91.7102 15.9263L92.7228 7.78235"
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M84.1883 38.4344C79.5015 31.5056 74.1349 28.9018 69.2745 28.3087"
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M77.8524 36.9734L84.3329 38.7237L85.114 32.4023"
          stroke={linesColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M124.59 22.3779H122.926L122.406 17.6188H123.852L124.04 19.5716C124.04 19.6728 124.04 19.8754 124.04 20.0923C124.04 20.3093 124.04 20.425 124.04 20.7433C124.134 20.3543 124.254 19.9724 124.402 19.6005L125.299 17.6188H126.832L124.59 22.3779Z"
          fill={colors[colorScheme][500]}
        />
        <path
          d="M130.853 21.2351L130.608 22.3779H126.528C126.614 21.8204 126.861 21.3001 127.239 20.8812C127.617 20.4623 128.109 20.163 128.655 20.0201C129.653 19.5716 129.884 19.398 129.884 19.0943C129.884 18.7905 129.667 18.7037 129.364 18.7037C129.06 18.7037 128.655 19.0075 128.611 19.5282H127.165C127.201 19.0405 127.377 18.5734 127.671 18.183C127.919 17.9431 128.215 17.7576 128.538 17.638C128.862 17.5185 129.207 17.4676 129.552 17.4886C130.651 17.4886 131.302 18.0094 131.302 18.9352C131.299 19.1852 131.239 19.4314 131.126 19.6546C131.013 19.8778 130.851 20.0721 130.651 20.2226C130.242 20.5237 129.788 20.758 129.306 20.9169C129.023 21.0061 128.751 21.1274 128.496 21.2785L130.853 21.2351Z"
          fill={colors[colorScheme][500]}
        />
        <path
          d="M30.6809 30.1714L24.0135 29.2721L20.0094 34.6745L22.6707 40.9812L29.3345 41.8819L33.3422 36.4781L30.6809 30.1714Z"
          stroke={linesColor}
          strokeWidth="1.3"
          strokeMiterlimit="10"
        />
        <path
          d="M24.8381 34.2367L26.5095 38.1977L25.6067 38.5787L24.3111 37.0041L25.0709 38.8048L24.2097 39.1682L22.5383 35.2072L23.3995 34.8437L24.7295 36.3837L23.9769 34.6001L24.8381 34.2367Z"
          fill="black"
        />
        <path
          d="M25.254 34.0612L26.9984 33.3251L27.3329 34.1178L26.6185 34.4192L26.9355 35.1703L27.5716 34.9019L27.8895 35.6555L27.2534 35.9239L27.9555 37.5876L26.9255 38.0222L25.254 34.0612Z"
          fill="black"
        />
        <path
          d="M29.3251 32.3433L29.6596 33.136L29.048 33.3941L30.3849 36.5624L29.3549 36.997L28.018 33.8287L27.4088 34.0858L27.0743 33.2931L29.3251 32.3433Z"
          fill="black"
        />
        <path
          d="M53.4482 22.3321L48.5091 18.9124L43.1327 21.5858L42.6916 27.6822L47.6273 31.1016L53.0072 28.4284L53.4482 22.3321Z"
          stroke={linesColor}
          strokeWidth="1.3"
          strokeMiterlimit="10"
        />
        <path
          d="M47.1514 23.1961L46.8744 27.025L46.0017 26.9619L45.6097 25.1838L45.4838 26.9244L44.6513 26.8642L44.9283 23.0353L45.7608 23.0955L46.1942 24.8599L46.3189 23.1359L47.1514 23.1961Z"
          fill="black"
        />
        <path
          d="M47.5534 23.2252L49.2397 23.3472L49.1842 24.1134L48.4937 24.0635L48.4411 24.7895L49.056 24.834L49.0033 25.5624L48.3884 25.5179L48.2721 27.1261L47.2765 27.0541L47.5534 23.2252Z"
          fill="black"
        />
        <path
          d="M51.4888 23.5099L51.4333 24.2761L50.8421 24.2333L50.6205 27.296L49.6249 27.224L49.8464 24.1613L49.2576 24.1187L49.313 23.3525L51.4888 23.5099Z"
          fill="black"
        />
        <path
          d="M117.603 26.8526L112.028 21.9178L105.018 24.4174L103.579 31.8553L109.15 36.7893L116.164 34.2905L117.603 26.8526Z"
          stroke={linesColor}
          strokeWidth="1.3"
          strokeMiterlimit="10"
        />
        <path
          d="M109.727 26.99L108.823 31.6615L107.758 31.4555L107.537 29.2096L107.126 31.3332L106.111 31.1367L107.015 26.4652L108.03 26.6617L108.304 28.897L108.711 26.7935L109.727 26.99Z"
          fill="black"
        />
        <path
          d="M110.217 27.0849L112.275 27.483L112.094 28.4179L111.251 28.2549L111.08 29.1407L111.83 29.2858L111.658 30.1746L110.908 30.0294L110.528 31.9915L109.313 31.7564L110.217 27.0849Z"
          fill="black"
        />
        <path
          d="M115.019 28.014L114.838 28.9489L114.116 28.8093L113.393 32.5459L112.179 32.3108L112.902 28.5742L112.183 28.4352L112.364 27.5003L115.019 28.014Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="hiwClipPath">
          <rect width="144.899" height="61.55" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HowItWorksIllustration;

import {
  AspectRatio,
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaRedoAlt,
  FaSearch,
  FaUndoAlt,
} from 'react-icons/fa';
import FloatingBadge from '../components/FloatingBadge';
import MotionBox from '../components/MotionBox';
import PreviewPopup from '../components/PreviewPopup';
import SeparatedList from '../components/SeparatedList';
import TraitsPreview from '../components/TraitsPreview';
import baseTheme from '../constants/baseTheme';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useTranslate from '../hooks/useTranslate';

type SelectedTraitsContainerProps = {
  renderItem: (traitId: string) => React.ReactElement;
};

const previewVariants = {
  open: { width: '4rem' },
  closed: { width: '3rem' },
};

const SelectedTraitsContainer = ({
  renderItem,
}: SelectedTraitsContainerProps) => {
  const translate = useTranslate();
  const { changedTraitIds, currentTraitIds } = useDressingRoomContext();
  const [areTraitsShown, setAreTraitsShown] = useBoolean();
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useBoolean();
  const { undoSwap, redoSwap, resetTraits, canUndo, canRedo } =
    useDressingRoomContext();

  return changedTraitIds && changedTraitIds.length > 0 ? (
    <>
      <Box w="full">
        <HStack spacing="1.5" w="full">
          <Box role="group">
            <MotionBox
              flex="none"
              position="relative"
              borderRadius="lg"
              overflow="hidden"
              animate={areTraitsShown ? 'open' : 'closed'}
              variants={previewVariants}
            >
              <AspectRatio ratio={1} w="full">
                <TraitsPreview
                  w="full"
                  traitIds={currentTraitIds}
                  imagesSize="256"
                />
              </AspectRatio>

              <Center
                position="absolute"
                opacity={0}
                transition="opacity 0.15s"
                _groupHover={{
                  opacity: 1,
                }}
                zIndex="10"
                bg={baseTheme.components.Modal.baseStyle.overlay.bg}
                w="full"
                h="full"
                top="0"
                left="0"
                role="button"
                cursor="pointer"
                aria-label={translate('common:zoom')}
                onClick={setIsPreviewPopupOpen.on}
              >
                <Icon as={FaSearch} />
              </Center>
            </MotionBox>
          </Box>

          <HStack spacing="0" w="full">
            <Tooltip label={translate('common:undo')} placement="top">
              <IconButton
                size="sm"
                variant="link"
                py="2"
                icon={<Icon as={FaUndoAlt} w="5" h="5" />}
                aria-label=""
                isDisabled={!canUndo}
                onClick={undoSwap}
              />
            </Tooltip>

            <Tooltip label={translate('common:redo')} placement="top">
              <IconButton
                size="sm"
                variant="link"
                py="2"
                icon={<Icon as={FaRedoAlt} w="5" h="5" />}
                aria-label=""
                isDisabled={!canRedo}
                onClick={redoSwap}
              />
            </Tooltip>

            <Box pl="1.5">
              <Button
                variant="outline"
                size="sm"
                isDisabled={!canUndo}
                onClick={resetTraits}
              >
                {translate('common:reset')}
              </Button>
            </Box>
          </HStack>

          <FloatingBadge text={changedTraitIds.length} offset="20%">
            <Button
              flex="none"
              size="sm"
              variant="outline"
              rightIcon={
                <Icon as={areTraitsShown ? FaChevronDown : FaChevronUp} />
              }
              onClick={setAreTraitsShown.toggle}
            >
              {translate(areTraitsShown ? 'bag:toggle:off' : 'bag:toggle:on')}
            </Button>{' '}
          </FloatingBadge>
        </HStack>

        <AnimatePresence>
          {areTraitsShown && (
            <MotionBox
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              overflow="hidden"
            >
              <SeparatedList w="full" pt="3">
                {changedTraitIds?.map(renderItem)}
              </SeparatedList>
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>

      {isPreviewPopupOpen && (
        <PreviewPopup
          isOpen
          traitIds={currentTraitIds}
          onClose={setIsPreviewPopupOpen.off}
        />
      )}
    </>
  ) : null;
};

export default SelectedTraitsContainer;

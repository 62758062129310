import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

const useCaptureException = (error?: any) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);
};

export default useCaptureException;

import { useCallback } from 'react';
import { useEventListener } from '@chakra-ui/react';

export enum KeyboardAction {
  Cancel,
  Next,
  Prev,
}

const useKeyboardActionListener = (
  callback: (action: KeyboardAction) => void,
  isActive = true,
  isPreventedWhenPortalExists = true
) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();

      const isPortalActive =
        // @ts-ignore
        !!document.getElementById('root')?.attributes['data-aria-hidden'];

      if (!isActive || (isPreventedWhenPortalExists && isPortalActive)) {
        return;
      }

      if (event.key === 'Escape') {
        callback(KeyboardAction.Cancel);
      }

      if (event.key === 'ArrowRight') {
        callback(KeyboardAction.Next);
      } else if (event.key === 'ArrowLeft') {
        callback(KeyboardAction.Prev);
      }
    },
    [callback, isActive, isPreventedWhenPortalExists]
  );

  useEventListener('keydown', handleKeyPress);
};

export default useKeyboardActionListener;

import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { erc20ABI, useContractRead } from 'wagmi';

const useAnyErc20AllowanceContractCall = (
  tokenAddress: string,
  spenderAddress?: string,
  walletAddress?: string
): [BigNumber | undefined, () => void] => {
  const args = useMemo(
    () => walletAddress && spenderAddress && [walletAddress, spenderAddress],
    [walletAddress, spenderAddress]
  );

  const [{ data: allowance }, readAllowance] = useContractRead(
    {
      addressOrName: tokenAddress,
      contractInterface: erc20ABI,
    },
    'allowance',
    {
      args,
    }
  );

  return [allowance as unknown as BigNumber, readAllowance];
};

export default useAnyErc20AllowanceContractCall;

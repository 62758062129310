import { Box, Button, ModalProps } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import useApproveTraitsForTraitSwapContractCall from '../hooks/bc/useApproveTraitsForTraitSwapContractCall';
import useIsTraitsApprovedForStakingContractCall from '../hooks/bc/useIsTraitsApprovedForStakingContractCall';
import useStakeTraitsContractCall, {
  StakeTraitsCallData,
} from '../hooks/bc/useStakeTraitsContractCall';
import useApplyTraitsSwapsUtil from '../hooks/useApplyTraitsSwapsUtil';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import useTranslate from '../hooks/useTranslate';
import BeforeAfterPreview from './BeforeAfterPreview';
import SimplePopup from './SimplePopup';
import TraitTile from './TraitTile';

type StakeTraitsPopupProps = Omit<ModalProps, 'children'> & {
  currentTraitIds: string[];
  newTraitIds: string[];
  traitIdsToStake: string[];
  dynamicNftTokenId: number;
  onSuccess: () => void;
};

const StakeTraitsPopup = ({
  dynamicNftTokenId,
  currentTraitIds,
  traitIdsToStake,
  onSuccess,
  ...rest
}: StakeTraitsPopupProps) => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();
  const { traitsById, traitCollectionsById, dynamicNftCollection } =
    useDynamicNftCollectionContext();
  const { traitCollection } = useTraitCollectionContext();

  const isMultiple = traitIdsToStake.length > 1;
  const slidesPerView = isMultiple ? 1.35 : 1;

  const [isApproved, readIsApproved] =
    useIsTraitsApprovedForStakingContractCall(
      traitCollection.contractAddress,
      walletAddress
    );
  const [approve, isApproving] =
    useApproveTraitsForTraitSwapContractCall(readIsApproved);

  const newTraitIds = useApplyTraitsSwapsUtil(currentTraitIds, traitIdsToStake);

  const callData: StakeTraitsCallData = useMemo(() => {
    const traitCollectionAddresses: string[] = [];
    const traitTokenIdsPerCollection: number[][] = [];

    traitIdsToStake.forEach(traitId => {
      const trait = traitsById[traitId];
      const collection = traitCollectionsById[trait.traitCollectionId];

      const collectionIndex = traitCollectionAddresses.indexOf(
        collection.contractAddress
      );

      if (collectionIndex >= 0) {
        if (traitTokenIdsPerCollection[collectionIndex]) {
          traitTokenIdsPerCollection[collectionIndex].push(trait.tokenId);
        } else {
          traitTokenIdsPerCollection[collectionIndex] = [trait.tokenId];
        }
      } else {
        traitCollectionAddresses.push(collection.contractAddress);
        traitTokenIdsPerCollection.push([trait.tokenId]);
      }
    });

    return {
      dynamicNftTokenId,
      dynamicNftCollectionAddress: dynamicNftCollection.contractAddress,
      traitCollectionAddresses,
      traitTokenIdsPerCollection,
    };
  }, [
    dynamicNftTokenId,
    traitIdsToStake,
    traitsById,
    traitCollectionsById,
    dynamicNftCollection.contractAddress,
  ]);

  const [stake, isStaking] = useStakeTraitsContractCall(callData, onSuccess);

  const renderItem = useCallback(
    (traitId: string) => {
      const trait = traitsById[traitId];

      if (!trait) {
        return <></>;
      }

      return (
        <SwiperSlide key={traitId}>
          <TraitTile
            traitId={traitId}
            tokenId={trait.tokenId}
            category={trait.category}
            name={trait.name}
            w="full"
            canvasSize="12"
          />
        </SwiperSlide>
      );
    },
    [traitsById]
  );

  useEffect(() => {
    readIsApproved();
  }, [readIsApproved]);

  return (
    <SimplePopup
      title={translate(
        isMultiple ? 'stake:title:multi' : 'stake:title:single',
        { count: traitIdsToStake.length }
      )}
      {...rest}
    >
      <Box pt="10">
        <BeforeAfterPreview
          currentTraitIds={currentTraitIds}
          newTraitIds={newTraitIds}
          imagesSize="256"
        />
      </Box>

      <Box mt="3">
        <Swiper
          spaceBetween={2}
          threshold={10}
          modules={[Controller]}
          slidesPerView={slidesPerView}
          freeMode
        >
          {traitIdsToStake.map(renderItem)}
        </Swiper>
      </Box>

      <Box pt="6">
        {isApproved === undefined ? (
          <Button w="full" colorScheme="dark" isLoading />
        ) : (
          <>
            {isApproved ? (
              <Button
                colorScheme="primary"
                w="full"
                onClick={() => stake()}
                isLoading={isStaking}
              >
                {translate('stake:submit')}
              </Button>
            ) : (
              <Button
                w="full"
                onClick={() => approve()}
                isLoading={isApproved === undefined || isApproving}
              >
                {translate('stake:approve')}
              </Button>
            )}
          </>
        )}
      </Box>
    </SimplePopup>
  );
};

export default StakeTraitsPopup;

import { BigNumber } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import config from '../../constants/baseConfig';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';

const useDynamicNftMintContractCall = (
  value?: BigNumber,
  referenceTokenIds?: number[],
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const [{ data, loading: isLoading, error: writeError }, mint] =
    useContractWrite(
      {
        addressOrName: config.contracts.dynamicNftSaleContract.address,
        contractInterface: config.contracts.dynamicNftSaleContract.interface,
      },
      'mint',
      {
        args: [referenceTokenIds],
        overrides: {
          value,
        },
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  useEffect(() => {
    if (!isWaiting && waitData && onSuccess) {
      onSuccess();
    }
  }, [isWaiting, waitData, onSuccess]);

  return useMemo(
    () => [mint, isLoading || isWaiting],
    [mint, isWaiting, isLoading]
  );
};

export default useDynamicNftMintContractCall;

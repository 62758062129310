import { useMemo } from 'react';
import * as walletsApi from '../../api/walletsApi';
import { TraitWithQuantity } from '../../types/trait';
import useApiCall from '../useApiCall';

const useFetchTraitIdsInWalletApiCall = (
  data: walletsApi.FetchWalletTraitsReqData
) => {
  const memoizedData = useMemo(
    () => data,
    [
      data.walletAddress,
      data.dynamicNftCollectionAddress,
      data.traitCollectionAddress,
    ]
  );

  return useApiCall<TraitWithQuantity[], walletsApi.FetchWalletTraitsReqData>(
    walletsApi.fetchWalletTraits,
    memoizedData
  );
};

export default useFetchTraitIdsInWalletApiCall;

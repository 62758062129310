import { BigNumber } from 'ethers';
import React from 'react';

export type HiveContextValue = {
  hiveBalanceInDynamicNfts?: Record<number, BigNumber>;
  hiveBalanceInDynamicNftsSum?: BigNumber;
  readHiveBalanceInDynamicNfts: () => void;
  hiveBalanceInHoneyJars?: Record<number, BigNumber>;
  hiveBalanceInHoneyJarsSum?: BigNumber;
  readHiveBalanceInHoneyJars?: () => void;
  hiveBalanceSum?: BigNumber;
  isLoading: boolean;
};

// @ts-ignore
const HiveContext = React.createContext<HiveContextValue>();

HiveContext.displayName = 'HiveContext';

export default HiveContext;

import { SimpleGrid } from '@chakra-ui/react';
import Attribute from '../components/Attribute';
import Panel from '../components/Panel';
import TinyHeading from '../components/TinyHeading';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useTranslate from '../hooks/useTranslate';

const DynamicNftAttributesContainer = () => {
  const translate = useTranslate();
  const { dynamicNftMetadata } = useDynamicNftContext();
  const hasAttributes =
    dynamicNftMetadata?.attributes && dynamicNftMetadata.attributes.length > 0;

  return hasAttributes ? (
    <Panel w="full" overflow="hidden">
      <TinyHeading mb="3">
        {translate('nftAttrs:label', {
          count: dynamicNftMetadata.attributes!.length,
        })}
      </TinyHeading>

      <SimpleGrid columns={{ base: 2, sm: 3 }} gap="2">
        {dynamicNftMetadata.attributes!.map((attribute, i) => (
          <Attribute key={i} attribute={attribute} />
        ))}
      </SimpleGrid>
    </Panel>
  ) : null;
};

export default DynamicNftAttributesContainer;

import { Box, BoxProps } from '@chakra-ui/react';
import TinyHeading from '../components/TinyHeading';
import useTranslate from '../hooks/useTranslate';

type OrDividerProps = BoxProps;

const OrDivider = (props: OrDividerProps) => {
  const translate = useTranslate();

  return (
    <Box position="relative" textAlign="center" {...props}>
      <Box
        h="2px"
        w="full"
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        bg="bright.50"
        opacity="0.15"
      />

      <Box bg="dark.900" px="3" display="inline-block" position="relative">
        <TinyHeading p="2">{translate('common:or')}</TinyHeading>
      </Box>
    </Box>
  );
};

export default OrDivider;

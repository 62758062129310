import * as React from 'react';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
  const event = new Event('storageChange');
  document.dispatchEvent(event);
  // @ts-ignore
  originalSetItem.apply(this, arguments);
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function () {
  const event = new Event('storageChange');
  document.dispatchEvent(event);
  // @ts-ignore
  originalRemoveItem.apply(this, arguments);
};

const useLocalStorageChange = (key: string) => {
  const storageItem = localStorage.getItem(key);

  const [storedValue, setValue] = React.useState(
    !!storageItem ? JSON.parse(storageItem) : null
  );

  const setLocalItem = () => {
    setTimeout(() => {
      const itemValueFromStorage = localStorage.getItem(key);
      const value = itemValueFromStorage && JSON.parse(itemValueFromStorage);
      setValue(value);
    }, 50);
  };

  const setStoredValue = (value: string | object) => {
    const parsedValue =
      typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, parsedValue);
  };

  React.useEffect(() => {
    document.addEventListener('storageChange', setLocalItem, false);

    return () => document.removeEventListener('storageChange', setLocalItem);
  }, []);

  return [storedValue, setStoredValue];
};

export default useLocalStorageChange;

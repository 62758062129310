import React from 'react';

const DynamicNftIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#v2ClipPath)">
      <path
        d="M27.1431 7.40331L15.8778 1.22313C15.3344 0.922368 14.6656 0.922368 14.1222 1.22313L2.84646 7.40331C2.32395 7.69437 2 8.21828 2 8.781V21.2093C2 21.7817 2.32395 22.3056 2.84646 22.5967L14.1117 28.7768C14.6551 29.0776 15.324 29.0776 15.8674 28.7768L27.1431 22.5967C27.6656 22.3056 27.9896 21.7817 27.9896 21.2093V8.781C28 8.21828 27.676 7.69437 27.1431 7.40331Z"
        fill="currentColor"
      />
      <path
        d="M4.91557 18.9099C4.59161 18.9099 4.34081 18.8323 4.17361 18.6674C4.0064 18.5121 3.91235 18.2793 3.91235 17.9688V12.0797C3.91235 11.7595 3.99595 11.517 4.17361 11.352C4.34081 11.1871 4.57071 11.0998 4.85287 11.0998C5.10367 11.0998 5.29177 11.1483 5.43808 11.2259C5.57393 11.3132 5.73068 11.4588 5.89788 11.6528L9.58679 15.9314H9.25239V12.0215C9.25239 11.7207 9.33599 11.4879 9.50319 11.3326C9.67039 11.1774 9.9212 11.0901 10.2452 11.0901C10.5691 11.0901 10.8199 11.1677 10.9871 11.3326C11.1543 11.4879 11.2379 11.7207 11.2379 12.0215V18.0076C11.2379 18.2793 11.1648 18.5024 11.008 18.6577C10.8513 18.8226 10.6423 18.9002 10.381 18.9002C10.1197 18.9002 9.91075 18.8517 9.75399 18.7644C9.60769 18.6771 9.44049 18.5315 9.27329 18.3375L5.58438 14.0492H5.90833V17.9591C5.90833 18.2696 5.82473 18.5024 5.65753 18.6577C5.47988 18.8226 5.23952 18.9099 4.91557 18.9099Z"
        fill="black"
      />
      <path
        d="M13.4325 18.9099C13.0877 18.9099 12.8264 18.8226 12.6383 18.648C12.4398 18.4636 12.3457 18.2114 12.3457 17.8815V12.2253C12.3457 11.8954 12.4398 11.6431 12.6279 11.4685C12.816 11.2939 13.0981 11.2065 13.4639 11.2065H17.3409C17.623 11.2065 17.832 11.2745 17.9679 11.4006C18.1037 11.5267 18.1769 11.7207 18.1769 11.9633C18.1769 12.2156 18.1037 12.4096 17.9679 12.5454C17.832 12.6812 17.623 12.7492 17.3409 12.7492H14.488V14.2821H17.0796C17.3513 14.2821 17.5603 14.35 17.7066 14.4761C17.8529 14.6022 17.9261 14.7963 17.9261 15.0388C17.9261 15.2911 17.8529 15.4851 17.7066 15.6113C17.5603 15.7374 17.3513 15.8053 17.0796 15.8053H14.488V17.9009C14.488 18.5607 14.1431 18.9099 13.4325 18.9099Z"
        fill="black"
      />
      <path
        d="M22.6599 18.9099C22.315 18.9099 22.0538 18.8226 21.8657 18.648C21.6775 18.4733 21.5835 18.2211 21.5835 17.9106V12.8268H19.7547C19.4726 12.8268 19.2531 12.7589 19.0964 12.6133C18.9501 12.4775 18.8665 12.2738 18.8665 12.0118C18.8665 11.7499 18.9396 11.5461 19.0964 11.4103C19.2427 11.2745 19.4621 11.2065 19.7547 11.2065H25.5441C25.8263 11.2065 26.0457 11.2745 26.2025 11.4103C26.3488 11.5461 26.4324 11.7499 26.4324 12.0118C26.4324 12.2738 26.3592 12.4678 26.2025 12.6133C26.0562 12.7492 25.8263 12.8268 25.5441 12.8268H23.7153V17.9106C23.7153 18.2308 23.6213 18.4733 23.4436 18.648C23.2764 18.8129 23.0152 18.9099 22.6599 18.9099Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="v2ClipPath">
        <rect width="26" height="28" fill="white" transform="translate(2 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default DynamicNftIcon;

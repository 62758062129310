import { BigNumber } from 'ethers';
import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';
import { useMemo } from 'react';
import useVestingContext from './useVestingContext';
import useWalletAssetsContext from './useWalletAssetsContext';

const useDynamicNftVestingHoneyUtil = (dynamicNftTokenId?: number) => {
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();
  const { fancyBearsInWallet } = useWalletAssetsContext();

  const { vestingAmountInBearsRemaining } = useVestingContext();

  return useMemo(() => {
    if (dynamicNftTokenId) {
      const isInWallet = fancyBearsInWallet?.includes(dynamicNftTokenId);
      const hasSomethingInVesting =
        vestingAmountInBearsRemaining?.[dynamicNftTokenId];

      return isFancyBearsCollection && isInWallet && hasSomethingInVesting
        ? vestingAmountInBearsRemaining[dynamicNftTokenId]
        : BigNumber.from('0');
    }

    return undefined;
  }, [
    dynamicNftTokenId,
    fancyBearsInWallet,
    isFancyBearsCollection,
    vestingAmountInBearsRemaining,
  ]);
};

export default useDynamicNftVestingHoneyUtil;

import FeatureFlag from '../types/featureFlag';

export const isFeatureEnabled = (feature: FeatureFlag) =>
  Boolean(
    process.env[`REACT_APP_FEATURE_${feature}`] &&
      process.env[`REACT_APP_FEATURE_${feature}`] === '1'
  );

export const isFeatureDisabled = (feature: FeatureFlag) =>
  !isFeatureEnabled(feature);

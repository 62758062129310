import config from '../constants/baseConfig';
import VideoPopup, { VideoPopupProps } from './VideoPopup';

type HowItWorksPopupProps = Omit<
  VideoPopupProps,
  'children' | 'src' | 'isClosable'
>;

const HowItWorksPopup = ({ ...rest }: HowItWorksPopupProps) => (
  <VideoPopup src={`${config.urls.publicUrl}/hiw-video.mp4`} {...rest} />
);

export default HowItWorksPopup;

import { Container, useBreakpointValue, VStack } from '@chakra-ui/react';
import AnimatedPage from '../components/AnimatedPage';
import HowItWorksBanner from '../components/HowItWorksBanner';
import JoinTheClubPanel from '../components/JoinTheClubPanel';
import ReserveV2Panel from '../components/ReserveV2Panel';
import DynamicNftsContainer from '../containers/DynamicNftsContainer';
import StaticNftsContainer from '../containers/StaticNftsContainer';
import usePageTracking from '../hooks/usePageTracking';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';

const NftsPage = () => {
  const slidesPerView = useBreakpointValue({
    base: 3.25,
    sm: 5.25,
  });

  usePageTracking();

  return (
    <AnimatedPage>
      <Container>
        <VStack pt="12" pb="16" spacing="6">
          <HowItWorksBanner />
          <DynamicNftsContainer />

          {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
            <>
              <StaticNftsContainer />
              <ReserveV2Panel />
              <JoinTheClubPanel slidesPerView={slidesPerView} />
            </>
          )}
        </VStack>
      </Container>
    </AnimatedPage>
  );
};

export default NftsPage;

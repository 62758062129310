import { Box, BoxProps, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import EmptyState from './EmptyState';
import TinyHeading from './TinyHeading';
import { TraitTileProps } from './TraitTile';

type SeparatedListProps = Omit<BoxProps, 'gap' | 'children'> &
  Pick<SimpleGridProps, 'gap' | 'columns'> & {
    title?: string;
    children?: (React.ReactElement<TraitTileProps> | null)[];
  };

const SeparatedList = ({
  title,
  children,
  columns = 1,
  gap = '2px',
  ...rest
}: SeparatedListProps) => (
  <Box {...rest}>
    {title && <TinyHeading mb="3">{title}</TinyHeading>}

    {children?.length ? (
      <SimpleGrid gap={gap} columns={columns}>
        {children}
      </SimpleGrid>
    ) : (
      <EmptyState pt="6" pb="6" opacity="0.4" />
    )}
  </Box>
);

export default SeparatedList;

import { AspectRatio, Box } from '@chakra-ui/react';
import TraitsPreview from '../components/TraitsPreview';
import useDressingRoomContext from '../hooks/useDressingRoomContext';

const TraitsPreviewContainer = () => {
  const { originalTraitIds, currentTraitIds } = useDressingRoomContext();

  return (
    <AspectRatio ratio={1} w="full">
      {originalTraitIds && currentTraitIds ? (
        <TraitsPreview traitIds={currentTraitIds} imagesSize="512" />
      ) : (
        <Box />
      )}
    </AspectRatio>
  );
};

export default TraitsPreviewContainer;

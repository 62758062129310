import { Image, ImageProps } from '@chakra-ui/react';
import config from '../constants/baseConfig';

const COLLECTIONS_WITH_AVATARS = ['fancybeartraits', 'fancytraitsv2'];

type TraitsCollectionImageProps = Omit<ImageProps, 'src' | 'fallbackSrc'> & {
  collectionId: string;
};

const TraitsCollectionImage = ({
  collectionId,
}: TraitsCollectionImageProps) => (
  <Image
    src={`${config.urls.publicUrl}/collection-${collectionId}.png`}
    fallbackSrc={`${config.urls.publicUrl}/collection-placeholder.png`}
    w="full"
    position="absolute"
    opacity={COLLECTIONS_WITH_AVATARS.includes(collectionId) ? 1 : 0.3}
  />
);

export default TraitsCollectionImage;

import FancyBearTraitsHoneyCheckoutContainer from '../containers/FancyBearTraitsHoneyCheckoutContainer';
import SimplePopup from './SimplePopup';
import TinyHeading from './TinyHeading';
import TraitTile from './TraitTile';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useStoreContext from '../hooks/useStoreContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import useTranslate from '../hooks/useTranslate';
import V2TraitsErc20CheckoutContainer from '../containers/V2TraitsErc20CheckoutContainer';
import V2TraitsEthCheckoutContainer from '../containers/V2TraitsEthCheckoutContainer';
import V2TraitsHoneyCheckoutContainer from '../containers/V2TraitsHoneyCheckoutContainer';
import { Box, ModalProps } from '@chakra-ui/react';
import { Controller } from 'swiper';
import { Currency } from '../types';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useCallback, useMemo } from 'react';

type PurchasePopupProps = Omit<ModalProps, 'children'> & {
  onSuccess: () => void;
};

const PurchasePopup = ({ onSuccess, ...rest }: PurchasePopupProps) => {
  const translate = useTranslate();
  const { currency } = useCurrencyContext();
  const { traitsById } = useDynamicNftCollectionContext();
  const { isFancyBearTraitCollection } = useTraitCollectionContext();
  const { traitSalesByTraitId } = useStoreContext();
  const { changedTraitIds } = useDressingRoomContext();

  const isMultiple = (changedTraitIds?.length || 0) > 1;

  const slidesPerView = isMultiple ? 1.35 : 1;

  const renderTile = useCallback(
    (traitId: string) => {
      const traitSale = traitSalesByTraitId[traitId];
      const trait = traitsById[traitId];

      if (!traitSale || !trait) {
        return <></>;
      }

      return traitSale ? (
        <TraitTile
          key={traitId}
          traitId={traitId}
          name={trait.name}
          tokenId={trait.tokenId}
          category={trait.category}
          price={traitSale.price[currency]}
          totalSupply={traitSale.counter}
          maxSupply={traitSale.maxSupply}
          w="full"
          canvasSize="12"
        />
      ) : null;
    },
    [currency, traitsById, traitSalesByTraitId]
  );

  const checkoutContainer = useMemo(() => {
    if (isFancyBearTraitCollection) {
      return <FancyBearTraitsHoneyCheckoutContainer onSuccess={onSuccess} />;
    }
    if (currency === Currency.Eth) {
      return <V2TraitsEthCheckoutContainer onSuccess={onSuccess} />;
    }
    if (currency === Currency.Honey) {
      return <V2TraitsHoneyCheckoutContainer onSuccess={onSuccess} />;
    }

    return <V2TraitsErc20CheckoutContainer onSuccess={onSuccess} />;
  }, [currency, isFancyBearTraitCollection, onSuccess]);

  return changedTraitIds ? (
    <SimplePopup
      title={translate(
        isMultiple ? 'purchase:title:multi' : 'purchase:title:single',
        {
          count: changedTraitIds.length,
        }
      )}
      hasScrollArea
      {...rest}
    >
      <Box mt="4" mb="2">
        <TinyHeading mb="2">{translate('purchase:traits:title')}</TinyHeading>

        <Swiper
          spaceBetween={2}
          threshold={10}
          modules={[Controller]}
          slidesPerView={slidesPerView}
          freeMode
        >
          {changedTraitIds.map(traitId => (
            <SwiperSlide key={traitId}>{renderTile(traitId)}</SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {checkoutContainer}
    </SimplePopup>
  ) : null;
};

export default PurchasePopup;

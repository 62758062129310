import { Box, Divider } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import Tab from '../components/Tab';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';

const CategorySelectorContainer = () => {
  const { categoriesById } = useDynamicNftCollectionContext();
  const { currentCategoryId, availableCategoryIds, changeCategory } =
    useDressingRoomContext();
  const [swiper, setSwiper] = useState<any>(null);

  const slideToCategory = useCallback(
    (categoryId: string) => {
      if (!swiper || swiper.destroyed || !availableCategoryIds) {
        return;
      }

      const categoryIndex =
        availableCategoryIds?.findIndex(id => categoryId === id) || -1;

      const isLast = categoryIndex === availableCategoryIds.length - 1;
      swiper.slideTo(isLast ? categoryIndex : Math.max(categoryIndex - 1, 0));
    },
    [swiper, availableCategoryIds]
  );

  const handleCategoryChange = useCallback(
    (categoryId: string) => {
      changeCategory(categoryId);
      slideToCategory(categoryId);
    },
    [changeCategory, slideToCategory]
  );

  const renderItem = useCallback(
    categoryId => {
      const category = categoriesById[categoryId];

      return (
        <SwiperSlide
          key={categoryId}
          style={{
            width: 'auto',
          }}
        >
          <Tab
            size="sm"
            px={{
              base: '3',
              md: '4',
            }}
            isActive={currentCategoryId === categoryId}
            onClick={() => handleCategoryChange(categoryId)}
          >
            {category.displayName}
          </Tab>
        </SwiperSlide>
      );
    },
    [currentCategoryId, categoriesById, handleCategoryChange]
  );

  return availableCategoryIds?.length ? (
    <Box position="relative" w="full">
      <Divider position="absolute" bottom="0" left="0" right="0" />

      <Swiper
        spaceBetween={0}
        slidesPerView="auto"
        threshold={10}
        modules={[Controller]}
        onSwiper={setSwiper}
        freeMode
      >
        {availableCategoryIds.map(renderItem)}
      </Swiper>
    </Box>
  ) : null;
};

export default CategorySelectorContainer;

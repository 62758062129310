import axios from 'axios';
import config from '../constants/baseConfig';

export type AcceptTermsReqData = {
  walletAddress: string;
  collectionAddress: string;
  signature?: string;
  tokenIds: number[];
};

export const acceptTerms = async ({
  walletAddress,
  collectionAddress,
  tokenIds,
  signature,
}: AcceptTermsReqData): Promise<void> => {
  await axios.post<{ tokenId: number; quantity: number }[]>(
    '/terms/accept',
    { tokenIds, signature, address: walletAddress, collectionAddress },
    {
      baseURL: config.urls.traitswapApiUrl,
    }
  );
  return;
};

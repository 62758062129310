import { AspectRatioProps, Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { ImageSize } from '../types';

export type TraitImageProps = Omit<AspectRatioProps, 'ratio' | 'color'> & {
  traitId: string;
  postfix?: string;
  size?: ImageSize;
  isDisabled?: boolean;
  isBottom?: boolean;
};

const TraitImage = ({
  traitId,
  postfix,
  size = '512',
  isDisabled = false,
  isBottom = false,
  ...rest
}: TraitImageProps) => {
  const { traitsById } = useDynamicNftCollectionContext();

  const trait = traitsById[traitId];

  const imagesPaths = useMemo(() => {
    const placeholderPath = `url(${config.urls.publicUrl}/v2-placeholder.png)`;
    const paths = [`url(${trait.files[0].paths[size]})`];

    if (isBottom) {
      paths.unshift(placeholderPath);
    } else {
      paths.push(placeholderPath);
    }

    return paths.join(',');
  }, [trait.files, size, isBottom]);

  return (
    <Box
      w="full"
      h="full"
      borderRadius="md"
      bgColor="dark.500"
      bgImage={imagesPaths}
      bgPosition="0, 0"
      bgSize={'cover'}
      bgRepeat="no-repeat"
      {...rest}
    />
  );
};

export default TraitImage;

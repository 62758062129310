import useVestingContext from './useVestingContext';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';

const useJarsWithVestedHoneyUtil = () => {
  const { vestingAmountInJarsRemaining } = useVestingContext();

  return useMemo(
    () =>
      vestingAmountInJarsRemaining
        ? Object.entries(vestingAmountInJarsRemaining)
            .filter(([, honeyAmount]) => !honeyAmount.isZero())
            .map(([tokenId, amount]) => [parseInt(tokenId, 10), amount])
        : [],
    [vestingAmountInJarsRemaining]
  ) as [tokenId: number, amount: BigNumber][];
};

export default useJarsWithVestedHoneyUtil;

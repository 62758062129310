import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useTranslate from '../hooks/useTranslate';
import ErrorDetails from './ErrorDetails';
import Panel from './Panel';

const NotFoundMessage = () => {
  const translate = useTranslate();

  return (
    <Panel py="12" px="12" maxW="xs">
      <ErrorDetails
        title={translate('error:notFound:title')}
        description={translate('error:notFound:description')}
      >
        <Link
          to="/"
          style={{
            display: 'block',
            width: '100%',
          }}
        >
          <Button as="span" colorScheme="primary" w="full">
            {translate('error:notFound:backToHome')}
          </Button>
        </Link>
      </ErrorDetails>
    </Panel>
  );
};

export default NotFoundMessage;

import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import { FaTimes } from 'react-icons/fa';
import FloatingPanel from '../components/FloatingPanel';
import Panel from '../components/Panel';
import StakeTraitsPopup from '../components/StakeTraitsPopup';
import StakeTraitsSuccessPopup from '../components/StakeTraitsSuccessPopup';
import TraitTile from '../components/TraitTile';
import config from '../constants/baseConfig';
import useApplyTraitsSwapsUtil from '../hooks/useApplyTraitsSwapsUtil';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import SelectedTraitsContainer from './SelectedTraitsContainer';

const WardrobeHangerContainer = () => {
  const translate = useTranslate();
  const { traitsById, isFancyBearsCollection } =
    useDynamicNftCollectionContext();
  const { dynamicNft } = useDynamicNftContext();
  const { dynamicNftsInWallet, stakedFancyBears } = useWalletAssetsContext();
  const { originalTraitIds, changedTraitIds, removeTrait } =
    useDressingRoomContext();
  const [selectedTraitIds, setSelectedTraitIds] = useState<string[]>([]);
  const [isStakeTraitsPopupOpen, setIsStakeTraitsPopupOpen] = useBoolean();
  const [isStakeTraitsSuccessPopupOpen, setIsStakeTraitsSuccessPopupOpen] =
    useBoolean();

  const isInWallet = dynamicNftsInWallet?.includes(dynamicNft.tokenId);
  const isStaked =
    isFancyBearsCollection && stakedFancyBears?.includes(dynamicNft.tokenId);
  const isOwner = isInWallet || isStaked;

  const hasChanges = !!(changedTraitIds && changedTraitIds.length);

  const newTraitIds = useApplyTraitsSwapsUtil(
    dynamicNft.traitIds,
    selectedTraitIds
  );

  const handleStake = useCallback(() => {
    setSelectedTraitIds(changedTraitIds!);
    setIsStakeTraitsPopupOpen.on();
  }, [changedTraitIds, setSelectedTraitIds, setIsStakeTraitsPopupOpen]);

  const handleStakeSuccess = useCallback(() => {
    setIsStakeTraitsPopupOpen.off();
    setIsStakeTraitsSuccessPopupOpen.on();

    ReactGA.event({
      category: 'Trait Swap',
      action: 'Stake',
      label: selectedTraitIds?.join(', ') || undefined,
    });
  }, [
    selectedTraitIds,
    setIsStakeTraitsPopupOpen,
    setIsStakeTraitsSuccessPopupOpen,
  ]);

  const renderItem = useCallback(
    (traitId: string) => {
      const trait = traitsById[traitId];

      if (!trait) {
        return <></>;
      }

      return (
        <Box key={traitId} w="full">
          <TraitTile
            traitId={traitId}
            tokenId={trait.tokenId}
            category={trait.category}
            name={trait.name}
            canvasSize="12"
            bg="dark.900"
          >
            <HStack spacing="3" p="1.5">
              <IconButton
                size="sm"
                aria-label=""
                icon={<Icon as={FaTimes} />}
                borderRadius="full"
                colorScheme="dark"
                onClick={() => removeTrait(traitId)}
              />
            </HStack>
          </TraitTile>
        </Box>
      );
    },
    [traitsById, removeTrait]
  );

  return (
    <>
      <FloatingPanel>
        {isOwner && hasChanges && (
          <Panel bg="dark.800" outline="2px solid" outlineColor="dark.500">
            <VStack spacing="3">
              <SelectedTraitsContainer renderItem={renderItem} />

              <Button w="full" onClick={handleStake}>
                {translate('hanger:stakeAll', {
                  count: changedTraitIds.length,
                })}
              </Button>
            </VStack>
          </Panel>
        )}
      </FloatingPanel>

      {selectedTraitIds && isStakeTraitsPopupOpen && (
        <StakeTraitsPopup
          isOpen
          dynamicNftTokenId={dynamicNft.tokenId}
          currentTraitIds={originalTraitIds}
          traitIdsToStake={selectedTraitIds}
          newTraitIds={newTraitIds}
          onClose={setIsStakeTraitsPopupOpen.off}
          onSuccess={handleStakeSuccess}
        />
      )}

      {selectedTraitIds && isStakeTraitsSuccessPopupOpen && (
        <StakeTraitsSuccessPopup
          isOpen
          title={translate('stake:success:title')}
          description={translate('stake:success:description', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
          dynamicNftTokenId={dynamicNft.tokenId}
          newTraitIds={newTraitIds}
          withConfetti
        />
      )}
    </>
  );
};

export default WardrobeHangerContainer;

import { Box } from '@chakra-ui/react';
import Footer from './Footer';
import React from 'react';

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Box
    minH="100vh"
    display="flex"
    flexDirection="column"
    justifyContent="stretch"
  >
    {children}
    <Footer />
  </Box>
);

export default Layout;

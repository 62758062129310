import React from 'react';
import { TraitCollection } from '../types';

export type TraitCollectionContextValue = {
  traitCollection: TraitCollection;
  changeTraitCollection: (traitCollectionId: string) => void;
  isFancyBearTraitCollection: boolean;
};

const TraitCollectionContext =
  // @ts-ignore
  React.createContext<TraitCollectionContextValue>();

TraitCollectionContext.displayName = 'TraitCollectionContext';

export default TraitCollectionContext;

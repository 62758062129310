import { Badge, Box, BoxProps, HStack, Progress, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import useTranslate from '../hooks/useTranslate';
import EtherAmount from './EtherAmount';

type fancyBearLevelProps = BoxProps & {
  level: number;
  nextLevelProgress: number;
  nextLevelHoneyRequirement: BigNumber;
};

const FancyBearLevel = ({
  level,
  nextLevelProgress,
  nextLevelHoneyRequirement,
  ...rest
}: fancyBearLevelProps) => {
  const translate = useTranslate();

  return (
    <Box {...rest}>
      <HStack justify="space-between" align="flex-end" mb="2" spacing="0">
        <Badge
          fontWeight="semibold"
          px="2"
          py="0.5"
          borderRadius="full"
          bg="dark.500"
          color="bright.400"
          isTruncated
        >
          {translate('common:level')} {level}
        </Badge>

        <Text
          fontSize="xs"
          textTransform="uppercase"
          fontWeight="semibold"
          lineHeight="1"
          isTruncated
          opacity="0.6"
        >
          {translate('common:nextLevel')}{' '}
          <EtherAmount amount={nextLevelHoneyRequirement} />
        </Text>
      </HStack>

      <Progress h="2" colorScheme="secondary" value={nextLevelProgress} />
    </Box>
  );
};

export default FancyBearLevel;

import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import useKeyboardActionListener, {
  KeyboardAction,
} from '../hooks/useKeyboardActionListener';

import EmptyState from '../components/EmptyState';
import InViewChecker from '../components/InViewChecker';
import useDressingRoomContext from '../hooks/useDressingRoomContext';

type TraitsGridModeSelectorContainerProps = {
  renderItem: (
    traitId: string,
    isSelected: boolean,
    isOriginal: boolean,
    isVisible: boolean
  ) => JSX.Element;
  traitIdsBlacklist?: string[];
};

const TraitsGridModeSelectorContainer = ({
  renderItem,
  traitIdsBlacklist = [],
}: TraitsGridModeSelectorContainerProps) => {
  const {
    currentCategoryId,
    originalTraitIds,
    currentTraitIds,
    availableTraitIdsByCategory,
    addOrRemoveTrait,
    removeTrait,
  } = useDressingRoomContext();

  const columns = useBreakpointValue({
    base: 2,
    sm: 3,
  });

  const traitsInCurrentCategory = useMemo(
    () =>
      currentCategoryId && availableTraitIdsByCategory
        ? availableTraitIdsByCategory[currentCategoryId] || []
        : [],
    [availableTraitIdsByCategory, currentCategoryId]
  );

  const currentTraitIndex = useMemo(
    () =>
      traitsInCurrentCategory?.findIndex(
        traitId => currentTraitIds.indexOf(traitId) >= 0
      ),
    [traitsInCurrentCategory, currentTraitIds]
  );

  const selectNext = useCallback(
    (toIndex = currentTraitIndex) => {
      const index =
        toIndex < traitsInCurrentCategory.length - 1 ? toIndex + 1 : -1;

      if (index >= 0) {
        const traitId = traitsInCurrentCategory[index];

        if (!traitIdsBlacklist.includes(traitId)) {
          addOrRemoveTrait(traitId);
        } else {
          selectNext(index);
        }
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      addOrRemoveTrait,
      traitIdsBlacklist,
    ]
  );

  const selectPrev = useCallback(
    (toIndex = currentTraitIndex) => {
      const index = toIndex > 0 ? toIndex - 1 : -1;

      if (index >= 0) {
        const traitId = traitsInCurrentCategory[index];

        if (!traitIdsBlacklist.includes(traitId)) {
          addOrRemoveTrait(traitId);
        } else {
          selectPrev(index);
        }
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      addOrRemoveTrait,
      traitIdsBlacklist,
    ]
  );

  const handleKeyboardAction = useCallback(
    (action: KeyboardAction) => {
      if (action === KeyboardAction.Cancel) {
        removeTrait(traitsInCurrentCategory[currentTraitIndex]);
      }

      if (action === KeyboardAction.Next) {
        selectNext();
      }

      if (action === KeyboardAction.Prev) {
        selectPrev();
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      selectNext,
      selectPrev,
      removeTrait,
    ]
  );

  useKeyboardActionListener(
    handleKeyboardAction,
    !!(currentTraitIndex !== undefined && traitsInCurrentCategory)
  );

  return (
    <Box w="full">
      {traitsInCurrentCategory?.length ? (
        <SimpleGrid columns={columns} spacing="2">
          {traitsInCurrentCategory.map(traitId => (
            <InViewChecker key={traitId}>
              {isInView => {
                const item = renderItem(
                  traitId,
                  currentTraitIds.includes(traitId),
                  originalTraitIds.includes(traitId),
                  isInView
                );
                const isOnBlacklist = traitIdsBlacklist.includes(traitId);

                return React.cloneElement(item, {
                  onClick: () => addOrRemoveTrait(traitId),
                  isDisabled: isOnBlacklist,
                });
              }}
            </InViewChecker>
          ))}
        </SimpleGrid>
      ) : (
        <EmptyState py="7" opacity="0.4" />
      )}
    </Box>
  );
};

export default TraitsGridModeSelectorContainer;

import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { AspectRatioProps, Box } from '@chakra-ui/react';
import { getFilesToRenderForTraits } from '../utils/traitsRenderUtils';
import { ImageSize } from '../types';
import { memo, useMemo } from 'react';

export type TraitsPreviewProps = {
  traitIds: string[];
  imagesSize?: ImageSize;
  hasV2Badge?: boolean;
} & Omit<AspectRatioProps, 'ratio'>;

const TraitsPreview = ({
  traitIds,
  imagesSize = '512',
  hasV2Badge = false,
  ...rest
}: TraitsPreviewProps) => {
  const { traitsById, categoriesById } = useDynamicNftCollectionContext();

  const bgImage = useMemo(() => {
    if (!traitIds.length) {
      return `url(${config.urls.publicUrl}/v2-placeholder.png)`;
    }

    const traitFiles = [];
    if (hasV2Badge) {
      traitFiles.push(`url(${config.urls.publicUrl}/v2-badge.png)`);
    }

    traitFiles.push(
      ...getFilesToRenderForTraits(traitIds, traitsById, categoriesById)
        .map(file => `url(${file[imagesSize]})`)
        .reverse()
    );

    traitFiles.push(`url(${config.urls.publicUrl}/v2-placeholder.png)`);

    return traitFiles.join(',');
  }, [traitIds, traitsById, categoriesById, imagesSize, hasV2Badge]);

  return (
    <Box
      bgColor="dark.500"
      borderRadius="lg"
      bgImage={bgImage}
      bgPosition="0, 0"
      bgSize="cover"
      bgRepeat="no-repeat"
      top="0"
      left="0"
      w="full"
      h="full"
      {...rest}
    />
  );
};

export default memo(
  TraitsPreview,
  (prevProps, nextProps) =>
    prevProps.traitIds.join('') === nextProps.traitIds.join('')
);

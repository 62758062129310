import { BoxProps } from '@chakra-ui/react';
import MotionBox from './MotionBox';

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.1 } },
  exit: { opacity: 0 },
};

type AnimatedPageProps = BoxProps;

const AnimatedPage = (props: AnimatedPageProps) => (
  // @ts-ignore
  <MotionBox
    initial="initial"
    animate="animate"
    exit="exit"
    variants={pageVariants}
    {...props}
  />
);

export default AnimatedPage;

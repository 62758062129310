import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { formatEtherBalance, getRandomInt } from '../utils/numberUtils';
import { useCallback, useMemo, useState } from 'react';

import { Currency } from '../types';
import DynamicNftImage from '../components/DynamicNftImage';
import DynamicNftSelect from '../components/DynamicNftSelect';
import TinyHeading from '../components/TinyHeading';
import config from '../constants/baseConfig';
import { getCurrencySymbol } from '../utils/currencyUtils';
import useAllOwnedDynamicNftsTokenIdsUtil from '../hooks/useAllOwnedDynamicNftsTokenIdsUtil';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useStoreContext from '../hooks/useStoreContext';
import useStoreSelectedTraitsTotalPriceUtil from '../hooks/useStoreSelectedTraitsTotalPriceUtil';
import useTranslate from '../hooks/useTranslate';
import useV2TraitsPurchaseWithEthContractCall from '../hooks/bc/useV2TraitsPurchaseWithEthContractCall';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

type V2TraitsEthCheckoutContainerProps = {
  onSuccess: () => void;
};

const V2TraitsEthCheckoutContainer = ({
  onSuccess,
}: V2TraitsEthCheckoutContainerProps) => {
  const translate = useTranslate();
  const { traitSalesByTraitId } = useStoreContext();
  const { ethBalance } = useWalletAssetsContext();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const { changedTraitIds } = useDressingRoomContext();
  const { dynamicNft } = useDynamicNftContext();

  const ownedTokenIds = useAllOwnedDynamicNftsTokenIdsUtil();
  const totalPrice = useStoreSelectedTraitsTotalPriceUtil(
    changedTraitIds || []
  );

  const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>(
    dynamicNft.tokenId
  );

  const traitTokenIds = useMemo(
    () =>
      changedTraitIds?.map(traitId => traitSalesByTraitId[traitId].tokenId) ||
      [],
    [changedTraitIds, traitSalesByTraitId]
  );

  const isFree = totalPrice.eq('0');
  const isTooExpensive = ethBalance ? ethBalance.gt(ethBalance) : true;

  const [purchase, isPurchasing] = useV2TraitsPurchaseWithEthContractCall(
    selectedTokenId,
    traitTokenIds,
    totalPrice,
    onSuccess
  );

  const handleDynamicNftChange = useCallback((tokenId?: number) => {
    setSelectedTokenId(tokenId);
  }, []);

  const handleDrawDynamicNft = useCallback(
    () =>
      setSelectedTokenId(
        dynamicNftTokenIds[getRandomInt(0, dynamicNftTokenIds.length)]
      ),
    [dynamicNftTokenIds]
  );

  return (
    <VStack spacing="6" w="full">
      <Box position="relative" zIndex="dropdown" w="full">
        <TinyHeading mb="2">
          {translate('purchase:dynamicNft:title', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
        </TinyHeading>

        <HStack borderRadius="md" spacing="3" bg="dark.800" px="3" py="3">
          <DynamicNftImage
            tokenId={selectedTokenId}
            objectFit="cover"
            transition="all 0.15s"
            size="256"
            w="10"
            flex="none"
          />

          <Box w="full">
            <DynamicNftSelect
              tokenId={selectedTokenId}
              ownedTokenIds={ownedTokenIds}
              allTokenIds={dynamicNftTokenIds}
              isClearable
              onChange={handleDynamicNftChange}
            />
          </Box>

          <Button
            size="sm"
            colorScheme="dark"
            flex="none"
            onClick={handleDrawDynamicNft}
          >
            {translate('purchase:dynamicNft:draw')}
          </Button>
        </HStack>
      </Box>

      <Button
        w="full"
        colorScheme="primary"
        onClick={() => purchase()}
        isDisabled={isTooExpensive}
        isLoading={isPurchasing}
      >
        {translate(isFree ? 'purchase:submit:free' : 'purchase:submit', {
          price: formatEtherBalance(totalPrice),
          currency: getCurrencySymbol(Currency.Eth),
        })}
      </Button>
    </VStack>
  );
};

export default V2TraitsEthCheckoutContainer;

import Chip from '../components/Chip';
import TraitsCollectionImage from './TraitsCollectionImage';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import { Box } from '@chakra-ui/react';
import { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useCallback, useMemo, useState } from 'react';

const TraitCollectionSelectorContainer = () => {
  const { traitCollectionsById, traitCollections } =
    useDynamicNftCollectionContext();
  const { traitCollection, changeTraitCollection } =
    useTraitCollectionContext();
  const { resetTraits, allTraitIdsByTraitCollection } =
    useDressingRoomContext();
  const [swiper, setSwiper] = useState<any>(null);

  const availableCollectionIds = useMemo(
    () =>
      traitCollections
        .filter(({ hidden, traitCollectionId }) =>
          Boolean(
            !hidden && allTraitIdsByTraitCollection?.[traitCollectionId]?.length
          )
        )
        .map(({ traitCollectionId }) => traitCollectionId),
    [traitCollections, allTraitIdsByTraitCollection]
  );

  const slideToCollection = useCallback(
    (traitCollectionId: string) => {
      if (!swiper || swiper.destroyed) {
        return;
      }

      const collectionIndex =
        availableCollectionIds.findIndex(
          collectionId => traitCollectionId === collectionId
        ) || -1;

      const isLast = collectionIndex === availableCollectionIds.length - 1;
      swiper.slideTo(
        isLast ? collectionIndex : Math.max(collectionIndex - 1, 0)
      );
    },
    [swiper, availableCollectionIds]
  );

  const handleCollectionChange = useCallback(
    (traitCollectionId: string) => {
      resetTraits();
      changeTraitCollection(traitCollectionId);
      slideToCollection(traitCollectionId);
    },
    [changeTraitCollection, resetTraits, slideToCollection]
  );

  const renderItem = useCallback(
    (traitCollectionId: string) => {
      const collection = traitCollectionsById[traitCollectionId];
      const traitsCount =
        allTraitIdsByTraitCollection?.[traitCollectionId].length || 0;

      if (!collection) {
        return null;
      }

      return (
        <SwiperSlide
          key={traitCollectionId}
          style={{
            width: 'auto',
          }}
        >
          <Chip
            badgeText={traitsCount}
            image={
              <TraitsCollectionImage
                collectionId={traitCollectionId}
                w="full"
                position="absolute"
              />
            }
            isActive={traitCollectionId === traitCollection.traitCollectionId}
            onClick={() => handleCollectionChange(traitCollectionId)}
          >
            {collection.name}
          </Chip>
        </SwiperSlide>
      );
    },
    [
      traitCollection,
      allTraitIdsByTraitCollection,
      handleCollectionChange,
      traitCollectionsById,
    ]
  );

  return (
    <Box w="full">
      <Swiper
        spaceBetween={12}
        slidesPerView="auto"
        threshold={10}
        modules={[Controller]}
        onSwiper={setSwiper}
        freeMode
      >
        {availableCollectionIds.map(renderItem)}
      </Swiper>
    </Box>
  );
};

export default TraitCollectionSelectorContainer;

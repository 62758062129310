import {
  Box,
  BoxProps,
  Button,
  Heading,
  HStack,
  Icon,
  useBoolean,
  Wrap,
} from '@chakra-ui/react';
import { FaGift, FaPlay } from 'react-icons/fa';
import useTranslate from '../hooks/useTranslate';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import HowItWorksIllustration from './HowItWorksIllustartion';
import HowItWorksPopup from './HowItWorksPopup';
import IncentivesPopup from './IncentivesPopup';

type HowItWorksBannerProps = BoxProps;

const HowItWorksBanner = (props: HowItWorksBannerProps) => {
  const translate = useTranslate();
  const [isIncentivesPopupOpen, setIsIncentivesPopupOpen] = useBoolean(false);
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useBoolean(false);

  return (
    <>
      <Box
        w="full"
        px="3"
        py="4"
        bg="primary.500"
        borderRadius="lg"
        color="dark.900"
        {...props}
      >
        <HStack justify="space-between" spacing="0" px="2">
          <Box flex="1">
            <Heading color="dark.900" fontSize="xl">
              {translate('howItWorks:title')}
            </Heading>

            <Wrap mt="2" w="full">
              <Button
                colorScheme="dark"
                size="xs"
                bg="dark.800"
                leftIcon={<Icon as={FaPlay} w="3.5" h="3.5" />}
                onClick={setIsVideoPopupOpen.on}
              >
                {translate('howItWorks:video')}
              </Button>

              {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
                <Button
                  mt="3"
                  colorScheme="dark"
                  size="xs"
                  bg="dark.800"
                  leftIcon={<Icon as={FaGift} w="3.5" h="3.5" />}
                  onClick={setIsIncentivesPopupOpen.on}
                >
                  {translate('howItWorks:incentives')}
                </Button>
              )}
            </Wrap>
          </Box>

          <Box
            display={{
              base: 'none',
              sm: 'block',
            }}
            maxW="10rem"
            minW="8rem"
            mr="2%"
          >
            <HowItWorksIllustration width="100%" height="auto" />
          </Box>
        </HStack>
      </Box>

      {isIncentivesPopupOpen && (
        <IncentivesPopup isOpen onClose={setIsIncentivesPopupOpen.off} />
      )}

      <HowItWorksPopup
        isOpen={isVideoPopupOpen}
        onClose={setIsVideoPopupOpen.off}
      />
    </>
  );
};

export default HowItWorksBanner;

import NftLockedHoney from '../types/NftLockedHoney';
import useHiveContext from './useHiveContext';
import useVestingContext from './useVestingContext';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';

const useJarsWithHoneyInVestingOrHiveUtil = () => {
  const { hiveBalanceInHoneyJars } = useHiveContext();
  const { vestingAmountInJarsRemaining } = useVestingContext();

  const jarsWithVestedHoney: [tokenId: string, honeyAmount: BigNumber][] =
    useMemo(
      () =>
        vestingAmountInJarsRemaining
          ? Object.entries(vestingAmountInJarsRemaining).filter(
              ([, honeyAmount]) => !honeyAmount.isZero()
            )
          : [],
      [vestingAmountInJarsRemaining]
    );

  const jarsWithHiveHoney: [tokenId: string, honeyAmount: BigNumber][] =
    useMemo(
      () =>
        hiveBalanceInHoneyJars
          ? Object.entries(hiveBalanceInHoneyJars).filter(
              ([, honeyAmount]) => !honeyAmount.isZero()
            )
          : [],
      [hiveBalanceInHoneyJars]
    );

  const jarsWithHoney = useMemo(() => {
    const jars: Record<string, NftLockedHoney> = {};

    jarsWithVestedHoney.forEach(([tokenId, amount]) => {
      jars[tokenId] = {
        vestedHoneyAmount: amount,
      };
    });

    jarsWithHiveHoney.forEach(([tokenId, amount]) => {
      jars[tokenId] = {
        ...(jars[tokenId] || {}),
        honeyInHiveAmount: amount,
      };
    });

    return Object.entries(jars).map(
      ([tokenId, amounts]) =>
        [parseInt(tokenId, 10), amounts] as [number, NftLockedHoney]
    );
  }, [jarsWithVestedHoney, jarsWithHiveHoney]);

  return jarsWithHoney;
};

export default useJarsWithHoneyInVestingOrHiveUtil;

import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';
import useWalletAssetsContext from './useWalletAssetsContext';
import { useMemo } from 'react';

const useAllOwnedDynamicNftsTokenIdsUtil = () => {
  const { dynamicNftsInWallet, stakedFancyBears } = useWalletAssetsContext();
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();

  return useMemo(() => {
    if (stakedFancyBears === undefined || dynamicNftsInWallet === undefined) {
      return;
    }

    return [
      ...dynamicNftsInWallet,
      ...(isFancyBearsCollection ? stakedFancyBears : []),
    ].sort((a, b) => a - b);
  }, [dynamicNftsInWallet, stakedFancyBears, isFancyBearsCollection]);
};

export default useAllOwnedDynamicNftsTokenIdsUtil;

import { Center } from '@chakra-ui/react';
import { useConnect } from 'wagmi';
import BoldSpinner from '../components/BoldSpinner';
import useConnectedAccount from '../hooks/useConnectedAccount';

type AccountGuardProps = {
  yeyElement: JSX.Element;
  neyElement: JSX.Element;
};

const AccountGuard = ({ yeyElement, neyElement }: AccountGuardProps) => {
  const [
    {
      data: { connected: isConnected },
      loading: isLoading,
    },
  ] = useConnect();
  const [walletAddress] = useConnectedAccount();

  if (isLoading) {
    return (
      <Center h="100vh">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return isConnected && walletAddress ? yeyElement : neyElement;
};

export default AccountGuard;

import { BigNumber } from 'ethers';
import useBigNumbersSumUtil from './useBigNumbersSumUtil';
import useCurrencyContext from './useCurrencyContext';
import useStoreContext from './useStoreContext';

const useStoreSelectedTraitsTotalPriceUtil = (tokenIds: string[] = []) => {
  const { traitSalesByTraitId } = useStoreContext();
  const { currency } = useCurrencyContext();

  return useBigNumbersSumUtil(
    tokenIds.map(
      tokenId =>
        traitSalesByTraitId?.[tokenId].price[currency] || BigNumber.from('0')
    )
  );
};

export default useStoreSelectedTraitsTotalPriceUtil;

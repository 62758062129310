import DynamicNftContext, {
  DynamicNftContextValue,
} from '../contexts/DynamicNftContext';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';

import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useFetchDynamicNftApiCall from '../hooks/api/useFetchDynamicNftApiCall';
import useFetchDynamicNftMetadataApiCall from '../hooks/api/useFetchDynamicNftMetadataApiCall';
import { usePrevious } from '@chakra-ui/react';
import useTokenIdParamUtil from '../hooks/useTokenIdParamUtil';

type DynamicNftProviderProps = {};

const DynamicNftProvider: FunctionComponent<DynamicNftProviderProps> = ({
  children,
}) => {
  const dynamicNftTokenId: number | undefined = useTokenIdParamUtil();
  const lastDynamicNftTokenId = usePrevious(dynamicNftTokenId);

  const { dynamicNftCollection } = useDynamicNftCollectionContext();

  const [dynamicNft, fetchDynamicNftPromise, isDataLoading] =
    useFetchDynamicNftApiCall({
      collectionAddress: dynamicNftCollection.contractAddress,
      dynamicNftTokenId,
    });

  const [dynamicNftMetadata, fetchMetadataPromise, isMetaLoading] =
    useFetchDynamicNftMetadataApiCall(dynamicNftTokenId);

  const fetchMetadata = useCallback(() => {
    if (dynamicNftTokenId !== undefined) {
      fetchMetadataPromise(dynamicNftTokenId);
    }
  }, [dynamicNftTokenId, fetchMetadataPromise]);

  const fetchDynamicNft = useCallback(() => {
    if (dynamicNftTokenId !== undefined) {
      fetchDynamicNftPromise({
        collectionAddress: dynamicNftCollection.contractAddress,
        dynamicNftTokenId,
      });
    }
  }, [dynamicNftTokenId, fetchDynamicNftPromise, dynamicNftCollection]);

  useEffect(() => {
    if (lastDynamicNftTokenId === undefined) {
      return;
    }

    if (dynamicNftTokenId !== lastDynamicNftTokenId) {
      fetchMetadata();
      fetchDynamicNft();
    }
  }, [
    dynamicNftTokenId,
    lastDynamicNftTokenId,
    fetchMetadata,
    fetchDynamicNft,
  ]);

  const contextValue = useMemo<DynamicNftContextValue>(
    () => ({
      // @ts-ignore
      dynamicNft: dynamicNft || {
        nftId: '',
        tokenId: '',
        collectionId: '',
        collectionAddress: '',
        hash: '',
        traitIds: [],
        originalTraitIds: [],
      },
      // @ts-ignore
      dynamicNftMetadata,
      isLoading: isDataLoading || isMetaLoading,
      fetchMetadata,
      fetchDynamicNft,
    }),
    [
      dynamicNft,
      dynamicNftMetadata,
      isDataLoading,
      isMetaLoading,
      fetchMetadata,
      fetchDynamicNft,
    ]
  );

  return (
    <DynamicNftContext.Provider value={contextValue}>
      {children}
    </DynamicNftContext.Provider>
  );
};

export default DynamicNftProvider;

import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useGetConsumedERC20ContractCall = (
  collectionAddress: string,
  collectionTokenId?: number,
  tokenAddress: string = config.contracts.honeyTokenContract.address
): [BigNumber | undefined, () => void] => {
  const args = useMemo(
    () =>
      collectionTokenId && collectionAddress && tokenAddress
        ? [collectionAddress, collectionTokenId, tokenAddress]
        : null,
    [collectionTokenId, collectionAddress, tokenAddress]
  );

  const [{ data: consumedHoney }, readConsumedToken] = useContractRead(
    {
      addressOrName: config.contracts.levelsContract.address,
      contractInterface: config.contracts.levelsContract.interface,
    },
    'getConsumedToken',
    { args }
  );

  return [consumedHoney as any as BigNumber, readConsumedToken];
};

export default useGetConsumedERC20ContractCall;

import { useEffect, useMemo, useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useHoneyJarsInWalletContractCall = (
  walletAddress?: string
): [tokenIds: number[] | undefined, readHoneyJarsInWallet: () => void] => {
  const [honeyJarsInWallet, setHoneyJarsInWallet] = useState<number[]>();

  const [{ data: honeyJarsInWalletData }, readHoneyJarsInWallet] =
    useContractRead(
      {
        addressOrName: config.contracts.honeyJarsContract.address,
        contractInterface: config.contracts.honeyJarsContract.interface,
      },
      'tokensInWallet',
      {
        args: walletAddress,
      }
    );

  useEffect(() => {
    if (honeyJarsInWalletData !== undefined) {
      setHoneyJarsInWallet(
        honeyJarsInWalletData.map(tokenId => tokenId.toNumber() as number)
      );
    }
  }, [honeyJarsInWalletData]);

  return useMemo(
    () => [honeyJarsInWallet, readHoneyJarsInWallet],
    [honeyJarsInWallet, readHoneyJarsInWallet]
  );
};

export default useHoneyJarsInWalletContractCall;

import React from 'react';

export type CacheContextValue = {
  cacheId: string | null;
  restoreCacheId: () => void;
};

// @ts-ignore
const CacheContext = React.createContext<CacheContextValue>();

CacheContext.displayName = 'CacheContext';

export default CacheContext;

import { StackProps, Text, VStack } from '@chakra-ui/react';
import { MetadataAttribute } from '../types';
import TinyHeading from './TinyHeading';

type AttributeProps = StackProps & {
  attribute: MetadataAttribute;
};

const Attribute = ({ attribute, ...rest }: AttributeProps) => (
  <VStack
    bg="dark.700"
    p="3"
    spacing="1"
    align="start"
    overflow="hidden"
    borderRadius="md"
    {...rest}
  >
    <TinyHeading>{attribute.trait_type}</TinyHeading>

    <Text w="full" lineHeight="1.25" isTruncated>
      {attribute.display_type === 'date'
        ? new Date(+attribute.value).toLocaleDateString()
        : attribute.value}
    </Text>
  </VStack>
);

export default Attribute;

import React from 'react';

const SwapIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="40"
    height="10"
    viewBox="0 0 40 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#swapIconClipPaath)">
      <path
        d="M39.41 2.493H38.62C38.5413 2.49169 38.4631 2.50535 38.3902 2.53316C38.3173 2.56097 38.2513 2.60234 38.1961 2.65476C38.1409 2.70717 38.0977 2.76953 38.0691 2.83804C38.0406 2.90654 38.0273 2.97976 38.03 3.05322L38.12 6.92811H38.06C34.44 2.65173 27.47 -0.0466843 19.73 -0.0466843C11.59 1.07656e-06 4.25 3.01587 0.869999 7.71242L0.159999 8.70215C0.114987 8.76252 0.0833826 8.8307 0.0670487 8.90267C0.0507149 8.97463 0.0499829 9.04892 0.0648958 9.12115C0.0798087 9.19338 0.110065 9.2621 0.153878 9.32323C0.197692 9.38437 0.254176 9.4367 0.319999 9.47713L0.979999 9.89729C1.11223 9.9773 1.27249 10.0064 1.4272 9.97859C1.58192 9.95074 1.7191 9.86807 1.81 9.7479L2.3 9.06629C5.24 4.68721 12.1 1.85808 19.78 1.85808C26.88 1.85808 33.45 4.38842 36.64 8.23529L32.64 8.14192C32.5604 8.13944 32.481 8.15178 32.4066 8.17823C32.3321 8.20468 32.264 8.24472 32.2063 8.296C32.1486 8.34728 32.1024 8.40878 32.0704 8.47691C32.0384 8.54504 32.0213 8.61843 32.02 8.69281V9.4211C32.02 9.49467 32.0355 9.56752 32.0657 9.63549C32.0958 9.70346 32.14 9.76522 32.1957 9.81724C32.2514 9.86926 32.3176 9.91053 32.3904 9.93868C32.4632 9.96684 32.5412 9.98132 32.62 9.98132H39.47C39.6291 9.98132 39.7817 9.9223 39.8943 9.81724C40.0068 9.71218 40.07 9.56968 40.07 9.4211V3.05322C40.072 2.97425 40.0559 2.8958 40.0226 2.82318C39.9893 2.75057 39.9396 2.68549 39.877 2.63235C39.8144 2.57922 39.7403 2.53927 39.6598 2.51523C39.5792 2.49118 39.494 2.4836 39.41 2.493V2.493Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="swapIconClipPaath">
        <rect width="40" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SwapIcon;

/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useBreakpointValue } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import useKeyboardActionListener, {
  KeyboardAction,
} from '../hooks/useKeyboardActionListener';

import { Controller } from 'swiper';
import EmptyState from '../components/EmptyState';
import useDressingRoomContext from '../hooks/useDressingRoomContext';

type TraitsGalleryModeSelectorContainerProps = {
  renderItem: (
    traitId: string,
    isSelected: boolean,
    isOriginal: boolean,
    isVisible: boolean
  ) => JSX.Element;
  traitIdsBlacklist?: string[];
};

const TraitsGalleryModeSelectorContainer = ({
  renderItem,
  traitIdsBlacklist = [],
}: TraitsGalleryModeSelectorContainerProps) => {
  const {
    currentCategoryId,
    originalTraitIds,
    currentTraitIds,
    availableTraitIdsByCategory,
    addOrRemoveTrait,
    removeTrait,
  } = useDressingRoomContext();

  const slidesPerView = useBreakpointValue({
    base: 3.25,
    sm: 4.4,
  });

  const itemsGap = useBreakpointValue({
    base: 5,
    sm: 4,
  });

  const [swiper, setSwiper] = useState<any>(null);

  const traitsInCurrentCategory = useMemo(
    () =>
      currentCategoryId && availableTraitIdsByCategory
        ? availableTraitIdsByCategory[currentCategoryId] || []
        : [],
    [availableTraitIdsByCategory, currentCategoryId]
  );

  const currentTraitIndex = useMemo(
    () =>
      traitsInCurrentCategory?.findIndex(
        traitId => currentTraitIds.indexOf(traitId) >= 0
      ),
    [traitsInCurrentCategory, currentTraitIds]
  );

  const slideToTrait = useCallback(
    (traitId: string) => {
      if (!swiper || swiper.destroyed) {
        return;
      }

      const traitIndex = traitsInCurrentCategory?.findIndex(
        id => id === traitId
      );
      const isLast = traitIndex === traitsInCurrentCategory.length - 1;
      swiper.slideTo(isLast ? traitIndex : Math.max(traitIndex - 1, 0));
    },
    [swiper, traitsInCurrentCategory]
  );

  const selectNext = useCallback(
    (toIndex = currentTraitIndex) => {
      const index =
        toIndex < traitsInCurrentCategory.length - 1 ? toIndex + 1 : -1;

      if (index >= 0) {
        const traitId = traitsInCurrentCategory[index];

        if (!traitIdsBlacklist.includes(traitId)) {
          addOrRemoveTrait(traitId);
          slideToTrait(traitId);
        } else {
          selectNext(index);
        }
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      addOrRemoveTrait,
      slideToTrait,
      traitIdsBlacklist,
    ]
  );

  const selectPrev = useCallback(
    (toIndex = currentTraitIndex) => {
      const index = toIndex > 0 ? toIndex - 1 : -1;

      if (index >= 0) {
        const traitId = traitsInCurrentCategory[index];

        if (!traitIdsBlacklist.includes(traitId)) {
          addOrRemoveTrait(traitId);
          slideToTrait(traitId);
        } else {
          selectPrev(index);
        }
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      addOrRemoveTrait,
      slideToTrait,
      traitIdsBlacklist,
    ]
  );

  const handleKeyboardAction = useCallback(
    (action: KeyboardAction) => {
      if (action === KeyboardAction.Cancel) {
        removeTrait(traitsInCurrentCategory[currentTraitIndex]);
      }

      if (action === KeyboardAction.Next) {
        selectNext();
      }

      if (action === KeyboardAction.Prev) {
        selectPrev();
      }
    },
    [
      currentTraitIndex,
      traitsInCurrentCategory,
      removeTrait,
      selectNext,
      selectPrev,
    ]
  );

  useEffect(() => {
    const traitId = traitsInCurrentCategory?.find(
      traitId => currentTraitIds.indexOf(traitId) >= 0
    );

    if (traitId) {
      slideToTrait(traitId);
    }
  }, [swiper, traitsInCurrentCategory]);

  useKeyboardActionListener(
    handleKeyboardAction,
    !!(currentTraitIndex !== undefined && traitsInCurrentCategory)
  );

  return (
    <Box w="full">
      {traitsInCurrentCategory?.length ? (
        <Swiper
          spaceBetween={itemsGap}
          threshold={10}
          slidesPerView={slidesPerView}
          modules={[Controller]}
          onSwiper={setSwiper}
          freeMode
          watchSlidesProgress
        >
          {traitsInCurrentCategory.map(traitId => (
            <SwiperSlide key={traitId}>
              {({ isVisible, isPrev, isNext }) => {
                const item = renderItem(
                  traitId,
                  currentTraitIds.includes(traitId),
                  originalTraitIds.includes(traitId),
                  isVisible || isPrev || isNext
                );
                const isOnBlacklist = traitIdsBlacklist.includes(traitId);

                return (
                  <Box p="2px">
                    {React.cloneElement(item, {
                      isDisabled: isOnBlacklist,
                      onClick: () => {
                        addOrRemoveTrait(traitId);
                        slideToTrait(traitId);
                      },
                    })}
                  </Box>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EmptyState py="7" opacity="0.4" />
      )}
    </Box>
  );
};

export default TraitsGalleryModeSelectorContainer;

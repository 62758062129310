/* eslint-disable react-hooks/exhaustive-deps */

// @ts-ignore
import { serializeError } from 'eth-rpc-errors';
import { useEffect } from 'react';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';

const useSnackbarError = (error?: { message?: string }) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar(
        'error',
        serializeError(error).message || translate('error:default')
      );
    }
  }, [error]);
};

export default useSnackbarError;

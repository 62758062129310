import { useEffect, useMemo, useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useFancyBearsInWalletContractCall = (
  walletAddress?: string
): [tokenIds: number[] | undefined, readFancyBearsInWallet: () => void] => {
  const [fancyBearsInWallet, setFancyBearsInWallet] = useState<number[]>();

  const [{ data: fancyBearsInWalletData }, readFancyBearsInWallet] =
    useContractRead(
      {
        addressOrName: config.contracts.fancyBearsContract.address,
        contractInterface: config.contracts.fancyBearsContract.interface,
      },
      'tokensInWallet',
      {
        args: walletAddress,
      }
    );

  useEffect(() => {
    if (fancyBearsInWalletData) {
      setFancyBearsInWallet(
        fancyBearsInWalletData?.map(bn => bn.toNumber() as number)
      );
    }
  }, [fancyBearsInWalletData]);

  return useMemo(
    () => [fancyBearsInWallet, readFancyBearsInWallet],
    [fancyBearsInWallet, readFancyBearsInWallet]
  );
};

export default useFancyBearsInWalletContractCall;

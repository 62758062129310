import { BigNumber } from 'ethers';

const sumBigNumbers = (bigNumbers: BigNumber[]) =>
  bigNumbers.reduce(
    (sum: BigNumber, bigNumber) =>
      BigNumber.isBigNumber(bigNumber) ? sum.add(bigNumber || '0') : sum,
    BigNumber.from('0')
  );

export default sumBigNumbers;

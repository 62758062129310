import React from 'react';

export enum DressingRoomMode {
  Gallery = 'gallery',
  Grid = 'grid',
}

export enum DressingRoomScope {
  Store = 'store',
  Wardrobe = 'wardrobe',
}

export type DressingRoomContextValue = {
  currentMode: DressingRoomMode;
  currentCategoryId?: string;
  currentTraitIds: string[];
  originalTraitIds: string[];
  changedTraitIds?: string[];
  availableTraitIds?: string[];
  allTraitIds?: string[];
  allTraitIdsByTraitCollection?: Record<string, string[]>;
  availableTraitIdsByCategory?: Record<string, string[]>;
  availableCategoryIds?: string[];
  canRedo: boolean;
  canUndo: boolean;
  undoSwap: () => void;
  redoSwap: () => void;
  resetTraits: () => void;
  addTrait: (traitId: string) => void;
  removeTrait: (traitId: string) => void;
  addOrRemoveTrait: (traitId: string) => void;
  swapTraits: (newTraitIds: string[]) => void;
  changeMode: (mode: DressingRoomMode) => void;
  changeCategory: (category: string) => void;
};

// @ts-ignore
const DressingRoomContext = React.createContext<DressingRoomContextValue>();

DressingRoomContext.displayName = 'DressingRoomContext';

export default DressingRoomContext;

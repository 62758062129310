import {
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import pluralize, { plural } from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BoldSpinner from '../components/BoldSpinner';
import EmptyNftCardWithLink from '../components/EmptyNftCardWithLink';
import MintSuccessPopup from '../components/MintSuccessPopup';
import Panel from '../components/Panel';
import StaticNftCard from '../components/StaticNftCard';
import TermsPopup from '../components/TermsPopup';
import config from '../constants/baseConfig';
import useDynamicNftMintContractCall from '../hooks/bc/useDynamicNftMintContractCall';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftSaleContext from '../hooks/useDynamicNftSaleContext';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import { Currency } from '../types';
import { getCurrencySymbol } from '../utils/currencyUtils';
import { formatEtherBalance } from '../utils/numberUtils';

const StaticNftsContainer = () => {
  const translate = useTranslate();
  const { price } = useDynamicNftSaleContext();
  const [walletAddress] = useConnectedAccount();
  const { staticNftsInWallet, fancyBearsInWallet } = useWalletAssetsContext();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const [isMintSuccessPopupOpen, setIsMintSuccessPopupOpen] = useBoolean();
  const [isTermsPopupOpen, setIsTermsPopupOpen] = useBoolean();
  const [tokenIds, setTokenIds] = useState<number[]>();

  const totalPrice = useMemo(() => {
    if (tokenIds?.length && price && fancyBearsInWallet) {
      const total = price.mul(tokenIds.length);
      return fancyBearsInWallet.length ? total.div(2) : total;
    }
  }, [price, tokenIds, fancyBearsInWallet]);

  const handleMintSuccess = useCallback(() => {
    setIsMintSuccessPopupOpen.on();
  }, [setIsMintSuccessPopupOpen]);

  const [mint, isMinting] = useDynamicNftMintContractCall(
    totalPrice,
    tokenIds,
    handleMintSuccess
  );

  const handleReadTerms = useCallback(() => {
    setIsTermsPopupOpen.on();
  }, [setIsTermsPopupOpen]);

  const handleTermsConsentSuccess = useCallback(() => {
    setIsTermsPopupOpen.off();
    mint();
  }, [mint, setIsTermsPopupOpen]);

  const handleV1Select = useCallback(
    (tokenId: number) => {
      const isSelected = tokenIds?.includes(tokenId);

      if (isSelected) {
        setTokenIds(tokenIds?.filter(id => tokenId !== id));
      } else {
        setTokenIds([...(tokenIds || []), tokenId]);
      }
    },
    [tokenIds]
  );

  const renderCard = useCallback(
    (tokenId: number) => {
      const isMinted = dynamicNftTokenIds.includes(tokenId);

      return (
        <StaticNftCard
          key={tokenId}
          tokenId={tokenId}
          isSelected={tokenIds?.includes(tokenId)}
          isDisabled={isMinted}
          badgeText={isMinted ? translate('common:minted') : undefined}
          onClick={() => handleV1Select(tokenId)}
        >
          <Text fontWeight="600" px="1.5" pt="1.5">
            #{tokenId}
          </Text>
        </StaticNftCard>
      );
    },
    [handleV1Select, tokenIds, dynamicNftTokenIds, translate]
  );

  useEffect(() => {
    if (staticNftsInWallet?.length && !tokenIds) {
      const availableBearsToClaim = staticNftsInWallet.filter(
        tokenId => !dynamicNftTokenIds.includes(tokenId)
      );

      setTokenIds(availableBearsToClaim);
    }
  }, [dynamicNftTokenIds, staticNftsInWallet, tokenIds]);

  if (staticNftsInWallet === undefined || price === undefined) {
    return (
      <Center h="52">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return (
    <>
      <Panel>
        <Box mb="4" pl="2" mt="1.5">
          <Heading fontSize="2xl">
            {translate('staticNfts:title', {
              count: staticNftsInWallet.length,
              staticNftName: plural(config.staticNft.staticNftName),
            })}
          </Heading>
          <Text fontSize="sm" mt="1" opacity="0.8">
            {translate('staticNfts:description', {
              dynamicNftName: plural(config.dynamicNft.dynamicNftName),
            })}
          </Text>
        </Box>

        <SimpleGrid
          columns={{
            base: 2,
            sm: staticNftsInWallet.length > 1 ? 3 : 2,
          }}
          spacing="2"
          w="full"
        >
          {staticNftsInWallet.sort((a, b) => b - a).map(renderCard)}

          <EmptyNftCardWithLink
            label={translate('common:buyOnOpenSea')}
            externalUrl={config.urls.staticNftOpenSeaUrl}
          />
        </SimpleGrid>

        <Box pt="5" pb="2" px="1.5">
          <Button
            onClick={handleReadTerms}
            isDisabled={!tokenIds?.length}
            colorScheme={tokenIds?.length ? 'primary' : 'dark'}
            isLoading={isMinting}
            w="full"
          >
            {tokenIds !== undefined && tokenIds.length > 0
              ? tokenIds.length > 1
                ? translate('staticNfts:mint:multi', {
                    count: tokenIds.length,
                    price: formatEtherBalance(totalPrice!),
                    currency: getCurrencySymbol(Currency.Eth),
                    dynamicNftName: pluralize(
                      config.dynamicNft.dynamicNftName,
                      tokenIds.length
                    ),
                  })
                : translate('staticNfts:mint:one', {
                    price: formatEtherBalance(totalPrice!),
                    currency: getCurrencySymbol(Currency.Eth),
                    dynamicNftName: pluralize(
                      config.dynamicNft.dynamicNftName,
                      tokenIds.length
                    ),
                  })
              : translate('staticNfts:mint:none', {
                  dynamicNftName: plural(config.dynamicNft.dynamicNftName),
                })}
          </Button>
        </Box>
      </Panel>

      {tokenIds && walletAddress && isTermsPopupOpen && (
        <TermsPopup
          isOpen
          walletAddress={walletAddress}
          tokenIds={tokenIds}
          onSuccess={handleTermsConsentSuccess}
          onClose={setIsTermsPopupOpen.off}
        />
      )}

      {tokenIds && isMintSuccessPopupOpen && (
        <MintSuccessPopup
          isOpen
          tokenIds={tokenIds}
          onSuccess={handleMintSuccess}
          onClose={setIsMintSuccessPopupOpen.off}
        />
      )}
    </>
  );
};

export default StaticNftsContainer;

import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export type VideoPopupProps = Omit<ModalProps, 'children'> & {
  src?: string;
  isClosable?: boolean;
  children?: ReactNode;
};

const VideoPopup = ({
  src,
  size = '2xl',
  isClosable = true,
  children,
  ...rest
}: VideoPopupProps) => (
  <Modal
    size={size}
    closeOnEsc={isClosable}
    closeOnOverlayClick={isClosable}
    {...rest}
  >
    <ModalOverlay />
    <ModalContent overflow="hidden">
      <ModalBody p="0" overflow="hidden">
        <AspectRatio ratio={16 / 9}>
          <video src={src} controls autoPlay />
        </AspectRatio>

        {children}
      </ModalBody>

      {isClosable && <ModalCloseButton mt="1" bg="dark.900" />}
    </ModalContent>
  </Modal>
);

export default VideoPopup;

import { AspectRatio, AspectRatioProps, Image } from '@chakra-ui/react';
import config from '../constants/baseConfig';
import { ImageSize } from '../types';

type FancyBearImageProps = Omit<AspectRatioProps, 'ratio'> & {
  tokenId?: number;
  size?: ImageSize;
  isDisabled?: boolean;
};

const FancyBearImage = ({
  tokenId,
  size = '512',
  isDisabled = false,
  ...rest
}: FancyBearImageProps) => (
  <AspectRatio
    ratio={1}
    w="full"
    bg="dark.400"
    borderRadius="md"
    overflow="hidden"
    {...rest}
  >
    <Image
      src={
        tokenId !== undefined
          ? `${config.urls.fancyBearImagesUrl}/${size}/${tokenId}.png`
          : undefined
      }
      fallbackSrc={`${config.urls.publicUrl}/bear-placeholder.png`}
      fit="cover"
      opacity={isDisabled ? 0.5 : 1}
      transition="opacity 0.15s"
    />
  </AspectRatio>
);

export default FancyBearImage;

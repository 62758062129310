import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  ModalProps,
  VStack,
} from '@chakra-ui/react';
import { plural } from 'pluralize';
import { FaExternalLinkAlt } from 'react-icons/fa';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTranslate from '../hooks/useTranslate';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import CopyInput from './CopyInput';
import SimplePopup from './SimplePopup';
import TinyHeading from './TinyHeading';

type ContractsPopupProps = Omit<ModalProps, 'children'> & {};

type ContractInfoBoxProps = {
  name: string;
  address: string;
};

const ContractInfoBox = ({ name, address }: ContractInfoBoxProps) => (
  <Box
    key={address}
    borderRadius="lg"
    bg="dark.800"
    color="bright.50"
    p="3"
    w="full"
  >
    <TinyHeading opacity="0.6" mb="1">
      {name}
    </TinyHeading>

    <HStack w="full" spacing="3">
      <CopyInput value={address} size="md" />
      <IconButton
        as="a"
        href={`https://etherscan.io/address/${address}`}
        target="_blank"
        aria-label=""
        size="xs"
        colorScheme="dark"
        borderRadius="full"
        color="primary.500"
        icon={<Icon as={FaExternalLinkAlt} w="3" h="3" />}
      />
    </HStack>
  </Box>
);

const ContractsPopup = ({ onClose, ...rest }: ContractsPopupProps) => {
  const translate = useTranslate();
  const { dynamicNftCollection } = useDynamicNftCollectionContext();

  return (
    <SimplePopup
      title={translate('common:contracts')}
      onClose={onClose}
      {...rest}
    >
      <Box mt="4">
        <VStack spacing="2px">
          <ContractInfoBox
            name={plural(config.dynamicNft.dynamicNftName)}
            address={dynamicNftCollection.contractAddress}
          />
          {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
            <ContractInfoBox
              name={`${plural(config.dynamicNft.dynamicNftName)} Sale`}
              address={config.contracts.dynamicNftSaleContract.address}
            />
          )}
          <ContractInfoBox
            name="Trait Swap"
            address={config.contracts.traitSwapContract.address}
          />
          <ContractInfoBox
            name="Trait Categories"
            address={config.contracts.traitCategoriesContract.address}
          />
          <ContractInfoBox
            name="Honey Jars"
            address={config.contracts.honeyJarsContract.address}
          />
          <ContractInfoBox
            name="HONEY Token"
            address={config.contracts.honeyTokenContract.address}
          />
          <ContractInfoBox
            name="Hive"
            address={config.contracts.hiveContract.address}
          />
          <ContractInfoBox
            name="HONEY Vesting"
            address={config.contracts.honeyVestingContract.address}
          />
        </VStack>

        <Button w="full" colorScheme="dark" mt="6" onClick={onClose}>
          {translate('common:close')}
        </Button>
      </Box>
    </SimplePopup>
  );
};

export default ContractsPopup;

import Category from '../types/category';
import DynamicNftCollection from '../types/dynamicNftCollection';
import React from 'react';
import { Trait, TraitCollection } from '../types';

export type DynamicNftCollectionContextValue = {
  dynamicNftCollection: DynamicNftCollection;
  categories: Category[];
  categoriesById: Record<string, Category>;
  categoryIndexesById: Record<string, number>;
  traitCollections: TraitCollection[];
  traitCollectionsById: Record<string, TraitCollection>;
  traitCollectionsByAddress: Record<string, TraitCollection>;
  traitsByCollectionId: Record<string, Trait[]>;
  dynamicNftTokenIds: number[];
  traits: Trait[];
  traitsById: Record<string, Trait>;
  fetchDynamicNftIds: () => void;
  fetchCategories: () => void;
  fetchTraitCollections: () => void;
  fetchTraits: () => void;
  isFancyBearsCollection: boolean;
  isLoading: boolean;
};

const DynamicNftCollectionContext =
  // @ts-ignore
  React.createContext<DynamicNftCollectionContextValue>();

DynamicNftCollectionContext.displayName = 'DynamicNftCollectionContext';

export default DynamicNftCollectionContext;

import { Container, HStack, VStack } from '@chakra-ui/react';
import AnimatedPage from '../components/AnimatedPage';
import Panel from '../components/Panel';
import DynamicNftSelectorContainer from '../containers/DynamicNftSelectorContainer';
import ModeSelectorContainer from '../containers/ModeSelectorContainer';
import WardrobeDressingRoomContainer from '../containers/WardrobeDressingRoomContainer';
import WardrobeHangerContainer from '../containers/WardrobeHangerContainer';
import DressingRoomContext, {
  DressingRoomMode,
  DressingRoomScope,
} from '../contexts/DressingRoomContext';
import WardrobeContext from '../contexts/WardrobeContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import usePageTracking from '../hooks/usePageTracking';
import DressingRoomProvider from '../providers/DressingRoomProvider';
import WardrobeProvider from '../providers/WardrobeProvider';

const WardrobePage = () => {
  const { dynamicNft } = useDynamicNftContext();

  usePageTracking();

  return (
    <WardrobeProvider>
      <WardrobeContext.Consumer>
        {({ ownedTraits }) => (
          <DressingRoomProvider
            scope={DressingRoomScope.Wardrobe}
            originalTraitIds={dynamicNft.traitIds || []}
            allTraitIds={ownedTraits.map(trait => trait.traitId)}
          >
            <AnimatedPage>
              <DressingRoomContext.Consumer>
                {({ currentMode }) => (
                  <Container
                    maxW={
                      currentMode === DressingRoomMode.Grid
                        ? 'container.md'
                        : 'container.sm'
                    }
                    pt="12"
                    pb="16"
                  >
                    <Panel>
                      <VStack spacing="3">
                        <HStack
                          justify="space-between"
                          w="full"
                          px="1.5"
                          pt="1.5"
                          pb={
                            currentMode === DressingRoomMode.Grid ? '2' : '1.5'
                          }
                          spacing={{
                            base: '0',
                            sm: '8',
                          }}
                        >
                          <DynamicNftSelectorContainer path="wardrobe" />
                          <ModeSelectorContainer />
                        </HStack>

                        <WardrobeDressingRoomContainer />
                      </VStack>
                    </Panel>

                    <WardrobeHangerContainer />
                  </Container>
                )}
              </DressingRoomContext.Consumer>
            </AnimatedPage>
          </DressingRoomProvider>
        )}
      </WardrobeContext.Consumer>
    </WardrobeProvider>
  );
};

export default WardrobePage;

import {
  Box,
  Center,
  Icon,
  IconButton,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';
import BoldSpinner from '../components/BoldSpinner';
import EmptyState from '../components/EmptyState';
import EtherPrice from '../components/EtherPrice';
import PreviewPopup from '../components/PreviewPopup';
import TraitPreviewCard from '../components/TraitPreviewCard';
import { DressingRoomMode } from '../contexts/DressingRoomContext';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useStoreContext from '../hooks/useStoreContext';
import { addOrReplaceTrait } from '../utils/traitsUtils';
import CategorySelectorContainer from './CategorySelectorContainer';
import TraitCollectionSelectorContainer from './TraitCollectionSelector';
import TraitsGalleryModeSelectorContainer from './TraitsGalleryModeSelectorContainer';
import TraitsGridModeSelectorContainer from './TraitsGridModeSelectorContainer';
import TraitsPreviewContainer from './TraitsPreviewContainer';

const StoreDressingRoomContainer = () => {
  const { currency } = useCurrencyContext();
  const { traitsById } = useDynamicNftCollectionContext();
  const { traitSalesByTraitId, isLoading } = useStoreContext();
  const {
    currentMode,
    availableCategoryIds,
    currentTraitIds,
    availableTraitIds,
  } = useDressingRoomContext();
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useBoolean();

  const idsOfNonbuyableTraits = useMemo(
    () =>
      availableTraitIds?.filter(
        traitId => !traitSalesByTraitId[traitId]?.price[currency]
      ) || [],
    [currency, availableTraitIds, traitSalesByTraitId]
  );

  const renderPreview = useCallback(
    (
      traitId: string,
      isSelected: boolean,
      isOriginal: boolean,
      isVisible: boolean
    ) => {
      const traitSale = traitSalesByTraitId[traitId];
      const traitIdsToPreview = addOrReplaceTrait(
        currentTraitIds,
        traitId,
        traitsById
      );
      const hasZoom = currentMode === DressingRoomMode.Grid;

      return (
        <TraitPreviewCard
          traitId={traitId}
          tokenId={traitSale.tokenId}
          traitIds={traitIdsToPreview}
          totalSupply={traitSale.counter}
          maxSupply={traitSale.maxSupply}
          isSelected={isSelected}
          isActive={isOriginal}
          isVisible={isVisible}
          imageSize="256"
        >
          {hasZoom && (
            <Box
              position="absolute"
              top="0"
              zIndex="10"
              right="0"
              transition="opacity 0.15s"
              opacity={isSelected ? '1' : '0'}
            >
              <IconButton
                bg="dark.700"
                colorScheme="dark"
                borderRadius="md"
                size="xs"
                aria-label=""
                _hover={{
                  color: 'primary.500',
                }}
                onClick={event => {
                  if (isSelected) {
                    event.stopPropagation();
                    setIsPreviewPopupOpen.on();
                  }
                }}
              >
                <Icon as={FaSearch} w="3.5" h="3.5" />
              </IconButton>
            </Box>
          )}

          <EtherPrice
            amount={traitSale.price[currency]}
            asPrice
            fontSize="sm"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="semibold"
            iconColor="primary.500"
          />
        </TraitPreviewCard>
      );
    },
    [
      currency,
      currentTraitIds,
      traitSalesByTraitId,
      traitsById,
      currentMode,
      setIsPreviewPopupOpen,
    ]
  );

  return (
    <>
      {currentMode === DressingRoomMode.Gallery ? (
        <VStack spacing="3" w="full">
          <TraitsPreviewContainer />

          <TraitCollectionSelectorContainer />

          {!isLoading ? (
            availableCategoryIds?.length ? (
              <>
                <CategorySelectorContainer />
                <TraitsGalleryModeSelectorContainer
                  renderItem={renderPreview}
                  traitIdsBlacklist={idsOfNonbuyableTraits}
                />
              </>
            ) : (
              <Center h="32">
                <EmptyState opacity="0.4" />
              </Center>
            )
          ) : (
            <Center h="32">
              <BoldSpinner size="lg" />
            </Center>
          )}
        </VStack>
      ) : (
        <VStack spacing="3" w="full">
          <TraitCollectionSelectorContainer />

          {!isLoading ? (
            availableCategoryIds?.length ? (
              <>
                <CategorySelectorContainer />
                <TraitsGridModeSelectorContainer
                  renderItem={renderPreview}
                  traitIdsBlacklist={idsOfNonbuyableTraits}
                />
              </>
            ) : (
              <Center h="32">
                <EmptyState opacity="0.4" />
              </Center>
            )
          ) : (
            <Center h="32">
              <BoldSpinner size="lg" />
            </Center>
          )}
        </VStack>
      )}

      {isPreviewPopupOpen && (
        <PreviewPopup
          isOpen
          traitIds={currentTraitIds}
          onClose={setIsPreviewPopupOpen.off}
        />
      )}
    </>
  );
};

export default StoreDressingRoomContainer;

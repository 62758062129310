import React from 'react';
import DynamicNftProvider from './DynamicNftProvider';
import DynamicNftSaleProvider from './DynamicNftSaleProvider';
import HiveProvider from './HiveProvider';
import TraitCollectionProvider from './TraitCollectionProvider';
import VestingProvider from './VestingProvider';
import WalletAssetsProvider from './WalletAssetsProvider';

const ComposedConnectedProviders: React.FC = ({ children }) => (
  <WalletAssetsProvider>
    <VestingProvider>
      <HiveProvider>
        <DynamicNftSaleProvider>
          <TraitCollectionProvider>
            <DynamicNftProvider>{children}</DynamicNftProvider>
          </TraitCollectionProvider>
        </DynamicNftSaleProvider>
      </HiveProvider>
    </VestingProvider>
  </WalletAssetsProvider>
);

export default ComposedConnectedProviders;

/* eslint-disable react-hooks/exhaustive-deps */

import { BigNumber, constants } from 'ethers';

import config from '../../constants/baseConfig';
import useApproveAnyErc20ContractCall from './useApproveAnyErc20ContractCall';

const useApproveHoneyContractCall = (
  spenderAddress?: string,
  amount: BigNumber = constants.MaxUint256,
  onSuccess?: () => void
) =>
  useApproveAnyErc20ContractCall(
    config.contracts.honeyTokenContract.address,
    spenderAddress,
    amount,
    onSuccess
  );

export default useApproveHoneyContractCall;

import { applyTraitsUnswaps } from '../utils/traitsUtils';
import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';

const useApplyTraitsUnswapsUtil = (
  originalTraitIds: string[],
  currentTraitIds: string[],
  traitIdsToRemove: string[] = []
): string[] => {
  const { traitsById } = useDynamicNftCollectionContext();

  return applyTraitsUnswaps(
    originalTraitIds,
    currentTraitIds,
    traitIdsToRemove,
    traitsById
  );
};

export default useApplyTraitsUnswapsUtil;

import { AspectRatio, AspectRatioProps, Image } from '@chakra-ui/react';
import config from '../constants/baseConfig';
import useCacheContext from '../hooks/useCacheContext';
import { ImageSize } from '../types';

type DynamicNftImageProps = Omit<AspectRatioProps, 'ratio'> & {
  tokenId?: number;
  size?: ImageSize;
  isDisabled?: boolean;
};

const DynamicNftImage = ({
  tokenId,
  size = '512',
  isDisabled = false,
  ...rest
}: DynamicNftImageProps) => {
  const { cacheId } = useCacheContext();

  return (
    <AspectRatio
      ratio={1}
      w="full"
      bg="dark.400"
      borderRadius="md"
      overflow="hidden"
      {...rest}
    >
      <Image
        src={
          tokenId !== undefined
            ? `${config.urls.dynamicNftImagesUrl}/${
                size !== 'original' ? `${size}/` : ''
              }${tokenId}.png${
                cacheId ? `?cacheId=${encodeURIComponent(cacheId)}` : ''
              }`
            : undefined
        }
        fallbackSrc={`${config.urls.publicUrl}/v2-placeholder.png`}
        fit="cover"
        opacity={isDisabled ? 0.5 : 1}
        transition="opacity 0.15s"
      />
    </AspectRatio>
  );
};

export default DynamicNftImage;

import { useMemo } from 'react';
import * as traitSalesApi from '../../api/traitSalesApi';
import { TraitSale } from '../../types';
import useApiCall from '../useApiCall';

const useFetchTraitSalesApiCall = (
  data: traitSalesApi.FetchTraitSalesReqData
) => {
  const memoizedData = useMemo(() => {
    if (
      data.walletAddress &&
      data.collectionAddress &&
      data.traitCollectionAddress
    ) {
      return data;
    }
  }, [data.walletAddress, data.collectionAddress, data.traitCollectionAddress]);

  return useApiCall<TraitSale[], traitSalesApi.FetchTraitSalesReqData>(
    traitSalesApi.fetchTraitSales,
    memoizedData
  );
};

export default useFetchTraitSalesApiCall;

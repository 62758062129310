import React from 'react';
import { Metadata } from '../types';
import DynamicNft from '../types/dynamicNft';

export type DynamicNftContextValue = {
  dynamicNft: DynamicNft;
  dynamicNftMetadata: Metadata;
  isLoading: boolean;
  fetchMetadata: () => void;
  fetchDynamicNft: () => void;
};

// @ts-ignore
const DynamicNftContext = React.createContext<DynamicNftContextValue>();

DynamicNftContext.displayName = 'DynamicNftContext';

export default DynamicNftContext;

import EtherAmount from './EtherAmount';
import HoneyIcon from './HoneyIcon';
import SimplePopup from './SimplePopup';
import TinyHeading from './TinyHeading';
import useFancyBearLevelDetailsUtil from '../hooks/useFancyBearLevelDetailsUtil';
import useTranslate from '../hooks/useTranslate';
import WalletAsset from './WalletAsset';
import { BigNumber } from 'ethers';
import { calculateHoneyConsumptionRequirement } from '../utils/levelUtils';
import { formatEtherBalance } from '../utils/numberUtils';
import { useMemo } from 'react';
import {
  Box,
  Divider,
  ModalProps,
  Progress,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';

type FancyBearLevelDetailsPopupProps = Omit<ModalProps, 'children'> & {
  consumedHoney: BigNumber;
};

const FancyBearLevelDetailsPopup = ({
  consumedHoney,
  onClose,
  ...rest
}: FancyBearLevelDetailsPopupProps) => {
  const translate = useTranslate();

  const levelDetails = useFancyBearLevelDetailsUtil(consumedHoney);

  const nextLevelsInfoList = useMemo(() => {
    const items = [];

    if (levelDetails) {
      for (let i = 1; i < 7; i++) {
        const level = levelDetails?.nextLevel + i;
        const honeyRequired = calculateHoneyConsumptionRequirement(level);
        items.push({ level, honeyRequired });
      }
    }

    return items;
  }, [levelDetails]);

  return (
    <SimplePopup
      title={translate('fancyBearLevel:title', {
        level: levelDetails?.currentLevel,
      })}
      size="sm"
      onClose={onClose}
      {...rest}
    >
      <VStack pt="3" w="full" spacing="3">
        <Box w="full">
          <WalletAsset
            icon={HoneyIcon}
            title={translate('fancyBearLevel:honeyEaten')}
            value={consumedHoney && formatEtherBalance(consumedHoney)}
          />

          <Box mt="3">
            <WalletAsset
              icon={HoneyIcon}
              title={translate('common:nextLevel')}
              borderBottomRadius="none"
              value={
                levelDetails &&
                formatEtherBalance(levelDetails.nextLevelHoneyRequirement)
              }
            />

            <Progress
              h="2"
              w="100%"
              colorScheme="secondary"
              borderBottomRadius="md"
              value={levelDetails?.nextLevelProgress || 0}
              borderRadius="0"
            />
          </Box>
        </Box>

        <Divider borderBottomColor="dark.600" />

        <SimpleGrid columns={2} gap="3" w="full">
          {nextLevelsInfoList.map(({ level, honeyRequired }) => (
            <Box key={level}>
              <TinyHeading mb="0.5">
                {translate('common:level')} {level}{' '}
              </TinyHeading>
              <Text color="secondary.500">
                <EtherAmount amount={honeyRequired} />
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </SimplePopup>
  );
};

export default FancyBearLevelDetailsPopup;

import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useHoneyBalance = (
  walletAddress?: string
): [honeyBalance: BigNumber | undefined, readHoneyBalance: () => void] => {
  const [{ data }, readHoneyBalance] = useContractRead(
    {
      addressOrName: config.contracts.honeyTokenContract.address,
      contractInterface: config.contracts.honeyTokenContract.interface,
    },
    'balanceOf',
    {
      args: walletAddress,
    }
  );

  return [data as BigNumber | undefined, readHoneyBalance];
};

export default useHoneyBalance;

import config from '../../constants/baseConfig';
import useTraitCollectionContext from '../useTraitCollectionContext';
import useAnyErc20AllowanceContractCall from './useAnyErc20AllowanceContractCall';

const useErc20TraitsSaleAllowanceContractCall = (walletAddress?: string) => {
  const { traitCollection } = useTraitCollectionContext();
  return useAnyErc20AllowanceContractCall(
    config.contracts.erc20Contract.address,
    traitCollection.saleContractAddress,
    walletAddress
  );
};

export default useErc20TraitsSaleAllowanceContractCall;

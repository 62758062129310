import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { formatEtherBalance, getRandomInt } from '../utils/numberUtils';
import { useCallback, useMemo, useState } from 'react';

import DynamicNftImage from '../components/DynamicNftImage';
import DynamicNftSelect from '../components/DynamicNftSelect';
import TinyHeading from '../components/TinyHeading';
import config from '../constants/baseConfig';
import { constants } from 'ethers';
import { getCurrencySymbol } from '../utils/currencyUtils';
import useAllOwnedDynamicNftsTokenIdsUtil from '../hooks/useAllOwnedDynamicNftsTokenIdsUtil';
import useApproveErc20ContractCall from '../hooks/bc/useApproveErc20ContractCall';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useErc20TraitsSaleAllowanceContractCall from '../hooks/bc/useErc20TraitsSaleAllowanceContractCall';
import useStoreContext from '../hooks/useStoreContext';
import useStoreSelectedTraitsTotalPriceUtil from '../hooks/useStoreSelectedTraitsTotalPriceUtil';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import useTranslate from '../hooks/useTranslate';
import useV2TraitsPurchaseWithErc20ContractCall from '../hooks/bc/useV2TraitsPurchaseWithErc20ContractCall';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

type V2TraitsErc20CheckoutContainerProps = {
  onSuccess: () => void;
};

const V2TraitsErc20CheckoutContainer = ({
  onSuccess,
}: V2TraitsErc20CheckoutContainerProps) => {
  const translate = useTranslate();
  const { dynamicNft } = useDynamicNftContext();
  const [walletAddress] = useConnectedAccount();
  const { traitSalesByTraitId } = useStoreContext();
  const { traitCollection } = useTraitCollectionContext();
  const { ethBalance } = useWalletAssetsContext();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const { changedTraitIds } = useDressingRoomContext();
  const [allowance, readAllowance] =
    useErc20TraitsSaleAllowanceContractCall(walletAddress);

  const ownedTokenIds = useAllOwnedDynamicNftsTokenIdsUtil();

  const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>(
    dynamicNft.tokenId
  );

  const totalPrice = useStoreSelectedTraitsTotalPriceUtil(
    changedTraitIds || []
  );

  const traitTokenIds = useMemo(
    () =>
      changedTraitIds?.map(traitId => traitSalesByTraitId[traitId].tokenId) ||
      [],
    [changedTraitIds, traitSalesByTraitId]
  );

  const isFree = totalPrice.eq('0');
  const isTooExpensive = ethBalance ? ethBalance.gt(ethBalance) : true;
  const isCheckingAllowance = allowance === undefined;
  const areEnoughAmountApproved = allowance?.gte(totalPrice) || false;

  const [purchase, isPurchasing] = useV2TraitsPurchaseWithErc20ContractCall(
    config.contracts.erc20Contract.address,
    selectedTokenId,
    traitTokenIds,
    onSuccess
  );

  const [approve, isApproving] = useApproveErc20ContractCall(
    traitCollection.saleContractAddress,
    constants.MaxUint256,
    readAllowance
  );

  const handleDynamicNftChange = useCallback(
    (tokenId?: number) => {
      setSelectedTokenId(tokenId);
    },
    [setSelectedTokenId]
  );

  const handleDrawDynamicNft = useCallback(
    () =>
      setSelectedTokenId(
        dynamicNftTokenIds[getRandomInt(0, dynamicNftTokenIds.length)]
      ),
    [dynamicNftTokenIds]
  );

  return (
    <VStack spacing="6" w="full">
      <Box position="relative" zIndex="dropdown" w="full">
        <TinyHeading mb="2">
          {translate('purchase:dynamicNft:title', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
        </TinyHeading>

        <HStack borderRadius="md" spacing="3" bg="dark.800" px="3" py="3">
          <DynamicNftImage
            tokenId={selectedTokenId}
            objectFit="cover"
            transition="all 0.15s"
            size="256"
            w="10"
            flex="none"
          />

          <Box w="full">
            <DynamicNftSelect
              tokenId={selectedTokenId}
              ownedTokenIds={ownedTokenIds}
              allTokenIds={dynamicNftTokenIds}
              isClearable
              onChange={handleDynamicNftChange}
            />
          </Box>

          <Button
            size="sm"
            colorScheme="dark"
            flex="none"
            onClick={handleDrawDynamicNft}
          >
            {translate('purchase:dynamicNft:draw')}
          </Button>
        </HStack>
      </Box>

      <Box
        position="relative"
        mt={isFree ? '4' : '6'}
        borderRadius="md"
        overflow="hidden"
        w="full"
      >
        {isCheckingAllowance ? (
          <Button w="full" colorScheme="dark" isLoading />
        ) : (
          <>
            {!areEnoughAmountApproved ? (
              <Button
                w="full"
                onClick={() => approve()}
                isLoading={isApproving}
              >
                {translate('purchase:approve')}
              </Button>
            ) : (
              <Button
                w="full"
                colorScheme="primary"
                onClick={() => purchase()}
                isDisabled={!areEnoughAmountApproved || isTooExpensive}
                isLoading={isPurchasing}
              >
                {translate(
                  isFree ? 'purchase:submit:free' : 'purchase:submit',
                  {
                    price: formatEtherBalance(totalPrice),
                    currency: getCurrencySymbol(config.erc20.tokenName),
                  }
                )}
              </Button>
            )}
          </>
        )}
      </Box>
    </VStack>
  );
};

export default V2TraitsErc20CheckoutContainer;

import { BigNumber } from 'ethers';
import { HoneyCheckoutFormValues } from '../types/purchaseValues';
import useBigNumbersSumUtil from './useBigNumbersSumUtil';

const usePurchaseFormValuesSumUtil = (formValues: HoneyCheckoutFormValues) =>
  useBigNumbersSumUtil(
    Object.values(formValues).map(value => value?.amount || BigNumber.from('0'))
  );

export default usePurchaseFormValuesSumUtil;

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, BoxProps, Button, Text, useInterval } from '@chakra-ui/react';

import { useState } from 'react';
import useTranslate from '../hooks/useTranslate';

type RefreshCountdownProps = BoxProps & {
  seconds?: number;
};

const RefreshCountdown = ({ seconds = 20, ...rest }: RefreshCountdownProps) => {
  const translate = useTranslate();
  const [count, setCount] = useState<number>(seconds);
  const [isCounting, setIsCounting] = useState<boolean>(true);

  useInterval(
    () => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        setIsCounting(false);
      }
    },
    isCounting ? 1000 : null
  );

  return (
    <Box w="full" {...rest}>
      <Box bg="dark.700" color="bright.50" px="6" py="4" borderTopRadius="lg">
        <Text fontSize="sm" maxW="18em" textAlign="center" mx="auto">
          {translate('refreshCountdown:message')}
        </Text>
      </Box>

      <Button
        onClick={() => {
          window.location.reload();
        }}
        isDisabled={isCounting}
        w="full"
        colorScheme="primary"
        borderTopRadius="none"
      >
        {isCounting ? count : translate('common:refresh')}
      </Button>
    </Box>
  );
};

export default RefreshCountdown;

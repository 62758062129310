import React from 'react';
import { TraitSale } from '../types';
import { TraitWithQuantity } from '../types/trait';

export type WardrobeContextValue = {
  ownedTraits: TraitWithQuantity[];
  ownedTraitsById: Record<string, TraitSale>;
  fetchOwnedTraits: () => void;
  isLoading: boolean;
};

const WardrobeContext =
  // @ts-ignore
  React.createContext<WardrobeContextValue>();

WardrobeContext.displayName = 'WardrobeContext';

export default WardrobeContext;

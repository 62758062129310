import { HStack, Icon, IconButton } from '@chakra-ui/react';
import { useCallback } from 'react';
import GalleryModeIcon from '../components/GalleryModeIcon';
import GridModeIcon from '../components/GridModeIcon';
import { DressingRoomMode } from '../contexts/DressingRoomContext';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useTranslate from '../hooks/useTranslate';

const MODE_ICON = {
  [DressingRoomMode.Gallery]: GalleryModeIcon,
  [DressingRoomMode.Grid]: GridModeIcon,
};

const ModeSelectorContainer = () => {
  const translate = useTranslate();
  const { currentMode, changeMode } = useDressingRoomContext();

  const getModeButtonProps = useCallback(
    (mode: DressingRoomMode) => ({
      'aria-label': translate(`common:${mode}`),
      colorScheme: 'dark',
      size: 'sm',
      color: currentMode === mode ? 'primary.500' : 'bright.50',
      bg: currentMode === mode ? 'dark.700' : 'transparent',
      _hover: {
        bg: 'dark.600',
      },
      icon: <Icon as={MODE_ICON[mode]} w="5" h="5" />,
      onClick: () => changeMode(mode),
    }),
    [currentMode, changeMode, translate]
  );

  return (
    <HStack spacing="2">
      <IconButton {...getModeButtonProps(DressingRoomMode.Gallery)} />
      <IconButton {...getModeButtonProps(DressingRoomMode.Grid)} />
    </HStack>
  );
};

export default ModeSelectorContainer;

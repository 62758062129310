import {
  AsyncProps,
  AsyncSelect,
  ChakraStylesConfig,
  GroupBase,
  Props as SelectProps,
  Select,
} from 'chakra-react-select';

export const getDefaultOptionStyles = (provided: any, option: any) => ({
  ...provided,
  color: 'bright.500',
  borderRadius: '0',
  border: '0',
  fontSize: 'md',
  px: '3',
  ...(option.isDisabled
    ? {
        background: 'dark.500',
      }
    : {
        background: 'dark.800',
        ':hover': {
          background: 'dark.900',
          color: 'primary.500',
        },
        ':focus': {
          background: 'dark.500',
        },
        ':active': {
          background: 'dark.500',
        },
      }),
});

const getStylesConfig = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(): ChakraStylesConfig<Option, IsMulti, Group> => ({
  control: provided => ({
    ...provided,
    ':hover': {
      borderColor: 'primary.500',
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    bg: 'transparent',
    px: '2',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    borderLeftWidth: '0',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'dark.100',
    opacity: 0.5,
    fontWeight: 'normal',
  }),
  clearIndicator: provided => ({
    ...provided,
    bg: 'transparent',
    mx: '0',
  }),
  downChevron: provided => ({
    ...provided,
    w: '7',
    hueRotate: 'auto',
  }),
  crossIcon: provided => ({
    ...provided,
    w: '7',
    h: 'auto',
  }),
  menuList: provided => ({
    ...provided,
    bg: 'dark.800',
    borderRadius: 'md',
    border: 'none',
    py: '2',
    w: 'auto',
    minW: '0',
    zIndex: 1000000,
  }),
  container: provided => ({
    ...provided,
    zIndex: 'dropdown',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: 'full',
    px: '2',
    mt: '1',
    mb: '1',
    mr: '1',
    bg: 'dark.500',
    color: 'bright.50',
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: 'sm',
    color: 'bright.500',
    opacity: 0.6,
  }),
  option: getDefaultOptionStyles,
  groupHeading: provided => ({
    ...provided,
    bg: 'transparent',
    fontSize: 'xs',
    py: '1.5',
    px: '3',
    color: 'bright.500',
    opacity: 0.6,
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  }),
});

export type SmartSelectProps<
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = SelectProps<Option, IsMulti, Group>;

const SmartSelect = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  chakraStyles = {},
  ...rest
}: SmartSelectProps<Option, IsMulti, Group>) => (
  <Select
    focusBorderColor="primary.500"
    chakraStyles={{
      ...getStylesConfig<Option, IsMulti, Group>(),
      ...chakraStyles,
    }}
    {...rest}
  />
);

export type AsyncSmartSelectProps<
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = AsyncProps<Option, IsMulti, Group>;

export const AsyncSmartSelect = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  chakraStyles = {},
  ...rest
}: AsyncSmartSelectProps<Option, IsMulti, Group>) => (
  <AsyncSelect
    focusBorderColor="primary.500"
    chakraStyles={{
      ...getStylesConfig<Option, IsMulti, Group>(),
      ...chakraStyles,
    }}
    {...rest}
  />
);

export default SmartSelect;

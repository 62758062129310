import { Container, HStack, VStack } from '@chakra-ui/react';
import AnimatedPage from '../components/AnimatedPage';
import Panel from '../components/Panel';
import DynamicNftSelectorContainer from '../containers/DynamicNftSelectorContainer';
import ModeSelectorContainer from '../containers/ModeSelectorContainer';
import StoreBagContainer from '../containers/StoreBagContainer';
import StoreDressingRoomContainer from '../containers/StoreDressingRoomContainer';
import StoreErrorsContainer from '../containers/StoreErrorsContainer';
import DressingRoomContext, {
  DressingRoomMode,
  DressingRoomScope,
} from '../contexts/DressingRoomContext';
import StoreContext from '../contexts/StoreContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import usePageTracking from '../hooks/usePageTracking';
import DressingRoomProvider from '../providers/DressingRoomProvider';
import StoreProvider from '../providers/StoreProvider';

const StorePage = () => {
  const { dynamicNft } = useDynamicNftContext();

  usePageTracking();

  return (
    <StoreProvider>
      <StoreContext.Consumer>
        {({ traitSales }) => (
          <DressingRoomProvider
            scope={DressingRoomScope.Store}
            originalTraitIds={dynamicNft.traitIds || []}
            allTraitIds={traitSales.map(sale => sale.traitId)}
          >
            <AnimatedPage>
              <DressingRoomContext.Consumer>
                {({ currentMode }) => (
                  <Container
                    maxW={
                      currentMode === DressingRoomMode.Grid
                        ? 'container.md'
                        : 'container.sm'
                    }
                    pt="12"
                    pb="16"
                  >
                    <Panel>
                      <VStack spacing="3">
                        <HStack
                          justify="space-between"
                          w="full"
                          px="1.5"
                          pt="1.5"
                          pb={
                            currentMode === DressingRoomMode.Grid ? '2' : '1.5'
                          }
                          spacing={{
                            base: '0',
                            sm: '8',
                          }}
                        >
                          <DynamicNftSelectorContainer path="store" />
                          <ModeSelectorContainer />
                        </HStack>

                        <StoreDressingRoomContainer />
                      </VStack>
                    </Panel>

                    <StoreBagContainer />
                  </Container>
                )}
              </DressingRoomContext.Consumer>
            </AnimatedPage>
          </DressingRoomProvider>
        )}
      </StoreContext.Consumer>

      <StoreErrorsContainer />
    </StoreProvider>
  );
};

export default StorePage;

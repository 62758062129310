import { Button, VStack } from '@chakra-ui/react';
import ErrorPopup, { ErrorPopupProps } from '../components/ErrorPopup';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import useTranslate from '../hooks/useTranslate';
import { getCurrencySymbol } from '../utils/currencyUtils';

type UnsupportedCurrencyPopupProps = Omit<
  ErrorPopupProps,
  'title' | 'description' | 'children'
>;

const UnsupportedCurrencyPopup = ({
  onClose,
  ...rest
}: UnsupportedCurrencyPopupProps) => {
  const translate = useTranslate();
  const { changeCurrency } = useCurrencyContext();
  const { traitCollection } = useTraitCollectionContext();
  const supportedCurrenciesSymbols = traitCollection.currencies.map(currency =>
    getCurrencySymbol(currency)
  );

  return (
    <ErrorPopup
      title={translate('unsupportedCurrency:title')}
      description={translate('unsupportedCurrency:description', {
        collectionName: traitCollection.name,
        currencies: supportedCurrenciesSymbols.join(' and '),
      })}
      {...rest}
    >
      <VStack>
        {traitCollection.currencies.map(currency => (
          <Button
            key={currency}
            w="full"
            colorScheme="dark"
            onClick={() => {
              changeCurrency(currency);
              onClose?.();
            }}
          >
            {translate('unsupportedCurrency:switch', {
              currency: getCurrencySymbol(currency),
            })}
          </Button>
        ))}

        <Button
          w="full"
          variant="outline"
          colorScheme="dark"
          onClick={() => {
            onClose?.();
          }}
        >
          {translate('common:cancel')}
        </Button>
      </VStack>
    </ErrorPopup>
  );
};

export default UnsupportedCurrencyPopup;

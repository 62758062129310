import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import config from '../constants/baseConfig';
import CurrencyContext from '../contexts/CurrencyContext';
import { Currency } from '../types';

const CURRENCY_STORAGE_KEY = 'lastCurrency';

type CurrencyProviderProps = {};

const CurrencyProvider: FunctionComponent<CurrencyProviderProps> = ({
  children,
}) => {
  const [currency, setCurrency] = useState<Currency>(
    (localStorage.getItem(CURRENCY_STORAGE_KEY) as Currency) ||
      config.currencies.defaultCurrency
  );

  const changeCurrency = useCallback((currency: Currency) => {
    localStorage.setItem(CURRENCY_STORAGE_KEY, currency);
    setCurrency(currency);
  }, []);

  const contextValue = useMemo(
    () => ({
      currency,
      changeCurrency,
    }),
    [currency, changeCurrency]
  );

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;

import {
  Box,
  Center,
  Icon,
  IconButton,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';
import EmptyState from '../components/EmptyState';
import PreviewPopup from '../components/PreviewPopup';
import TraitPreviewCard from '../components/TraitPreviewCard';
import { DressingRoomMode } from '../contexts/DressingRoomContext';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { addOrReplaceTrait } from '../utils/traitsUtils';
import CategorySelectorContainer from './CategorySelectorContainer';
import TraitCollectionSelectorContainer from './TraitCollectionSelector';
import TraitsGalleryModeSelectorContainer from './TraitsGalleryModeSelectorContainer';
import TraitsGridModeSelectorContainer from './TraitsGridModeSelectorContainer';
import TraitsPreviewContainer from './TraitsPreviewContainer';

const WardrobeDressingRoomContainer = () => {
  const { traitsById } = useDynamicNftCollectionContext();
  const { currentMode, availableCategoryIds, currentTraitIds } =
    useDressingRoomContext();
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useBoolean();

  const renderPreview = useCallback(
    (
      traitId: string,
      isSelected: boolean,
      isOriginal: boolean,
      isVisible: boolean
    ) => {
      const trait = traitsById[traitId];
      const traitIdsToPreview = addOrReplaceTrait(
        currentTraitIds,
        traitId,
        traitsById
      );
      const hasZoom = currentMode === DressingRoomMode.Grid;

      return (
        <TraitPreviewCard
          traitId={traitId}
          tokenId={trait.tokenId}
          traitIds={traitIdsToPreview}
          isSelected={isSelected}
          isActive={isOriginal}
          isVisible={isVisible}
          imageSize="256"
        >
          {hasZoom && (
            <Box
              position="absolute"
              top="0"
              zIndex="10"
              right="0"
              transition="opacity 0.15s"
              opacity={isSelected ? '1' : '0'}
            >
              <IconButton
                bg="dark.700"
                colorScheme="dark"
                borderRadius="md"
                size="xs"
                aria-label=""
                _hover={{
                  color: 'primary.500',
                }}
                onClick={event => {
                  if (isSelected) {
                    event.stopPropagation();
                    setIsPreviewPopupOpen.on();
                  }
                }}
              >
                <Icon as={FaSearch} w="3.5" h="3.5" />
              </IconButton>
            </Box>
          )}

          <Text
            fontSize="sm"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="semibold"
          >
            #{trait.tokenId}
          </Text>
        </TraitPreviewCard>
      );
    },
    [currentTraitIds, traitsById, currentMode, setIsPreviewPopupOpen]
  );

  return (
    <>
      {currentMode === DressingRoomMode.Gallery ? (
        <VStack spacing="3" w="full">
          <TraitsPreviewContainer />

          <TraitCollectionSelectorContainer />

          {availableCategoryIds?.length ? (
            <>
              <CategorySelectorContainer />
              <TraitsGalleryModeSelectorContainer renderItem={renderPreview} />
            </>
          ) : (
            <Center h="32">
              <EmptyState opacity="0.4" />
            </Center>
          )}
        </VStack>
      ) : (
        <VStack spacing="3" w="full">
          <TraitCollectionSelectorContainer />

          {availableCategoryIds?.length ? (
            <>
              <CategorySelectorContainer />
              <TraitsGridModeSelectorContainer renderItem={renderPreview} />
            </>
          ) : (
            <Center h="32">
              <EmptyState opacity="0.4" />
            </Center>
          )}
        </VStack>
      )}

      {isPreviewPopupOpen && (
        <PreviewPopup
          isOpen
          traitIds={currentTraitIds}
          onClose={setIsPreviewPopupOpen.off}
        />
      )}
    </>
  );
};

export default WardrobeDressingRoomContainer;

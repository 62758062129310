import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  AccountGuard,
  Background,
  ComposedConnectedProviders,
  ComposedGlobalProviders,
  config,
  ConnectedPage,
  ConnectPage,
  Layout,
} from '@traitswapjs/shared';
import React from 'react';
import ReactGA from 'react-ga4';
import theme from './constants/theme';
import translations from './constants/translations';

if (config.sentry.sentryDsn) {
  Sentry.init({
    dsn: config.sentry.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: config.environment,
    maxValueLength: 1000,
  });
}

if (config.ga.gaId) {
  ReactGA.initialize(config.ga.gaId);
}

const App: React.FC = () => (
  <ComposedGlobalProviders theme={theme} translations={translations}>
    <Background />

    <AccountGuard
      yeyElement={
        <ComposedConnectedProviders>
          <ConnectedPage />
        </ComposedConnectedProviders>
      }
      neyElement={
        <Layout>
          <ConnectPage />
        </Layout>
      }
    />
  </ComposedGlobalProviders>
);

export default App;

import { useEffect, useMemo, useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useDynamicNftsInWalletContractCall = (
  walletAddress?: string
): [tokenIds: number[] | undefined, readNftsInWallet: () => void] => {
  const [nftsInWallet, setNftsInWallet] = useState<number[]>();

  const [{ data: nftsInWalletData }, readNftsInWallet] = useContractRead(
    {
      addressOrName: config.contracts.dynamicNftContract.address,
      contractInterface: config.contracts.dynamicNftContract.interface,
    },
    'tokensInWallet',
    {
      args: walletAddress,
    }
  );

  useEffect(() => {
    if (nftsInWalletData) {
      setNftsInWallet(nftsInWalletData?.map(bn => bn.toNumber() as number));
    }
  }, [nftsInWalletData]);

  return useMemo(
    () => [nftsInWallet, readNftsInWallet],
    [nftsInWallet, readNftsInWallet]
  );
};

export default useDynamicNftsInWalletContractCall;

import { BigNumber } from 'ethers';

const useEtherAmountsPercentageUtil = (value: BigNumber, total: BigNumber) => {
  if (total.isZero()) {
    return 100;
  }

  return value.div(total).mul(100);
};

export default useEtherAmountsPercentageUtil;

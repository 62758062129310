import { chakraComponents, GroupBase } from 'chakra-react-select';
import { useMemo } from 'react';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import DynamicNftImage from './DynamicNftImage';
import {
  AsyncSmartSelect,
  AsyncSmartSelectProps,
  getDefaultOptionStyles,
} from './SmartSelect';

type DynamicNftSelectOption = {
  label: string;
  value?: number;
  options?: DynamicNftSelectOption[];
};

type DynamicNftSelectProps = Omit<
  AsyncSmartSelectProps<
    DynamicNftSelectOption,
    false,
    GroupBase<DynamicNftSelectOption>
  >,
  | 'onChange'
  | 'value'
  | 'defaultOptions'
  | 'loadOptions'
  | 'components'
  | 'blurInputOnSelect'
> & {
  tokenId?: number;
  ownedTokenIds?: number[];
  allTokenIds?: number[];
  firstTokenId?: number;
  lastTokenId?: number;
  optionPrefix?: string;
  onChange?: (tokenId?: number) => void;
};

const formatAndLimitOptions = (
  options: (number | string)[],
  optionPrefix: string
) =>
  options.slice(0, 6).map(tokenId => ({
    label: `${optionPrefix}${tokenId}`,
    value: Number(tokenId),
  }));

const generateOptions = (
  inputValue: string = '',
  allTokenIds: number[],
  optionPrefix: string
): DynamicNftSelectOption[] => {
  if (!inputValue) {
    return formatAndLimitOptions(allTokenIds, optionPrefix);
  }

  const filteredTokens = allTokenIds.filter(
    tokenId =>
      tokenId.toString().startsWith(inputValue) ||
      tokenId.toString() === inputValue
  );

  return formatAndLimitOptions(
    filteredTokens.length ? filteredTokens : [],
    optionPrefix
  );
};

const DynamicNftSelect = ({
  tokenId,
  ownedTokenIds = [],
  allTokenIds = [],
  firstTokenId = 0,
  lastTokenId = config.fancyBears.fancyBearsTotalSupply,
  optionPrefix = '#',
  chakraStyles = {},
  onChange,
  ...rest
}: DynamicNftSelectProps) => {
  const translate = useTranslate();

  const defaultOptions = useMemo(() => {
    const ownedTokensOptions = ownedTokenIds
      .map(tokenId => ({
        label: `${optionPrefix}${tokenId}`,
        value: tokenId,
      }))
      .sort((a, b) => a.value - b.value);

    let otherTokensOptions = generateOptions(
      undefined,
      allTokenIds,
      optionPrefix
    ).filter(option => option.value !== tokenId);

    if (ownedTokensOptions.length + otherTokensOptions.length > 8) {
      const diff = 8 - ownedTokensOptions.length;
      otherTokensOptions = otherTokensOptions.slice(0, diff);
    }

    return [
      {
        label: translate('v2Select:owned'),
        options: ownedTokensOptions,
      },
      {
        label: ownedTokenIds.length
          ? translate('v2Select:other')
          : translate('v2Select:community'),
        options: otherTokensOptions,
      },
    ];
  }, [ownedTokenIds, allTokenIds, optionPrefix, tokenId, translate]);

  return (
    <AsyncSmartSelect<
      DynamicNftSelectOption,
      false,
      GroupBase<DynamicNftSelectOption>
    >
      value={
        tokenId !== undefined
          ? { value: tokenId, label: `#${tokenId}` }
          : undefined
      }
      placeholder={translate('v2Select:placeholder')}
      components={{
        Option: ({ children, ...props }) => (
          <chakraComponents.Option {...props}>
            <DynamicNftImage
              tokenId={props.data.value!}
              size="256"
              w="8"
              h="8"
              flex="none"
              mr="3"
              borderRadius="full"
            />

            {children}
          </chakraComponents.Option>
        ),
        Input: ({ children, type, ...props }) => (
          <chakraComponents.Input
            type="number"
            min={firstTokenId}
            max={lastTokenId}
            {...props}
          />
        ),
      }}
      chakraStyles={{
        option: (...args) => ({
          ...getDefaultOptionStyles(...args),
          px: '3',
          py: '1.5',
        }),
        ...chakraStyles,
      }}
      defaultOptions={defaultOptions}
      loadOptions={(inputValue: string) =>
        Promise.resolve(generateOptions(inputValue, allTokenIds, optionPrefix))
      }
      onChange={option => onChange && onChange(option?.value)}
      blurInputOnSelect
      {...rest}
    />
  );
};

export default DynamicNftSelect;

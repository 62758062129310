import { Box, Button, HStack, ModalProps, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSignMessage } from 'wagmi';
import * as traitsApi from '../api/termsApi';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';
import SimplePopup from './SimplePopup';

type TermsPopupProps = Omit<ModalProps, 'children'> & {
  walletAddress: string;
  tokenIds: number[];
  isSignatureRequired?: boolean;
  onSuccess: () => void;
  onError?: () => void;
};

const TermsPopup = ({
  walletAddress,
  tokenIds,
  isSignatureRequired = true,
  onSuccess,
  onError,
  onClose,
  ...rest
}: TermsPopupProps) => {
  const translate = useTranslate();
  const contentRef = useRef<HTMLDivElement>(null);
  const { dynamicNftCollection } = useDynamicNftCollectionContext();
  const [isRead, setIsRead] = useState(true);
  const [termsHtml, setTermsHtml] = useState('');
  const [termsText, setTermsText] = useState('');
  const snackbar = useSnackbar();

  const [, sign] = useSignMessage({
    message: termsText,
  });

  const handleScroll = useCallback(() => {
    if (contentRef.current && !isRead) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsRead(true);
      }
    }
  }, [isRead]);

  const handleAgree = useCallback(async () => {
    try {
      const collectionAddress = dynamicNftCollection.contractAddress;

      if (isSignatureRequired) {
        const { data: signature } = await sign();
        if (signature) {
          await traitsApi.acceptTerms({
            walletAddress,
            signature,
            tokenIds,
            collectionAddress,
          });

          onSuccess();
        }
      } else {
        await traitsApi.acceptTerms({
          walletAddress,
          tokenIds,
          collectionAddress,
        });
        onSuccess();
      }
    } catch (error: any) {
      snackbar('error', error?.message || translate('error:default'));
      onError?.();
    }
  }, [
    tokenIds,
    walletAddress,
    dynamicNftCollection,
    snackbar,
    translate,
    sign,
    onSuccess,
    onError,
    isSignatureRequired,
  ]);

  useEffect(() => {
    const fetchTermsHtml = async () => {
      const res = await axios.get<string>(
        `${config.urls.publicUrl}/terms.html`
      );
      setTermsHtml(res.data!);
    };

    const fetchTermsText = async () => {
      const res = await axios.get<string>(`${config.urls.publicUrl}/terms.txt`);
      setTermsText(res.data!);
    };

    fetchTermsHtml();
    fetchTermsText();
  }, []);

  return (
    <SimplePopup
      size="lg"
      title={translate('terms:title')}
      onClose={onClose}
      {...rest}
    >
      <Box
        mt="5"
        px="3"
        py="6"
        bg="dark.700"
        borderRadius="lg"
        maxH="400px"
        fontSize="xs"
        overflowY="scroll"
        ref={contentRef}
        onScroll={handleScroll}
      >
        <Text
          opacity="0.8"
          dangerouslySetInnerHTML={{ __html: termsHtml }}
        ></Text>
      </Box>

      <HStack w="full" spacing="4" mt="6">
        <Button flex="1" colorScheme="dark" onClick={onClose}>
          {translate('common:cancel')}
        </Button>

        <Button flex="1" isDisabled={!isRead} onClick={handleAgree}>
          {translate('common:agree')}
        </Button>
      </HStack>
    </SimplePopup>
  );
};

export default TermsPopup;

import { GroupBase } from 'chakra-react-select';
import { Currency } from '../types';
import SmartSelect, {
  getDefaultOptionStyles,
  SmartSelectProps,
} from './SmartSelect';

export type CurrencySelectOption = {
  label: string;
  value: Currency;
  isDisabled?: boolean;
};

type CurrencySelectProps = Omit<
  SmartSelectProps<
    CurrencySelectOption,
    false,
    GroupBase<CurrencySelectOption>
  >,
  'value' | 'options' | 'onChange'
> & {
  currency: Currency;
  options: CurrencySelectOption[];
  onChange: (currency: Currency) => void;
};

const CurrencySelect = ({
  currency,
  options,
  onChange,
  ...rest
}: CurrencySelectProps) => (
  <SmartSelect
    chakraStyles={{
      control: provided => ({
        ...provided,
        minH: '8',
        fontWeight: 'semibold',
        fontSize: 'sm',
      }),
      valueContainer: provided => ({
        ...provided,
        px: '2',
      }),
      option: (...args) => ({
        ...getDefaultOptionStyles(...args),
        px: '3',
        py: '1.5',
        fontSize: 'sm',
      }),
      menuList: provided => ({
        ...provided,
        bg: 'dark.900',
        borderRadius: 'md',
        border: 'none',
        py: '0',
        w: 'auto',
        minW: '0',
      }),
    }}
    value={options.find(option => option.value === currency)}
    options={options}
    onChange={option => onChange(option?.value!)}
    {...rest}
  />
);

export default CurrencySelect;

import axios from 'axios';
import config from '../constants/baseConfig';

export type ReserveTermsReqData = {
  walletAddress: string;
  tokenIds: number[];
  collectionAddress: string;
};

export const reserveTokens = async ({
  tokenIds,
  walletAddress,
  collectionAddress,
}: ReserveTermsReqData): Promise<void> => {
  await axios.post<{ tokenId: number; quantity: number }[]>(
    '/reserve',
    { tokenIds, address: walletAddress, collectionAddress },
    {
      baseURL: config.urls.traitswapApiUrl,
    }
  );
  return;
};

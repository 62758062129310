import { Button, Heading } from '@chakra-ui/react';
import Panel, { PanelProps } from '../components/Panel';
import config from '../constants/baseConfig';
import ReserveDynamicNftContainer from '../containers/ReserveDynamicNftContainer';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useTranslate from '../hooks/useTranslate';

type ReserveV2PanelProps = PanelProps;

const ReserveV2Panel = (props: ReserveV2PanelProps) => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();

  return (
    <Panel px="6" py="6" textAlign="center" {...props}>
      <Heading size="sm" mb="3" lineHeight="base" maxW="25em" mx="auto">
        {translate(
          walletAddress
            ? 'reserve:title:connected'
            : 'reserve:title:disconnected',
          {
            staticNftName: config.staticNft.staticNftName,
            dynamicNftName: config.dynamicNft.dynamicNftName,
          }
        )}
      </Heading>

      <ReserveDynamicNftContainer
        trigger={
          <Button size="sm" colorScheme="dark">
            {translate('reserve:trigger', {
              dynamicNftName: config.dynamicNft.dynamicNftName,
            })}
          </Button>
        }
      />
    </Panel>
  );
};

export default ReserveV2Panel;

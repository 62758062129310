import React from 'react';

const StoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.39 10.9194H23.3176V7.84518C23.2051 5.9763 22.3276 4.24013 20.8539 2.95735C19.4026 1.69668 17.5014 1 15.5102 1C13.519 1 11.6178 1.69668 10.1666 2.95735C8.6929 4.24013 7.81541 5.96524 7.70292 7.90047V10.9084H2.61803C1.5718 10.9084 0.806828 11.8594 1.04307 12.8547L4.53049 27.7615C4.69924 28.4803 5.35172 29 6.10545 29H23.8913C24.645 29 25.2975 28.4913 25.4663 27.7615L28.9537 12.8547C29.2012 11.8705 28.4249 10.9194 27.39 10.9194ZM9.95286 7.97788C10.0316 6.6951 10.6391 5.51185 11.6628 4.61611C12.6978 3.70932 14.0703 3.21169 15.5102 3.21169C16.9502 3.21169 18.3227 3.70932 19.3576 4.61611C20.3814 5.50079 20.9889 6.6951 21.0564 7.90047V10.9084H9.95286V7.97788ZM25.3875 19.3902C24.735 19.7994 23.8575 19.6224 23.4413 18.981C23.2276 18.6603 22.8226 18.0964 22.4626 18.0521C21.5626 17.9194 19.3576 19.8104 16.8377 22C16.2527 22.5087 15.634 23.0506 14.959 23.6145C13.5753 24.7978 12.2591 25.3949 11.0441 25.3949C10.5604 25.3949 10.0991 25.3065 9.64912 25.1185C6.49919 23.8136 6.0267 18.406 5.9592 17.3444C5.9142 16.5814 6.51044 15.9289 7.27542 15.8847C8.07415 15.8183 8.71539 16.4155 8.76039 17.1785C8.88414 19.1469 9.62662 22.1106 10.7291 22.575C11.2353 22.7852 12.0903 22.3981 13.1028 21.5355C13.7665 20.9716 14.3853 20.4297 14.959 19.9321C18.6152 16.7583 20.6289 15.0111 22.8338 15.3096C23.97 15.4645 24.9375 16.1722 25.7925 17.4771C26.2312 18.1295 26.04 18.981 25.3875 19.3902Z"
      fill="currentColor"
    />
  </svg>
);

export default StoreIcon;

import { chakraComponents, GroupBase } from 'chakra-react-select';
import { useMemo } from 'react';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import { AsyncSmartSelect, AsyncSmartSelectProps } from './SmartSelect';
import StaticNftImage from './StaticNftImage';

type StaticNftMultiselectOption = {
  label: string;
  value: number;
};

type StaticNftMultiselectProps = Omit<
  AsyncSmartSelectProps<
    StaticNftMultiselectOption,
    true,
    GroupBase<StaticNftMultiselectOption>
  >,
  | 'onChange'
  | 'value'
  | 'defaultOptions'
  | 'loadOptions'
  | 'components'
  | 'blurInputOnSelect'
> & {
  tokenIds?: number[];
  firstTokenId?: number;
  lastTokenId?: number;
  optionPrefix?: string;
  onChange?: (tokenIds?: number[]) => void;
};

const generateOptions = (
  inputValue: string = '0',
  firstTokenId: number,
  lastTokenId: number,
  optionPrefix: string
): StaticNftMultiselectOption[] => {
  const options = [];
  const valueAsNumber = parseInt(inputValue, 10);

  if (!Number.isInteger(valueAsNumber)) {
    return [];
  }

  const generateOptionsFrom = Math.max(
    Math.min(valueAsNumber, lastTokenId),
    firstTokenId
  );

  let counter = 1;
  let tokenId = generateOptionsFrom;
  while (tokenId < lastTokenId && counter <= 4) {
    options.push({
      value: tokenId,
      label: `${optionPrefix}${tokenId}`,
    });
    tokenId = parseInt(`${generateOptionsFrom}${counter}`);
    counter++;
  }

  if (options.length <= 1) {
    counter = 1;
    tokenId = generateOptionsFrom + 1;
    while (tokenId < lastTokenId && counter <= 4) {
      options.push({
        value: tokenId,
        label: `${optionPrefix}${tokenId}`,
      });
      tokenId++;
      counter++;
    }
  }

  return options;
};

const StaticNftMultiselect = ({
  tokenIds,
  firstTokenId = 0,
  lastTokenId = config.staticNft.staticNftTotalSupply,
  optionPrefix = '#',
  chakraStyles = {},
  onChange,
  ...rest
}: StaticNftMultiselectProps) => {
  const translate = useTranslate();

  const defaultOptions = useMemo(
    () => generateOptions('0', firstTokenId, lastTokenId, optionPrefix),
    [firstTokenId, lastTokenId, optionPrefix]
  );

  return (
    <AsyncSmartSelect<
      StaticNftMultiselectOption,
      true,
      GroupBase<StaticNftMultiselectOption>
    >
      value={
        tokenIds
          ? tokenIds.map(tokenId => ({
              value: tokenId,
              label: `#${tokenId}`,
            }))
          : undefined
      }
      size="lg"
      isMulti
      isSearchable
      isClearable
      placeholder={translate('bearSelect:placeholder')}
      chakraStyles={{
        valueContainer: provided => ({
          ...provided,
        }),
      }}
      components={{
        Option: ({ children, ...props }) => (
          <chakraComponents.Option {...props}>
            <StaticNftImage
              tokenId={props.data.value!}
              size="256"
              w="8"
              h="8"
              flex="none"
              mr="3"
              borderRadius="full"
            />
            {children}
          </chakraComponents.Option>
        ),
        MultiValueContainer: ({ children, ...props }) => (
          <chakraComponents.MultiValueContainer {...props}>
            <StaticNftImage
              tokenId={props.data.value!}
              size="256"
              w="8"
              h="8"
              flex="none"
              ml="-2"
              mr="2"
              borderRadius="full"
            />
            {children}
          </chakraComponents.MultiValueContainer>
        ),
        Input: ({ children, type, ...props }) => (
          <chakraComponents.Input
            type="number"
            min={firstTokenId}
            max={lastTokenId}
            {...props}
          />
        ),
      }}
      defaultOptions={defaultOptions}
      loadOptions={(inputValue: string) =>
        Promise.resolve(
          generateOptions(inputValue, firstTokenId, lastTokenId, optionPrefix)
        )
      }
      onChange={options => {
        onChange?.(options?.map(option => option.value));
      }}
      blurInputOnSelect
      {...rest}
    />
  );
};

export default StaticNftMultiselect;

import React from 'react';

const GridModeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.50146"
      y="16.2507"
      width="11.25"
      height="11.25"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="2.50146"
      y="2.49927"
      width="11.25"
      height="11.25"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="16.2485"
      y="16.2507"
      width="11.25"
      height="11.25"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="16.2485"
      y="2.49927"
      width="11.25"
      height="11.25"
      rx="2"
      fill="currentColor"
    />
  </svg>
);

export default GridModeIcon;

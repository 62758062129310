import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import CacheContext, { CacheContextValue } from '../contexts/CacheContext';

const CACHE_ID_STORAGE_TOKEN = 'cacheId';

const CacheProvider: FunctionComponent = ({ children }) => {
  const [cacheId] = useState(() =>
    localStorage.getItem(CACHE_ID_STORAGE_TOKEN)
  );

  const restoreCacheId = useCallback(() => {
    localStorage.setItem(
      CACHE_ID_STORAGE_TOKEN,
      ((parseInt(cacheId!) || 0) + 1).toString()
    );
  }, [cacheId]);

  const contextValue = useMemo<CacheContextValue>(
    () => ({ cacheId, restoreCacheId }),
    [cacheId, restoreCacheId]
  );

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
};

export default CacheProvider;

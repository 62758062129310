import {
  AspectRatio,
  Box,
  Button,
  Icon,
  Text,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { hexToRGB } from '../utils/colorUtils';
import EmptyNftCard, { EmptyNftCardProps } from './EmptyNftCard';

type EmptyNftCardWithLinkProps = EmptyNftCardProps & {
  label: string;
  externalUrl: string;
};

const EmptyNftCardWithLink = ({
  label,
  externalUrl,
  ...rest
}: EmptyNftCardWithLinkProps) => {
  const { colors } = useTheme();
  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
  });

  return (
    <EmptyNftCard position="relative" {...rest}>
      <AspectRatio
        position="absolute"
        zIndex={1}
        top="1.5"
        left="1.5"
        right="1.5"
        ratio={1}
      >
        <Box w="full" h="full" textAlign="center" position="relative">
          <Button
            position="absolute"
            colorScheme="dark"
            as="a"
            bottom="0"
            w="full"
            borderTopRadius="0"
            href={externalUrl}
            target="_blank"
            rel="noreferrer"
            size={buttonSize}
            cursor="pointer"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
            bg={hexToRGB(colors['dark'][600], 0.8)}
          >
            {label}
          </Button>
        </Box>
      </AspectRatio>

      <Text fontWeight="600" px="1.5" pt="1.5">
        #???
      </Text>
    </EmptyNftCard>
  );
};

export default EmptyNftCardWithLink;

import config from '../../constants/baseConfig';
import useTraitCollectionContext from '../useTraitCollectionContext';
import useApproveAny721ContractCall from './useApproveAny721ContractCall';

const useApproveTraitsForTraitSwapContractCall = (onSuccess?: () => void) => {
  const { traitCollection } = useTraitCollectionContext();

  return useApproveAny721ContractCall(
    traitCollection.contractAddress,
    config.contracts.traitSwapContract.address,
    onSuccess
  );
};

export default useApproveTraitsForTraitSwapContractCall;

import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import TraitsPreview from '../components/TraitsPreview';

type PreviewPopupProps = Omit<ModalProps, 'children'> & {
  traitIds: string[];
};

const PreviewPopup = ({ traitIds, ...rest }: PreviewPopupProps) => (
  <Modal size="2xl" {...rest}>
    <ModalOverlay />
    <ModalContent p="0">
      <ModalBody px="0" py="0">
        <AspectRatio w="full" ratio={1}>
          <TraitsPreview traitIds={traitIds} imagesSize="512" />
        </AspectRatio>
      </ModalBody>

      <ModalCloseButton bg="dark.900" />
    </ModalContent>
  </Modal>
);

export default PreviewPopup;

import { FunctionComponent, useMemo } from 'react';
import DynamicNftSaleContext, {
  DynamicNftSaleContextValue,
} from '../contexts/DynamicNftSaleContext';
import useDynamicNftSaleHoneyRewardContractCall from '../hooks/bc/useDynamicNftSaleHoneyRewardContractCall';
import useDynamicNftSalePriceContractCall from '../hooks/bc/useDynamicNftSalePriceContractCall';
import useDynamicNftSaleStateContractCall from '../hooks/bc/useDynamicNftSaleStateContractCall';

const DynamicNftSaleProvider: FunctionComponent = ({ children }) => {
  const [isSaleActive, readSaleState] = useDynamicNftSaleStateContractCall();
  const [price, readPrice] = useDynamicNftSalePriceContractCall();
  const [honeyReward, readHoneyReward] =
    useDynamicNftSaleHoneyRewardContractCall();

  const contextValue = useMemo<DynamicNftSaleContextValue>(
    () => ({
      isSaleActive,
      price,
      honeyReward,
      readSaleState,
      readPrice,
      readHoneyReward,
    }),
    [
      isSaleActive,
      price,
      honeyReward,
      readSaleState,
      readPrice,
      readHoneyReward,
    ]
  );

  return (
    <DynamicNftSaleContext.Provider value={contextValue}>
      {children}
    </DynamicNftSaleContext.Provider>
  );
};

export default DynamicNftSaleProvider;

import { Center, HStack, StackProps, Text } from '@chakra-ui/react';

type InfoTileProps = StackProps & {
  index: number;
  text: string;
};

const InfoTile = ({ index, text, ...rest }: InfoTileProps) => (
  <HStack
    p="3"
    bg="dark.800"
    borderRadius="lg"
    maxH="400px"
    fontSize="sm"
    overflowY="auto"
    w="full"
    spacing="3"
    {...rest}
  >
    <Center borderRadius="full" bg="dark.500" h="7" w="7" flex="none">
      <Text fontWeight="semibold" fontSize="xs">
        {index}
      </Text>
    </Center>
    <Text fontSize="sm">{text}</Text>
  </HStack>
);

export default InfoTile;

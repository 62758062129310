import { Badge, BadgeProps, Box } from '@chakra-ui/react';

type FloatingBadgeProps = BadgeProps & {
  text?: string | number;
  offset?: string;
  placement?: 'top-right' | 'bottom-right';
};

const FloatingBadge = ({
  text,
  offset = '50%',
  children,
  placement = 'top-right',
  size = 'md',
  ...rest
}: FloatingBadgeProps) => {
  const [vPlacement, hPlacement] = placement.split('-');

  return (
    <Box position="relative">
      {text && (
        <Badge
          position="absolute"
          top={vPlacement === 'top' ? '0' : undefined}
          bottom={vPlacement === 'bottom' ? '0' : undefined}
          right={hPlacement === 'right' ? '0' : undefined}
          left={hPlacement === 'left' ? '0' : undefined}
          transform={`translate(${offset}, -${offset})`}
          px="1"
          minW="1.5em"
          lineHeight="1.5em"
          fontSize={size === 'sm' ? '0.625rem' : undefined}
          zIndex="2"
          textAlign="center"
          {...rest}
        >
          {text}
        </Badge>
      )}

      {children}
    </Box>
  );
};

export default FloatingBadge;

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DressingRoomContext, {
  DressingRoomContextValue,
  DressingRoomMode,
  DressingRoomScope,
} from '../contexts/DressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';
import { addOrReplaceTrait, removeOrReplaceTrait } from '../utils/traitsUtils';

const DRESSING_ROOM_MODE_STORAGE_KEY = 'mode';

export type DressingRoomProviderProps = {
  scope: DressingRoomScope;
  originalTraitIds: string[];
  allTraitIds?: string[];
};

const DressingRoomProvider = ({
  scope,
  originalTraitIds,
  allTraitIds,
  children,
}: PropsWithChildren<DressingRoomProviderProps>) => {
  const { categories, traitsById, traitCollections } =
    useDynamicNftCollectionContext();
  const { traitCollection } = useTraitCollectionContext();

  const [state, setState] = useState(() => ({
    history: [originalTraitIds],
    pointer: 0,
  }));

  const availableTraitIds = useMemo(
    () =>
      allTraitIds?.filter(traitId => {
        const trait = traitsById[traitId];
        return trait.traitCollectionId === traitCollection.traitCollectionId;
      }),
    [allTraitIds, traitCollection, traitsById]
  );

  const [currentMode, setCurrentMode] = useState(
    (localStorage.getItem(
      `${DRESSING_ROOM_MODE_STORAGE_KEY}_${scope}`
    ) as DressingRoomMode) || DressingRoomMode.Gallery
  );

  const currentTraitIds: string[] = useMemo(
    () => state.history[state.pointer],
    [state]
  );

  const changedTraitIds = useMemo(() => {
    if (currentTraitIds && originalTraitIds) {
      const changedTraitIds = currentTraitIds.reduce(
        (prev: string[], traitId: string) => {
          if (!originalTraitIds.includes(traitId)) {
            return [...prev, traitId];
          }
          return prev;
        },
        []
      );

      return changedTraitIds;
    }

    return [];
  }, [currentTraitIds, originalTraitIds]);

  const availableTraitIdsByCategory = useMemo(() => {
    const result = categories.reduce(
      (prev, category) => ({
        ...prev,
        [category.categoryId]: [],
      }),
      {}
    ) as Record<string, string[]>;

    if (availableTraitIds) {
      availableTraitIds.forEach(traitId => {
        const trait = traitsById[traitId];

        if (result[trait.category]) {
          result[trait.category].push(traitId);
        } else {
          result[trait.category] = [traitId];
        }
      });

      return result;
    }

    return result;
  }, [availableTraitIds, traitsById, categories]);

  const allTraitIdsByTraitCollection = useMemo(() => {
    const result = traitCollections.reduce(
      (prev, collection) => ({
        ...prev,
        [collection.traitCollectionId]: [],
      }),
      {}
    ) as Record<string, string[]>;

    if (allTraitIds) {
      allTraitIds.forEach(traitId => {
        const trait = traitsById[traitId];

        if (result[trait.traitCollectionId]) {
          result[trait.traitCollectionId].push(traitId);
        } else {
          result[trait.traitCollectionId] = [traitId];
        }
      });

      return result;
    }

    return result;
  }, [allTraitIds, traitsById, traitCollections]);

  const availableCategoryIds = useMemo(
    () =>
      categories
        .filter(category => {
          const traitsByCategory =
            availableTraitIdsByCategory?.[category.categoryId];
          return (
            !category.hidden && traitsByCategory && traitsByCategory.length > 0
          );
        })
        .map(({ categoryId }) => categoryId),
    [availableTraitIdsByCategory, categories]
  );

  const [currentCategoryId, setCurrentCategoryId] = useState<string>();

  const changeMode = useCallback((mode: DressingRoomMode) => {
    setCurrentMode(mode);
    localStorage.setItem(`${DRESSING_ROOM_MODE_STORAGE_KEY}_${scope}`, mode);
  }, []);

  const changeCategory = useCallback((categoryId: string) => {
    setCurrentCategoryId(categoryId);
  }, []);

  const undoSwap = useCallback(() => {
    if (state.pointer > 0) {
      setState({ ...state, pointer: state.pointer - 1 });
    }
  }, [state]);

  const redoSwap = useCallback(() => {
    if (state.pointer + 1 < state.history.length) {
      setState({ ...state, pointer: state.pointer + 1 });
    }
  }, [state]);

  const resetTraits = useCallback(() => {
    setState({ history: [originalTraitIds], pointer: 0 });
  }, [originalTraitIds]);

  const swapTraits = useCallback(
    (newTraitIds: string[]) => {
      const newSnapshot = [
        ...state.history.slice(0, state.pointer + 1),
        newTraitIds,
      ];

      setState({ history: newSnapshot, pointer: state.pointer + 1 });
    },
    [state]
  );

  const removeTrait = useCallback(
    (traitId: string) => {
      swapTraits(
        removeOrReplaceTrait(
          originalTraitIds,
          currentTraitIds,
          traitId,
          traitsById
        )
      );
    },
    [currentTraitIds, originalTraitIds, traitsById, swapTraits]
  );

  const addTrait = useCallback(
    (traitId: string) => {
      swapTraits(addOrReplaceTrait(currentTraitIds, traitId, traitsById));
    },
    [currentTraitIds, traitsById, swapTraits]
  );

  const addOrRemoveTrait = useCallback(
    (traitId: string) => {
      if (currentTraitIds.includes(traitId)) {
        removeTrait(traitId);
      } else {
        addTrait(traitId);
      }
    },
    [addTrait, removeTrait, currentTraitIds]
  );

  useEffect(() => {
    setState({
      history: [originalTraitIds],
      pointer: 0,
    });
  }, [originalTraitIds]);

  useEffect(() => {
    if (currentCategoryId && availableCategoryIds.includes(currentCategoryId)) {
      return;
    }
    setCurrentCategoryId(availableCategoryIds[0]);
  }, [availableCategoryIds, traitCollection.traitCollectionId]);

  const contextValue = useMemo<DressingRoomContextValue>(
    () => ({
      currentMode,
      currentCategoryId,
      currentTraitIds,
      changedTraitIds,
      originalTraitIds,
      availableTraitIds,
      availableTraitIdsByCategory,
      allTraitIds,
      allTraitIdsByTraitCollection,
      availableCategoryIds,
      canRedo: state.pointer < state.history.length - 1,
      canUndo: state.pointer > 0,
      undoSwap,
      redoSwap,
      resetTraits,
      swapTraits,
      addTrait,
      removeTrait,
      addOrRemoveTrait,
      changeMode,
      changeCategory,
    }),
    [
      currentMode,
      currentCategoryId,
      state,
      undoSwap,
      redoSwap,
      resetTraits,
      swapTraits,
      originalTraitIds,
      availableTraitIds,
      availableTraitIdsByCategory,
      allTraitIds,
      allTraitIdsByTraitCollection,
      availableCategoryIds,
      changedTraitIds,
      currentTraitIds,
      addTrait,
      removeTrait,
      addOrRemoveTrait,
      changeMode,
      changeCategory,
    ]
  );

  return (
    <DressingRoomContext.Provider value={contextValue}>
      {children}
    </DressingRoomContext.Provider>
  );
};

export default DressingRoomProvider;

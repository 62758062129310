import axios from 'axios';
import { BigNumber } from 'ethers';
import config from '../constants/baseConfig';
import { Currency, TraitSale } from '../types';

export type FetchTraitSalesReqData = {
  collectionAddress: string;
  walletAddress: string;
  traitCollectionAddress?: string;
};

export const fetchTraitSales = async ({
  walletAddress,
  collectionAddress,
  traitCollectionAddress,
}: FetchTraitSalesReqData): Promise<TraitSale[]> => {
  const res = await axios.get<any>(`/trait-sales`, {
    baseURL: config.urls.traitswapApiUrl,
    params: {
      collectionAddress,
      traitCollectionAddress,
    },
    headers: walletAddress
      ? {
          Wallet: walletAddress,
          Version: 'next',
        }
      : undefined,
  });

  return res.data.map((sale: any) => ({
    ...sale,
    price: sale.price
      ? Object.keys(sale.price).reduce(
          (prev, currency) => ({
            ...prev,
            [currency]: BigNumber.from(sale.price[currency] as string),
          }),
          {} as Record<Currency, BigNumber>
        )
      : undefined,
  }));
};

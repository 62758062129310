import { Box, Button, ModalProps } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import useUnstakeTraitsContractCall, {
  UnstakeTraitsContractCallData,
} from '../hooks/bc/useUnstakeTraitsContractCall';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTranslate from '../hooks/useTranslate';
import BeforeAfterPreview from './BeforeAfterPreview';
import SimplePopup from './SimplePopup';
import TraitTile from './TraitTile';

type UnstakePopupProps = Omit<ModalProps, 'children'> & {
  originalTraitIds: string[];
  currentTraitIds: string[];
  newTraitIds: string[];
  traitIdsToUnstake: string[];
  dynamicNftTokenId: number;
  onSuccess: () => void;
};

const UnstakePopup = ({
  dynamicNftTokenId,
  originalTraitIds,
  currentTraitIds,
  newTraitIds,
  traitIdsToUnstake,
  onSuccess,
  ...rest
}: UnstakePopupProps) => {
  const translate = useTranslate();
  const { traitsById, dynamicNftCollection } = useDynamicNftCollectionContext();

  const isMultiple = traitIdsToUnstake.length > 1;
  const slidesPerView = isMultiple ? 1.35 : 1;

  const categoriesToUnstake = useMemo(
    () => traitIdsToUnstake.map(traitId => traitsById[traitId].category),
    [traitIdsToUnstake, traitsById]
  );

  const callData: UnstakeTraitsContractCallData = useMemo(
    () => ({
      dynamicNftTokenId,
      dynamicNftCollectionAddress: dynamicNftCollection.contractAddress,
      categoriesToUnstake: categoriesToUnstake,
    }),
    [
      dynamicNftTokenId,
      categoriesToUnstake,
      dynamicNftCollection.contractAddress,
    ]
  );

  const [unstake, isUnstaking] = useUnstakeTraitsContractCall(
    callData,
    onSuccess
  );

  const renderItem = useCallback(
    (traitId: string) => {
      const trait = traitsById[traitId];

      if (!trait) {
        return <></>;
      }

      return (
        <SwiperSlide key={traitId}>
          <TraitTile
            traitId={traitId}
            tokenId={trait.tokenId}
            category={trait.category}
            name={trait.name}
            w="full"
            canvasSize="12"
          />
        </SwiperSlide>
      );
    },
    [traitsById]
  );

  return (
    <SimplePopup
      title={translate(
        isMultiple ? 'unstake:title:multi' : 'unstake:title:single',
        { count: traitIdsToUnstake.length }
      )}
      size="md"
      {...rest}
    >
      <Box pt="10">
        <BeforeAfterPreview
          currentTraitIds={currentTraitIds}
          newTraitIds={newTraitIds}
          imagesSize="256"
        />
      </Box>

      <Box mt="3">
        <Swiper
          spaceBetween={2}
          threshold={10}
          modules={[Controller]}
          slidesPerView={slidesPerView}
          freeMode
        >
          {traitIdsToUnstake.map(renderItem)}
        </Swiper>
      </Box>

      <Box pt="6">
        <Button
          w="full"
          colorScheme="primary"
          isLoading={isUnstaking}
          onClick={() => unstake()}
        >
          {translate('unstake:submit')}
        </Button>
      </Box>
    </SimplePopup>
  );
};

export default UnstakePopup;

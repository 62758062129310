import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useFancyBearStakedTraitsContractCall = (
  tokenId?: number
): [string[] | undefined, () => void] => {
  let stakedTraits;

  const [{ data: traitsData }, readStakedTraits] = useContractRead(
    {
      addressOrName: config.contracts.traitStakingContract.address,
      contractInterface: config.contracts.traitStakingContract.interface,
    },
    'getStakedTraits',
    {
      args: [tokenId],
    }
  );

  if (traitsData?.[0]) {
    stakedTraits = (traitsData[0] as BigNumber[])
      .map(tokenId => BigNumber.from(tokenId).toNumber())
      .filter(tokenId => tokenId > 0)
      .map(tokenId => `fancybeartraits:${tokenId}`);
  }

  return [stakedTraits, readStakedTraits];
};

export default useFancyBearStakedTraitsContractCall;

import HoneySlider from './HoneySlider';
import { BigNumber } from 'ethers';
import { BoxProps, HStack, Icon, IconButton } from '@chakra-ui/react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import {
  HoneyCheckoutFormValueKey,
  HoneyCheckoutFormValues,
} from '../types/purchaseValues';

type HoneyPurchaseSliderProps = Omit<BoxProps, 'onChange'> & {
  valueKey: HoneyCheckoutFormValueKey;
  label: string;
  values: HoneyCheckoutFormValues;
  max: BigNumber;
  badge?: string;
  onChange: (valueKey: HoneyCheckoutFormValueKey, amount: BigNumber) => void;
  onLockToggle: (
    valueKey: HoneyCheckoutFormValueKey,
    isLocked: boolean
  ) => void;
};

const HoneyPurchaseSlider = ({
  valueKey,
  values,
  max,
  label,
  badge,
  onChange,
  onLockToggle,
  ...rest
}: HoneyPurchaseSliderProps) => (
  <HStack
    w="full"
    spacing="4"
    px="4"
    py="1.5"
    bg="dark.800"
    borderRadius="md"
    align="center"
    justify="center"
    {...rest}
  >
    <HoneySlider
      label={label}
      badge={badge}
      max={max}
      value={values[valueKey]?.amount || BigNumber.from('0')}
      onChange={(value: BigNumber) => onChange(valueKey, value)}
    />

    <IconButton
      borderRadius="full"
      colorScheme="dark"
      size="sm"
      aria-label=""
      color={values[valueKey]?.isLocked ? 'primary.500' : 'bright.50'}
      icon={
        values[valueKey]?.isLocked ? (
          <Icon as={FaLock} />
        ) : (
          <Icon as={FaLockOpen} />
        )
      }
      onClick={() => onLockToggle(valueKey, !values[valueKey]?.isLocked)}
    />
  </HStack>
);

export default HoneyPurchaseSlider;

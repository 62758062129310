import React, { FunctionComponent, useCallback, useMemo } from 'react';
import TranslationsContext from '../contexts/TranslationsContext';
import { substitute } from '../utils/stringUtils';

type TranslationsProviderProps = {
  translations: { [key: string]: string };
};

const TranslationsProvider: FunctionComponent<TranslationsProviderProps> = ({
  translations,
  children,
}) => {
  const translate = useCallback(
    (key: string, values?: { [key: string]: any }) =>
      substitute(translations[key] || '', values),
    [translations]
  );

  const contextValue = useMemo(
    () => ({
      translate,
      translations,
    }),
    [translate, translations]
  );

  return (
    <TranslationsContext.Provider value={contextValue}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;

import * as dynamicNftCollectionsApi from '../../api/dynamicNftCollectionsApi';
import useApiCall from '../useApiCall';

const useFetchCollectionDynamicNftIdsApiCall = (collectionAddress: string) =>
  useApiCall<number[], string>(
    dynamicNftCollectionsApi.fetchDynamicNftIds,
    collectionAddress
  );

export default useFetchCollectionDynamicNftIdsApiCall;

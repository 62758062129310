import { BoxProps } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import MotionBox from '../components/MotionBox';

type FloatingPanelProps = BoxProps;

const FloatingPanel = ({ children, ...rest }: FloatingPanelProps) => (
  <AnimatePresence>
    {children && (
      // @ts-ignore
      <MotionBox
        position="fixed"
        w={{
          base: 'auto',
          sm: '26rem',
        }}
        bottom={{
          base: '2',
          sm: '2',
          md: '4',
          xl: '6',
        }}
        right={{
          base: '2',
          sm: '2',
          md: '4',
          xl: '6',
        }}
        left={{
          base: '2',
          sm: 'auto',
        }}
        zIndex="docked"
        initial={{ y: `80px`, opacity: 0 }}
        animate={{ y: `0`, opacity: 1 }}
        exit={{ y: `120px`, opacity: 0 }}
        {...rest}
      >
        {children}
      </MotionBox>
    )}
  </AnimatePresence>
);

export default FloatingPanel;

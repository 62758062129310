const theme = {
  colors: {
    primary: {
      50: '#f9d7a5',
      100: '#f7cf92',
      200: '#f6c780',
      300: '#f5bf6e',
      400: '#f3b75c',
      500: '#f2af4a',
      600: '#da9e43',
      700: '#c28c3b',
      800: '#a97a34',
      900: '#91692c',
    },
    secondary: {
      50: '#f9d7a5',
      100: '#f7cf92',
      200: '#f6c780',
      300: '#f5bf6e',
      400: '#f3b75c',
      500: '#f2af4a',
      600: '#da9e43',
      700: '#c28c3b',
      800: '#a97a34',
      900: '#91692c',
    },
    dark: {
      50: '#DBDADC',
      100: '#B7B5B9',
      200: '#726F78',
      300: '#929097',
      400: '#6E6B74',
      500: '#4A4651',
      600: '#36313E',
      700: '#2D2834',
      800: '#24202A',
      900: '#18151D',
    },
    bright: {
      50: '#fffcfa',
      100: '#fff9f4',
      200: '#fff6ef',
      300: '#fff3e9',
      400: '#fff0e4',
      500: '#ffebdb',
      600: '#ffe7d4',
      700: '#ffdfc6',
      800: '#ffdbbf',
      900: '#ffd7b8',
    },
  },
};

export default theme;

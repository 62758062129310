import * as traitCollectionsApi from '../../api/traitCollectionsApi';
import { TraitCollection } from '../../types';
import useApiCall from '../useApiCall';

const useFetchTraitCollectionsApiCall = (collectionAddress: string) =>
  useApiCall<TraitCollection[], string>(
    traitCollectionsApi.fetchTraitCollections,
    collectionAddress
  );

export default useFetchTraitCollectionsApiCall;

import BoldSpinner from '../components/BoldSpinner';
import config from '../constants/baseConfig';
import DynamicNftCard from '../components/DynamicNftCard';
import EmptyNftCardWithLink from '../components/EmptyNftCardWithLink';
import Panel from '../components/Panel';
import useAllOwnedDynamicNftsTokenIdsUtil from '../hooks/useAllOwnedDynamicNftsTokenIdsUtil';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import { plural } from 'pluralize';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Center,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

const DynamicNftsContainer = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();
  const { stakedFancyBears } = useWalletAssetsContext();
  const ownedTokenIds = useAllOwnedDynamicNftsTokenIdsUtil();

  const handleCardClick = useCallback(
    (tokenId: number) => {
      navigate(`/nft/${tokenId}/details`);
    },
    [navigate]
  );

  const renderCard = useCallback(
    (tokenId: number) => {
      let badge = null;
      if (isFancyBearsCollection && stakedFancyBears?.includes(tokenId)) {
        badge = (
          <Badge bg="primary.500" color="dark.900">
            {translate('common:staked')}
          </Badge>
        );
      }

      return (
        <DynamicNftCard
          key={tokenId}
          tokenId={tokenId}
          onClick={() => handleCardClick(tokenId)}
        >
          <HStack px="1.5" pt="1.5" w="full" justify="space-between">
            <Text fontWeight="600">#{tokenId}</Text>
            {badge}
          </HStack>
        </DynamicNftCard>
      );
    },
    [handleCardClick, isFancyBearsCollection, stakedFancyBears, translate]
  );

  if (ownedTokenIds === undefined) {
    return (
      <Center h="52">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return (
    <Panel>
      <Box mb="4" pl="2" mt="1.5">
        <Heading fontSize="2xl">
          {translate('dynamicNfts:title', {
            count: ownedTokenIds.length,
            dynamicNftName: plural(config.dynamicNft.dynamicNftName),
          })}
        </Heading>
        <Text fontSize="sm" mt="1" opacity="0.8">
          {translate('dynamicNfts:description', {
            dynamicNftName: plural(config.dynamicNft.dynamicNftName),
          })}
        </Text>
      </Box>

      <SimpleGrid
        columns={{
          base: 2,
          sm: ownedTokenIds.length > 1 ? 3 : 2,
        }}
        spacing="2"
        w="full"
      >
        {ownedTokenIds.map(tokenId => renderCard(tokenId))}

        <EmptyNftCardWithLink
          label={translate('common:buyOnOpenSea')}
          externalUrl={config.urls.dynamicNftOpenSeaUrl}
        />
      </SimpleGrid>
    </Panel>
  );
};

export default DynamicNftsContainer;

import { AspectRatio, AspectRatioProps, Image } from '@chakra-ui/react';
import config from '../constants/baseConfig';
import { ImageSize } from '../types';

type StaticNftImageProps = Omit<AspectRatioProps, 'ratio'> & {
  tokenId?: number;
  size?: ImageSize;
  isDisabled?: boolean;
  hasV2Badge?: boolean;
};

const StaticNftImage = ({
  tokenId,
  size = '512',
  isDisabled = false,
  hasV2Badge = false,
  ...rest
}: StaticNftImageProps) => (
  <AspectRatio
    ratio={1}
    w="full"
    bg="dark.400"
    borderRadius="md"
    overflow="hidden"
    position="relative"
    opacity={isDisabled ? 0.5 : 1}
    transition="opacity 0.15s"
    {...rest}
  >
    <>
      <Image
        fit="cover"
        src={
          Number.isInteger(tokenId)
            ? `${config.urls.staticNftImagesUrl}/${
                size !== 'original' ? `${size}/` : ''
              }${tokenId}.png`
            : undefined
        }
        fallbackSrc={`${config.urls.publicUrl}/v2-placeholder.png`}
      />
      {hasV2Badge && (
        <Image src={`${config.urls.publicUrl}/v2-badge.png`} fit="cover" />
      )}
    </>
  </AspectRatio>
);

export default StaticNftImage;

import { Badge, Box, HStack, Text } from '@chakra-ui/react';
import { BigNumber, BigNumberish } from 'ethers';
import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/slider';
import React, { useCallback, useEffect, useState } from 'react';

import { Currency } from '../types';
import EtherPrice from './EtherPrice';

type HoneySliderProps = {
  label: string;
  badge?: string;
  value: BigNumber;
  max: BigNumber;
  isLocked?: boolean;
  onChange: (value: BigNumber) => void;
} & Omit<RangeSliderProps, 'value' | 'onChange' | 'max' | 'min'>;

const getPercentageFromBn = (value: BigNumberish, of: BigNumber) =>
  parseFloat(BigNumber.from(value).mul('1000').div(of).toString()) / 10;

export const getBnFromPercentage = (value: BigNumberish, percentage: number) =>
  BigNumber.from(value)
    .mul(BigNumber.from(percentage))
    .div(BigNumber.from('100'));

const HoneySlider: React.FC<HoneySliderProps> = ({
  label,
  badge,
  value,
  max,
  isLocked = false,
  onChange,
  ...rest
}) => {
  const [{ honeyValue, percentageValue }, setState] = useState({
    percentageValue: 0,
    honeyValue: BigNumber.from('0'),
  });

  const handleChange = useCallback(
    (value: number[]) => {
      if (isLocked) {
        return;
      }

      let honeyValue: BigNumber;
      let percentageValue: number = value[0];
      try {
        honeyValue = getBnFromPercentage(max, value[0]);
        percentageValue = getPercentageFromBn(honeyValue, max);
        setState({
          honeyValue,
          percentageValue,
        });
      } catch (err) {}
    },
    [max, isLocked, setState]
  );

  const handleChangeEnd = useCallback(() => {
    if (isLocked) {
      return;
    }

    onChange(honeyValue);
  }, [isLocked, honeyValue, onChange]);

  useEffect(() => {
    if (
      BigNumber.isBigNumber(value) &&
      BigNumber.isBigNumber(max) &&
      !isLocked
    ) {
      setState({
        honeyValue: value,
        percentageValue: getPercentageFromBn(value, max),
      });
    }
  }, [value, max, isLocked]);

  return (
    <Box w="full">
      <HStack justify="space-between" w="full" pt="1.5">
        <HStack spacing="1.5" align="center">
          <Text fontWeight={'semibold'}>{label}</Text>
          {badge && (
            <Badge bg="dark.600" color="white">
              <Text opacity="0.6">{badge}</Text>
            </Badge>
          )}
        </HStack>
        <EtherPrice
          color="primary.500"
          fontWeight="semibold"
          currency={Currency.Honey}
          amount={honeyValue}
        />
      </HStack>

      <RangeSlider
        min={0}
        value={[percentageValue]}
        focusThumbOnChange={false}
        onChange={handleChange}
        onChangeEnd={handleChangeEnd}
        {...rest}
      >
        <RangeSliderTrack h="2" borderRadius="full" bg="dark.400">
          <RangeSliderFilledTrack bg="primary.500" />
        </RangeSliderTrack>

        <RangeSliderThumb
          index={0}
          boxSize="5"
          bg="bright.50"
        ></RangeSliderThumb>
      </RangeSlider>
    </Box>
  );
};

export default HoneySlider;

import { Box, BoxProps } from '@chakra-ui/react';

type AvatarProps = BoxProps & { size?: BoxProps['w'] };

const Avatar = ({ size = '12', ...rest }: AvatarProps) => (
  <Box
    w={size}
    h={size}
    flex="none"
    borderRadius="full"
    overflow="hidden"
    {...rest}
  />
);

export default Avatar;

import { Badge, Box, Center } from '@chakra-ui/react';
import BoldSpinner from './BoldSpinner';
import SelectableCard, { SelectableCardProps } from './SelectableCard';
import StaticNftImage from './StaticNftImage';

export type StaticNftCardProps = SelectableCardProps & {
  tokenId: number;
  isBusy?: boolean;
  badgeText?: string;
  hasV2Badge?: boolean;
  onClick?: (tokenId: number) => void;
};

const StaticNftCard: React.FC<StaticNftCardProps> = ({
  tokenId,
  badgeText,
  isBusy = false,
  isDisabled,
  hasV2Badge = false,
  children,
  onClick,
  ...rest
}) => (
  <SelectableCard
    isDisabled={isDisabled}
    onClick={() => onClick?.(tokenId)}
    {...rest}
  >
    <Box position="relative">
      <StaticNftImage
        tokenId={tokenId}
        isDisabled={isDisabled || isBusy}
        hasV2Badge={hasV2Badge}
      />

      <Center
        position="absolute"
        top="-2"
        right="-2"
        bg="bright.500"
        w="10"
        h="10"
        zIndex="dropdown"
        opacity={isBusy ? '1' : '0'}
        transition="opacity 0.15s"
      >
        {isBusy && <BoldSpinner size="sm" thickness="3px" />}
      </Center>

      {badgeText && (
        <Badge
          position="absolute"
          top="50%"
          left="50%"
          bg="dark.900"
          color="bright.50"
          px="4"
          py="2"
          transform="translate(-50%, -50%)"
        >
          {badgeText}
        </Badge>
      )}
    </Box>

    <Box>{children}</Box>
  </SelectableCard>
);

export default StaticNftCard;

import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useDynamicNftSaleStateContractCall = (): [
  isActive: boolean | undefined,
  readSaleState: () => void
] => {
  const [{ data: saleStateData }, readSaleState] = useContractRead(
    {
      addressOrName: config.contracts.dynamicNftSaleContract.address,
      contractInterface: config.contracts.dynamicNftSaleContract.interface,
    },
    'saleState'
  );

  if (saleStateData === undefined) {
    return [undefined, readSaleState];
  }

  return [(saleStateData as any as number) === 1 ? true : false, readSaleState];
};

export default useDynamicNftSaleStateContractCall;

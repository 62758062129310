import {
  AspectRatio,
  Box,
  Button,
  ButtonProps,
  HStack,
} from '@chakra-ui/react';
import FloatingBadge from './FloatingBadge';

export type ChipProps = Omit<
  ButtonProps,
  'color' | 'colorScheme' | 'leftIcon' | 'rightIcon' | 'size'
> & {
  isActive?: boolean;
  image?: ButtonProps['leftIcon'];
  badgeText?: string | number;
};

const Chip = ({
  badgeText,
  image,
  isActive = false,
  children,
  ...rest
}: ChipProps) => (
  <Button
    colorScheme={isActive ? 'primary' : 'dark'}
    bg={isActive ? 'primary.500' : 'dark.800'}
    borderRadius="full"
    pl={image ? '0' : '4'}
    pr="4"
    size="md"
    {...rest}
  >
    <HStack w="full" spacing="3">
      {image && (
        <FloatingBadge size="sm" text={badgeText} offset="20%">
          <AspectRatio
            ratio={1}
            w="9"
            h="9"
            ml="1.5"
            bg="dark.400"
            borderRadius="full"
            overflow="hidden"
          >
            {image}
          </AspectRatio>
        </FloatingBadge>
      )}

      <Box>{children}</Box>
    </HStack>
  </Button>
);

export default Chip;

import { Box, HStack, Icon, IconButton, useBoolean } from '@chakra-ui/react';
import { getCurrencyIcon, getCurrencySymbol } from '../utils/currencyUtils';

import { Currency } from '../types';
import CurrencySelect from '../components/CurrencySelect';
import EtherAmount from '../components/EtherAmount';
import { FaInfoCircle } from 'react-icons/fa';
import FeatureFlag from '../types/featureFlag';
import HoneyBalancePopup from '../components/HoneyBalancePopup';
import WalletAsset from '../components/WalletAsset';
import config from '../constants/baseConfig';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useHoneyOwnedTotalUtil from '../hooks/useHoneyOwnedTotalUtil';
import { useMemo } from 'react';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';

const WalletAssetsContainer = () => {
  const { currency, changeCurrency } = useCurrencyContext();
  const { ethBalance, erc20Balance } = useWalletAssetsContext();
  const honeyBalance = useHoneyOwnedTotalUtil();
  const [isHoneyBalancePopupVisible, setIsHoneyBalancePopupVisible] =
    useBoolean();

  const currencyOptions = useMemo(() => {
    const currencies = [
      {
        label: getCurrencySymbol(Currency.Eth),
        value: Currency.Eth,
      },
      {
        label: getCurrencySymbol(Currency.Honey),
        value: Currency.Honey,
      },
    ];

    if (isFeatureEnabled(FeatureFlag.Erc20Purchases)) {
      currencies.push({
        label: getCurrencySymbol(config.erc20.tokenName),
        value: config.erc20.tokenName,
      });
    }

    return currencies;
  }, []);

  const amountToDisplay = useMemo(() => {
    if (currency === Currency.Honey) {
      return <EtherAmount amount={honeyBalance} />;
    }
    if (currency === Currency.Eth) {
      return <EtherAmount amount={ethBalance} />;
    }

    return <EtherAmount amount={erc20Balance} />;
  }, [currency, ethBalance, honeyBalance, erc20Balance]);

  return (
    <>
      <HStack justify="stretch" spacing={3}>
        <WalletAsset
          py="4"
          icon={getCurrencyIcon(currency)}
          value={amountToDisplay}
        >
          <HStack spacing="1">
            {currency === Currency.Honey && (
              <IconButton
                aria-label=""
                size="xs"
                variant="ghost"
                icon={<Icon w="5" h="5" as={FaInfoCircle} />}
                onClick={setIsHoneyBalancePopupVisible.on}
              />
            )}

            <Box w="32" pr="3">
              <CurrencySelect
                currency={currency}
                options={currencyOptions}
                onChange={currency => changeCurrency(currency)}
              />
            </Box>
          </HStack>
        </WalletAsset>
      </HStack>

      {isHoneyBalancePopupVisible && (
        <HoneyBalancePopup isOpen onClose={setIsHoneyBalancePopupVisible.off} />
      )}
    </>
  );
};

export default WalletAssetsContainer;

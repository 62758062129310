import {
  AspectRatio,
  Box,
  BoxProps,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import { ImageSize } from '../types';
import { capitalize } from '../utils/stringUtils';
import EtherPrice from './EtherPrice';
import SupplyBadge from './SupplyBadge';
import TraitImage from './TraitImage';

export type TraitTileProps = Omit<BoxProps, 'onChange'> & {
  traitId: string;
  tokenId: number;
  category: string;
  name?: string;
  totalSupply?: number;
  maxSupply?: number;
  price?: BigNumber;
  canvasSize?: BoxProps['w'];
  canvasBg?: BoxProps['bg'];
  shapeOpacity?: number;
  imageSize?: ImageSize;
  isDisabled?: boolean;
  onClick?: (tokenId: number) => void;
};

const TraitTile: React.FC<TraitTileProps> = ({
  traitId,
  tokenId,
  category,
  price,
  name,
  totalSupply,
  maxSupply,
  canvasSize = '12',
  canvasBg = 'dark.400',
  imageSize = '256',
  shapeOpacity,
  children,
  isDisabled = false,
  onClick,
  ...rest
}) => {
  const { categories } = useDynamicNftCollectionContext();

  return (
    <Box
      bg="dark.800"
      p="3"
      w="full"
      userSelect="none"
      borderRadius="md"
      cursor={isDisabled ? 'not-allowed' : onClick ? 'pointer' : undefined}
      transition="all 0.15s"
      color="bright.50"
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? 'button' : undefined}
      onClick={() => {
        if (!isDisabled) {
          onClick?.(tokenId);
        }
      }}
      {...rest}
    >
      <HStack spacing="3" w="full" align="center">
        <AspectRatio
          ratio={1}
          bg={canvasBg}
          w={canvasSize}
          h={canvasSize}
          flex="none"
          borderRadius="md"
        >
          <>
            <Image
              src={`${config.urls.publicUrl}/v2-placeholder.png`}
              w="full"
              transition="opacity 0.15s"
              position="absolute"
              zIndex={category === categories[0].categoryId ? 2 : 0}
              opacity={shapeOpacity}
            />
            <TraitImage
              traitId={traitId}
              size={imageSize}
              position="absolute"
              zIndex={1}
            />
          </>
        </AspectRatio>

        <Box w="full" minW="0" whiteSpace="nowrap">
          <Text fontWeight="semibold" overflow="hidden" textOverflow="ellipsis">
            {name}
          </Text>

          <HStack spacing="1">
            <Text fontSize="xs" fontWeight="semibold">
              #{tokenId}
            </Text>

            {Number.isInteger(totalSupply) && Number.isInteger(maxSupply) && (
              <SupplyBadge
                totalSupply={totalSupply!}
                maxSupply={maxSupply!}
                size="sm"
              />
            )}

            <Text
              fontSize="xs"
              opacity={0.5}
              overflow="hidden"
              textOverflow="ellipsis"
              isTruncated
            >
              {capitalize(category || '')}
            </Text>
          </HStack>
        </Box>

        {price && (
          <EtherPrice
            amount={price}
            asPrice
            color="primary.500"
            fontWeight="semibold"
          />
        )}

        {children && <Box flex="none">{children}</Box>}
      </HStack>
    </Box>
  );
};

export default TraitTile;

import {
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import ContractsPopup from './ContractsPopup';
import DiscordIcon from './DiscordIcon';
import IncentivesPopup from './IncentivesPopup';
import OpenseaIcon from './OpenseaIcon';
import Panel from './Panel';
import TraitSwapLogo from './TraitSwapLogo';
import TwitterIcon from './TwitterIcon';

const Footer: React.FC = () => {
  const translate = useTranslate();
  const [isContractsPopupOpen, setIsContractsPopupOpen] = useBoolean(false);
  const [isIncentivesPopupOpen, setIsIncentivesPopupOpen] = useBoolean(false);

  return (
    <>
      <Panel as="footer" mt="auto" bg="dark.900" px="0" borderRadius="none">
        <Container>
          <HStack justify="space-between">
            <HStack spacing="5">
              <Link
                href={config.urls.dynamicNftOpenSeaUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={OpenseaIcon} w="7" h="auto" />
              </Link>

              <Link
                href={config.urls.discordUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={DiscordIcon} w="7" h="auto" />
              </Link>

              <Link
                href={config.urls.twitterUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={TwitterIcon} w="7" h="auto" />
              </Link>
            </HStack>

            <HStack justify="space-between" spacing="4">
              <Link href={config.urls.learnMoreUrl} target="_blank">
                <Button as="span" variant="link" fontSize="sm">
                  {translate('footer:learnMore')}
                </Button>
              </Link>

              <Button
                variant="link"
                fontSize="sm"
                onClick={setIsContractsPopupOpen.on}
              >
                {translate('common:contracts')}
              </Button>

              <Button
                variant="link"
                fontSize="sm"
                onClick={setIsIncentivesPopupOpen.on}
              >
                {translate('common:incentives')}
              </Button>
            </HStack>
          </HStack>

          <Divider my="2.5" />

          <LinkBox as={Center}>
            <Text fontSize="sm" fontWeight="semibold" mr="1.5">
              {translate('footer:powered')}
            </Text>
            <LinkOverlay href="//traitswap.com" target="_blank">
              <TraitSwapLogo width="65" height="auto" />
            </LinkOverlay>
          </LinkBox>
        </Container>
      </Panel>

      {isContractsPopupOpen && (
        <ContractsPopup isOpen onClose={() => setIsContractsPopupOpen.off()} />
      )}

      {isIncentivesPopupOpen && (
        <IncentivesPopup
          isOpen
          onClose={() => setIsIncentivesPopupOpen.off()}
        />
      )}
    </>
  );
};

export default Footer;

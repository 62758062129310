import { useEffect, useMemo } from 'react';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import config from '../../constants/baseConfig';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';

export type UnstakeTraitsContractCallData = {
  dynamicNftTokenId: number;
  dynamicNftCollectionAddress: string;
  categoriesToUnstake: string[];
};

const useUnstakeTraitsContractCall = (
  callData: UnstakeTraitsContractCallData,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const args = useMemo(
    () =>
      callData?.dynamicNftCollectionAddress &&
      callData?.dynamicNftTokenId &&
      callData?.categoriesToUnstake
        ? [
            callData.dynamicNftCollectionAddress,
            callData.dynamicNftTokenId.toString(),
            callData.categoriesToUnstake,
          ]
        : undefined,
    [
      callData?.dynamicNftCollectionAddress,
      callData?.dynamicNftTokenId,
      callData?.categoriesToUnstake,
    ]
  );

  const [{ data, loading: isLoading, error: writeError }, unstake] =
    useContractWrite(
      {
        addressOrName: config.contracts.traitSwapContract.address,
        contractInterface: config.contracts.traitSwapContract.interface,
      },
      'unstakeTraits',
      { args }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      confirmations: 1,
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(
    config.contracts.traitSwapContract.address,
    'unstakeTraits',
    args
  );

  console.log('unstakeTraits', writeError);
  console.log('unstakeTraits', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData, onSuccess]);

  return [unstake, isLoading || isWaiting];
};

export default useUnstakeTraitsContractCall;

import { useEffect, useMemo } from 'react';

import { getRandomInt } from '../utils/numberUtils';
import { matchPath } from 'react-router';
import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';
import useLocalStorageChange from './useLocalStorageChange';
import { useLocation } from 'react-router-dom';
import useWalletAssetsContext from './useWalletAssetsContext';

const LAST_DYNAMIC_NFT_TOKEN_ID_STORAGE_KEY = 'dynamicNftTokenId';

const useLastDynamicTokenIdUtil = () => {
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const { dynamicNftsInWallet } = useWalletAssetsContext();
  const { pathname } = useLocation();

  const pathMatch = matchPath('/nft/:tokenId/*', pathname);

  const [savedTokenId, saveTokenId] = useLocalStorageChange(
    LAST_DYNAMIC_NFT_TOKEN_ID_STORAGE_KEY
  );

  useEffect(() => {
    if (pathMatch?.params.tokenId) {
      saveTokenId(pathMatch?.params.tokenId);
    }
  }, [pathMatch?.params.tokenId, saveTokenId]);

  return useMemo(() => {
    if (!dynamicNftTokenIds.length) {
      return null;
    }

    const lastTokenId = savedTokenId;
    const lastTokenIdAsNumber = lastTokenId
      ? parseInt(lastTokenId.toString(), 10)
      : dynamicNftsInWallet?.[0];

    if (lastTokenIdAsNumber !== undefined) {
      if (dynamicNftTokenIds.includes(lastTokenIdAsNumber)) {
        return lastTokenIdAsNumber;
      } else {
        localStorage.removeItem(LAST_DYNAMIC_NFT_TOKEN_ID_STORAGE_KEY);
      }
    }

    return dynamicNftTokenIds[getRandomInt(0, dynamicNftTokenIds.length)];
  }, [dynamicNftsInWallet, dynamicNftTokenIds, savedTokenId]);
};

export default useLastDynamicTokenIdUtil;

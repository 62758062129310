import { BigNumber } from 'ethers';
import React from 'react';

export type VestingContextValue = {
  vestingAmountInBearsRemaining?: Record<number, BigNumber>;
  vestingAmountInBearsRemainingSum?: BigNumber;
  readVestingAmountRemainingInBears: () => void;
  vestingAmountInJarsRemaining?: Record<number, BigNumber>;
  vestingAmountInJarsRemainingSum?: BigNumber;
  readVestingAmountRemainingInJars: () => void;
  vestingAmountRemainingSum?: BigNumber;
  isLoading: boolean;
};

// @ts-ignore
const VestingContext = React.createContext<VestingContextValue>();

VestingContext.displayName = 'VestingContext';

export default VestingContext;

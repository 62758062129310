import {
  AspectRatio,
  Box,
  BoxProps,
  HStack,
  Icon,
  StackProps,
} from '@chakra-ui/react';
import { ImageSize } from '../types';
import SwapIcon from './SwapIcon';
import TraitsPreview from './TraitsPreview';

type BeforeAfterPreviewProps = {
  currentTraitIds: string[];
  newTraitIds: string[];
  imagesSize?: ImageSize;
  spacing?: StackProps['spacing'];
  canvasBg?: BoxProps['bg'];
};

const BeforeAfterPreview = ({
  currentTraitIds,
  newTraitIds,
  imagesSize = '512',
  canvasBg = 'dark.500',
}: BeforeAfterPreviewProps) => (
  <Box position="relative">
    <HStack spacing="3">
      <AspectRatio w="full" ratio={1}>
        <TraitsPreview
          traitIds={currentTraitIds}
          imagesSize={imagesSize}
          bgColor={canvasBg}
        />
      </AspectRatio>
      <AspectRatio w="full" ratio={1}>
        <TraitsPreview
          traitIds={newTraitIds}
          imagesSize={imagesSize}
          bgColor={canvasBg}
        />
      </AspectRatio>
    </HStack>

    <Icon
      as={SwapIcon}
      w="14"
      color="primary.500"
      position="absolute"
      left="50%"
      top="-4"
      transform="translate(-50%, -50%)"
      zIndex={1}
    />
  </Box>
);

export default BeforeAfterPreview;

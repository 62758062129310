import React from 'react';

const WardrobeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.1646 26.3854L24.8846 23.0858C24.5246 22.7226 23.9246 22.8264 23.6946 23.2829L21.4146 27.8588C21.1546 28.3776 21.5146 28.9898 22.0846 28.9898H26.7346C28.3346 29.0106 29.2046 27.423 28.1646 26.3854Z"
      fill="currentColor"
    />
    <path
      d="M6.4346 23.314C6.1946 22.8367 5.5846 22.733 5.2146 23.1065L1.9546 26.3854C0.914601 27.423 1.7846 29.0002 3.3846 29.0002H7.9946C8.5746 29.0002 8.9446 28.3672 8.6746 27.838L6.4346 23.314Z"
      fill="currentColor"
    />
    <path
      d="M21.6447 19.8275L16.0647 14.214C16.0847 12.6991 16.0147 11.4332 15.6747 10.6965C15.1447 9.54478 14.2547 8.60055 13.1847 8.05061C12.2447 7.51106 10.7847 6.35931 11.6047 4.80289C12.3347 3.42286 14.4447 2.54089 16.0447 3.45399C16.8247 3.91054 17.3847 4.64725 17.6247 5.52922C17.8647 6.41119 17.7447 7.32429 17.3047 8.11287C17.0247 8.61092 17.1847 9.24387 17.6647 9.5344C18.1447 9.82493 18.7547 9.65891 19.0347 9.16086C19.7447 7.90535 19.9247 6.38006 19.5547 4.97928C19.1747 3.55775 18.2747 2.38525 17.0247 1.64855C14.3047 0.0921302 11.0647 1.54479 9.85472 3.80678C8.97472 5.46696 9.05472 8.05061 12.2447 9.88719C12.9547 10.2607 13.5247 10.8625 13.8647 11.5889C14.0447 11.9728 14.0747 13.1453 14.0647 14.1933L8.47472 19.8171C8.09472 20.201 8.00472 20.7925 8.24472 21.2802L11.7447 28.3152C11.9547 28.7302 12.3647 28.9896 12.8147 28.9896H17.3147C17.7647 28.9896 18.1747 28.7302 18.3847 28.3152L21.8847 21.2802C22.1247 20.8132 22.0247 20.2114 21.6447 19.8275Z"
      fill="currentColor"
    />
  </svg>
);

export default WardrobeIcon;

import { useMemo } from 'react';
import * as dynamicNftMetadataApi from '../../api/dynamicNftMetadataApi';
import { Metadata } from '../../types';
import useApiCall from '../useApiCall';
import useCacheContext from '../useCacheContext';

const useFetchDynamicNftMetadataApiCall = (tokenId?: number) => {
  const { cacheId } = useCacheContext();

  const promise = useMemo(
    () => (data: number) => dynamicNftMetadataApi.fetchMetadata(data, cacheId),
    [cacheId]
  );

  return useApiCall<Metadata, number>(promise, tokenId);
};

export default useFetchDynamicNftMetadataApiCall;

import { Box, Center } from '@chakra-ui/react';
import { ImageSize } from '../types';
import BoldSpinner from './BoldSpinner';
import DynamicNftImage from './DynamicNftImage';
import SelectableCard, { SelectableCardProps } from './SelectableCard';

export type DynamicNftCardProps = SelectableCardProps & {
  tokenId: number;
  imageSize?: ImageSize;
  isBusy?: boolean;
  onClick?: (tokenId: number) => void;
};

const DynamicNftCard: React.FC<DynamicNftCardProps> = ({
  tokenId,
  imageSize,
  isDisabled = false,
  isBusy = false,
  children,
  onClick,
  ...rest
}) => (
  <SelectableCard
    isDisabled={isDisabled}
    onClick={() => onClick?.(tokenId)}
    {...rest}
  >
    <Box position="relative">
      <DynamicNftImage
        size={imageSize}
        tokenId={tokenId}
        isDisabled={isDisabled || isBusy}
      />

      <Center
        position="absolute"
        top="-2"
        right="-2"
        bg="bright.500"
        w="10"
        h="10"
        zIndex="dropdown"
        opacity={isBusy ? '1' : '0'}
        transition="opacity 0.15s"
      >
        {isBusy && <BoldSpinner size="sm" thickness="3px" />}
      </Center>
    </Box>

    <Box>{children}</Box>
  </SelectableCard>
);

export default DynamicNftCard;

import { chakraComponents, GroupBase } from 'chakra-react-select';
import FancyBearImage from '../components/FancyBearImage';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import {
  AsyncSmartSelect,
  AsyncSmartSelectProps,
  getDefaultOptionStyles,
} from './SmartSelect';

type FancyBearSelectOption = {
  label: string;
  value?: number;
  options?: FancyBearSelectOption[];
};

type FancyBearSelectProps = Omit<
  AsyncSmartSelectProps<
    FancyBearSelectOption,
    false,
    GroupBase<FancyBearSelectOption>
  >,
  | 'onChange'
  | 'value'
  | 'defaultOptions'
  | 'loadOptions'
  | 'components'
  | 'blurInputOnSelect'
> & {
  tokenId?: number;
  ownedTokenIds?: number[];
  firstTokenId?: number;
  lastTokenId?: number;
  optionPrefix?: string;
  onChange?: (tokenId?: number) => void;
};

const generateOptions = (
  inputValue: string = '1',
  firstTokenId: number,
  lastTokenId: number,
  optionPrefix: string
): FancyBearSelectOption[] => {
  const options = [];
  const valueAsNumber = parseInt(inputValue, 10);

  if (!Number.isInteger(valueAsNumber)) {
    return [];
  }

  const generateOptionsFrom = Math.max(
    Math.min(valueAsNumber, lastTokenId),
    firstTokenId
  );

  let counter = 0;
  let tokenId = generateOptionsFrom;
  while (tokenId < lastTokenId && counter <= 5) {
    options.push({
      value: tokenId,
      label: `${optionPrefix}${tokenId}`,
    });
    tokenId = parseInt(`${generateOptionsFrom}${counter}`);
    counter++;
  }

  if (options.length <= 1) {
    counter = 1;
    tokenId = generateOptionsFrom + 1;
    while (tokenId < lastTokenId && counter <= 5) {
      options.push({
        value: tokenId,
        label: `${optionPrefix}${tokenId}`,
      });
      tokenId++;
      counter++;
    }
  }

  return options;
};

const FancyBearSelect = ({
  tokenId,
  ownedTokenIds = [],
  firstTokenId = 1,
  lastTokenId = config.fancyBears.fancyBearsTotalSupply,
  optionPrefix = '#',
  chakraStyles = {},
  onChange,
  ...rest
}: FancyBearSelectProps) => {
  const translate = useTranslate();

  const defaultOptions = [
    {
      label: translate('bearSelect:owned'),
      options: ownedTokenIds
        .map(tokenId => ({
          label: `${optionPrefix}${tokenId}`,
          value: tokenId,
        }))
        .sort((a, b) => a.value - b.value),
    },
    {
      label: ownedTokenIds.length
        ? translate('bearSelect:other')
        : translate('bearSelect:community'),
      options: generateOptions(
        tokenId?.toString(),
        firstTokenId,
        lastTokenId,
        optionPrefix
      ).slice(1),
    },
  ];

  return (
    <AsyncSmartSelect<
      FancyBearSelectOption,
      false,
      GroupBase<FancyBearSelectOption>
    >
      value={tokenId ? { value: tokenId, label: `#${tokenId}` } : undefined}
      placeholder={translate('bearSelect:placeholder')}
      components={{
        Option: ({ children, ...props }) => (
          <chakraComponents.Option {...props}>
            <FancyBearImage
              tokenId={props.data.value!}
              size="256"
              w="8"
              h="8"
              flex="none"
              mr="3"
              borderRadius="full"
            />

            {children}
          </chakraComponents.Option>
        ),
        Input: ({ children, type, ...props }) => (
          <chakraComponents.Input
            type="number"
            min={firstTokenId}
            max={lastTokenId}
            {...props}
          />
        ),
      }}
      chakraStyles={{
        option: (...args) => ({
          ...getDefaultOptionStyles(...args),
          px: '3',
          py: '1.5',
        }),
        ...chakraStyles,
      }}
      defaultOptions={defaultOptions}
      loadOptions={(inputValue: string) =>
        Promise.resolve(
          generateOptions(inputValue, firstTokenId, lastTokenId, optionPrefix)
        )
      }
      onChange={option => onChange && onChange(option?.value)}
      blurInputOnSelect
      {...rest}
    />
  );
};

export default FancyBearSelect;

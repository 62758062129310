import axios from 'axios';
import config from '../constants/baseConfig';
import { TraitCollection } from '../types';

export const fetchTraitCollections = async (
  collectionAddress: string
): Promise<TraitCollection[]> => {
  const res = await axios.get<TraitCollection[]>('/trait-collections', {
    baseURL: config.urls.traitswapApiUrl,
    params: {
      collectionAddress,
    },
  });

  return res.data;
};

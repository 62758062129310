import React from 'react';

const BadgeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#badgeIconClipPath)">
      <path
        d="M14.9927 1C8.37401 1 3 6.01083 3 12.1769C3 15.8881 4.94753 19.1661 7.93472 21.2022V27.3971C7.93472 28.5235 9.06223 29.2888 10.1312 28.8989L14.4362 27.3105C14.8023 27.1805 15.2123 27.1805 15.5784 27.3105L19.8542 28.8845C20.9231 29.2744 22.0506 28.4946 22.0506 27.3827V21.1877C25.0378 19.1516 26.9854 15.8736 26.9854 12.1625C27 6.01083 21.626 1 14.9927 1ZM21.8749 10.9495L19.1952 13.5199C19.0342 13.6787 18.961 13.9097 18.9902 14.1264L19.6199 17.7653C19.7224 18.3285 19.122 18.7473 18.6095 18.4874L15.3002 16.769C15.0952 16.6679 14.8609 16.6679 14.6559 16.769L11.3612 18.4729C10.8487 18.7329 10.263 18.3141 10.3508 17.7509L10.9805 14.1119C11.0244 13.8953 10.9512 13.6643 10.7755 13.5054L8.12508 10.9495C7.70043 10.5451 7.93472 9.86643 8.5058 9.77978L12.2105 9.24549C12.4301 9.21661 12.6205 9.08664 12.723 8.88448L14.3777 5.57762C14.6266 5.0722 15.3588 5.0722 15.6223 5.57762L17.277 8.88448C17.3795 9.08664 17.5699 9.23105 17.8041 9.25993L21.5088 9.79422C22.0653 9.86643 22.2849 10.5451 21.8749 10.9495Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="badgeIconClipPath">
        <rect width="24" height="28" fill="white" transform="translate(3 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default BadgeIcon;

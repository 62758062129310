import React from 'react';
import { TraitSale } from '../types';

export type StoreContextValue = {
  traitSales: TraitSale[];
  traitSalesByTraitId: Record<string, TraitSale>;
  traitSalesByTraitCollectionId: Record<string, TraitSale[]>;
  fetchTraitSales: () => void;
  isLoading: boolean;
};

const StoreContext =
  // @ts-ignore
  React.createContext<StoreContextValue>();

StoreContext.displayName = 'StoreContext';

export default StoreContext;

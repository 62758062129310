import config from '../../constants/baseConfig';
import useAnyErc20AllowanceContractCall from './useAnyErc20AllowanceContractCall';

const useHoneyAllowanceContractCall = (
  spenderAddress: string,
  walletAddress?: string
) =>
  useAnyErc20AllowanceContractCall(
    config.contracts.honeyTokenContract.address,
    spenderAddress,
    walletAddress
  );

export default useHoneyAllowanceContractCall;

import React from 'react';
import { Currency } from '../types';

type CurrencyContextValue = {
  currency: Currency;
  changeCurrency: (currency: Currency) => void;
};

// @ts-ignore
const CurrencyContext = React.createContext<CurrencyContextValue>();

CurrencyContext.displayName = 'CurrencyContext';

export default CurrencyContext;

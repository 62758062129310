import { HStack } from '@chakra-ui/react';
import HoneyIcon from '../components/HoneyIcon';
import WalletAsset from '../components/WalletAsset';
import { formatEtherBalance } from '../utils/numberUtils';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useFancyBearHoneyStats from '../hooks/useFancyBearHoneyStats';
import useTranslate from '../hooks/useTranslate';

const FancyBearHoneyStatsContainer = () => {
  const translate = useTranslate();
  const { dynamicNft } = useDynamicNftContext();

  const fancyBearStats = useFancyBearHoneyStats(dynamicNft.tokenId);

  return fancyBearStats ? (
    <HStack w="full">
      <WalletAsset
        bg="dark.700"
        icon={HoneyIcon}
        title={translate('stats:honeyInVesting')}
        value={
          fancyBearStats.honeyInVesting
            ? formatEtherBalance(fancyBearStats.honeyInVesting)
            : '0'
        }
      />

      <WalletAsset
        bg="dark.700"
        icon={HoneyIcon}
        title={translate('stats:honeyInHive')}
        value={
          fancyBearStats.honeyInHive
            ? formatEtherBalance(fancyBearStats.honeyInHive)
            : '0'
        }
      />
    </HStack>
  ) : null;
};

export default FancyBearHoneyStatsContainer;

import { useContractWrite, useWaitForTransaction } from 'wagmi';
import { useEffect, useMemo } from 'react';

import config from '../../constants/baseConfig';
import { getLevelAttribution } from '../../utils/purchaseUtils';
import useCaptureRpcException from '../useCaptureRpcException';
import useDynamicNftCollectionContext from '../useDynamicNftCollectionContext';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';
import useTraitCollectionContext from '../useTraitCollectionContext';

const useV2TraitsPurchaseWithErc20ContractCall = (
  erc20Address: string,
  selectedTokenId?: number,
  traitTokenIds?: number[],
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const { traitCollection } = useTraitCollectionContext();
  const { dynamicNftCollection } = useDynamicNftCollectionContext();

  const args = useMemo(() => {
    if (!traitTokenIds?.length) {
      return;
    }

    const amountPerTrait = new Array(traitTokenIds?.length || 0).fill(1);

    const levelAttribution = getLevelAttribution(
      selectedTokenId,
      dynamicNftCollection.contractAddress
    );

    return [erc20Address, traitTokenIds, amountPerTrait, levelAttribution];
  }, [
    erc20Address,
    selectedTokenId,
    traitTokenIds,
    dynamicNftCollection.contractAddress,
  ]);

  const [{ data, loading: isLoading, error: writeError }, purchase] =
    useContractWrite(
      {
        addressOrName: traitCollection.saleContractAddress,
        contractInterface: config.contracts.traitSaleContract.interface,
      },
      'purchaseTraitsWithERC20',
      {
        args,
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({ confirmations: 1, wait: data?.wait });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(
    traitCollection.saleContractAddress,
    'purchaseTraitsWithERC20',
    args
  );

  console.log('purchaseTraitsWithERC20', writeError);
  console.log('purchaseTraitsWithERC20', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [purchase, isLoading || isWaiting];
};

export default useV2TraitsPurchaseWithErc20ContractCall;

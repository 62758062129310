import { AspectRatio, Box, Image, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react/swiper-react';
import config from '../constants/baseConfig';
import { Member } from '../types';

type MembersSwiperProps = SwiperProps & {};

const MembersSwiper = (props: MembersSwiperProps) => {
  const [members, setMembers] = useState<Member[]>();

  useEffect(() => {
    const fetchMembers = async () => {
      const res = await axios.get<Member[]>(
        `${config.urls.publicUrl}/members.json`
      );
      setMembers(res.data!);
    };

    fetchMembers();
  }, []);

  return (
    <Box w="full">
      <Swiper
        spaceBetween={0}
        threshold={10}
        modules={[Controller]}
        slidesPerView={3.25}
        freeMode
        {...props}
      >
        {members?.map(({ imageUrl, twitterHandle }) => (
          <SwiperSlide key={imageUrl}>
            <VStack
              textAlign="center"
              align="center"
              direction="column"
              as="a"
              href={`//twitter.com/${twitterHandle}`}
              target="_blank"
              role="group"
              pt="1.5"
              px="1.5"
              spacing="1.5"
            >
              <AspectRatio
                ratio={1}
                w="20"
                bg="dark.400"
                borderRadius="full"
                overflow="hidden"
              >
                <Image
                  fit="cover"
                  src={imageUrl}
                  fallbackSrc={`${config.urls.publicUrl}/v2-placeholder.png`}
                />
              </AspectRatio>

              <Text
                fontSize="xs"
                fontWeight="semibold"
                isTruncated
                flex="none"
                minW="0"
                maxW="100%"
                _groupHover={{ color: 'primary.500' }}
                transition="all 0.15s"
              >
                @{twitterHandle}
              </Text>
            </VStack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default MembersSwiper;

import { applyTraitsSwaps } from '../utils/traitsUtils';
import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';

const useApplyTraitsSwapsUtil = (
  originalTraitIds: string[],
  newTraitIds: string[] = []
): string[] => {
  const { traitsById } = useDynamicNftCollectionContext();
  return applyTraitsSwaps(originalTraitIds, newTraitIds, traitsById);
};

export default useApplyTraitsSwapsUtil;

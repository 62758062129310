import { BigNumber } from 'ethers';
import React from 'react';

export type DynamicNftSaleContextValue = {
  isSaleActive?: boolean;
  price?: BigNumber;
  honeyReward?: BigNumber;
  readSaleState: () => void;
  readPrice: () => void;
  readHoneyReward: () => void;
};

// @ts-ignore
const DynamicNftSaleContext = React.createContext<DynamicNftSaleContextValue>();

DynamicNftSaleContext.displayName = 'DynamicNftSaleContext';

export default DynamicNftSaleContext;

import {
  Button,
  HStack,
  Icon,
  IconButton,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { useCallback, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { FaTimes } from 'react-icons/fa';
import FloatingPanel from '../components/FloatingPanel';
import Panel from '../components/Panel';
import PurchasePopup from '../components/PurchasePopup';
import StakeTraitsPopup from '../components/StakeTraitsPopup';
import StakeTraitsSuccessPopup from '../components/StakeTraitsSuccessPopup';
import TraitTile from '../components/TraitTile';
import config from '../constants/baseConfig';
import useApplyTraitsSwapsUtil from '../hooks/useApplyTraitsSwapsUtil';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useDressingRoomContext from '../hooks/useDressingRoomContext';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useHoneyOwnedTotalUtil from '../hooks/useHoneyOwnedTotalUtil';
import useStoreContext from '../hooks/useStoreContext';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import { Currency } from '../types';
import { getCurrencySymbol } from '../utils/currencyUtils';
import { formatEtherBalance } from '../utils/numberUtils';
import SelectedTraitsContainer from './SelectedTraitsContainer';

const StoreBagContainer = () => {
  const translate = useTranslate();
  const { currency } = useCurrencyContext();
  const { traitsById, isFancyBearsCollection } =
    useDynamicNftCollectionContext();
  const { traitSalesByTraitId, fetchTraitSales } = useStoreContext();
  const { dynamicNft } = useDynamicNftContext();
  const {
    ethBalance,
    erc20Balance,
    dynamicNftsInWallet,
    stakedFancyBears,
    readHoneyBalance,
    readErc20Balance,
  } = useWalletAssetsContext();
  const honeyBalance = useHoneyOwnedTotalUtil();
  const { changedTraitIds, originalTraitIds, removeTrait } =
    useDressingRoomContext();
  const [selectedTraitIds, setSelectedTraitIds] = useState<string[]>([]);
  const [isPurchasePopupOpen, setIsPurchasePopupOpen] = useBoolean();
  const [isStakeTraitsPopupOpen, setIsStakeTraitsPopupOpen] = useBoolean();
  const [isStakeTraitsSuccessPopupOpen, setIsStakeTraitsSuccessPopupOpen] =
    useBoolean();

  const totalPrice = useMemo(
    () =>
      changedTraitIds?.reduce((prev, tokenId) => {
        const traitSale = traitSalesByTraitId[tokenId];
        return traitSale
          ? prev.add(traitSale.price[currency] || BigNumber.from('0'))
          : prev;
      }, BigNumber.from('0')),
    [currency, changedTraitIds, traitSalesByTraitId]
  );

  const isInWallet = dynamicNftsInWallet?.includes(dynamicNft.tokenId);
  const isStaked =
    isFancyBearsCollection && stakedFancyBears?.includes(dynamicNft.tokenId);
  const isOwner = isInWallet || isStaked;

  const hasMultipleTraitsSelected = (changedTraitIds?.length || 0) > 1;
  const areAllTraitsFree = totalPrice?.eq('0') ?? false;

  const isTooExpensive = useMemo(() => {
    if (!totalPrice) {
      return false;
    }

    if (currency === Currency.Honey) {
      return !honeyBalance || honeyBalance.lt(totalPrice);
    }

    if (currency === Currency.Eth) {
      return !ethBalance || ethBalance.lt(totalPrice);
    }

    return !erc20Balance || erc20Balance.lt(totalPrice);
  }, [currency, ethBalance, honeyBalance, erc20Balance, totalPrice]);

  const hasChangedTraits = !!(changedTraitIds && changedTraitIds.length);

  const isOneSoldOutOrUnknown = useMemo(
    () =>
      changedTraitIds
        ? changedTraitIds?.some(tokenId => {
            const traitSale = traitSalesByTraitId[tokenId];
            return traitSale ? traitSale.counter >= traitSale.maxSupply : true;
          })
        : false,
    [changedTraitIds, traitSalesByTraitId]
  );

  const newTraitIds = useApplyTraitsSwapsUtil(
    dynamicNft.traitIds,
    selectedTraitIds
  );

  const refreshData = useCallback(() => {
    readHoneyBalance();
    readErc20Balance();
    fetchTraitSales();
  }, [readHoneyBalance, readErc20Balance, fetchTraitSales]);

  const handlePurchase = useCallback(() => {
    if (changedTraitIds) {
      setSelectedTraitIds(changedTraitIds);
      setIsPurchasePopupOpen.on();
    }
  }, [changedTraitIds, setSelectedTraitIds, setIsPurchasePopupOpen]);

  const handlePurchaseSuccess = useCallback(() => {
    setIsPurchasePopupOpen.off();

    if (totalPrice) {
      ReactGA.event({
        category: 'Trait Sale',
        action: 'Purchase',
        value: Number(formatEther(totalPrice)),
        label: currency,
      });
    }

    if (isOwner) {
      setIsStakeTraitsPopupOpen.on();
    } else {
      refreshData();
    }
  }, [
    isOwner,
    totalPrice,
    currency,
    setIsPurchasePopupOpen,
    setIsStakeTraitsPopupOpen,
    refreshData,
  ]);

  const handleStakeTraitsPopupClose = useCallback(() => {
    refreshData();
    setIsStakeTraitsPopupOpen.off();
  }, [refreshData, setIsStakeTraitsPopupOpen]);

  const handleStakeSuccess = useCallback(() => {
    setIsStakeTraitsPopupOpen.off();
    setIsStakeTraitsSuccessPopupOpen.on();
  }, [setIsStakeTraitsPopupOpen, setIsStakeTraitsSuccessPopupOpen]);

  const renderItem = useCallback(
    (traitId: string) => {
      const traitSale = traitSalesByTraitId[traitId];
      const trait = traitsById[traitId];

      if (!trait || !traitSale) {
        return <></>;
      }

      return (
        <TraitTile
          key={traitId}
          traitId={traitId}
          tokenId={trait.tokenId}
          name={trait.name}
          category={trait.category}
          price={traitSale.price[currency]}
          totalSupply={traitSale.counter}
          maxSupply={traitSale.maxSupply}
          canvasSize="12"
          bg="dark.900"
        >
          <HStack spacing="3">
            <IconButton
              size="sm"
              aria-label=""
              icon={<Icon as={FaTimes} />}
              borderRadius="full"
              colorScheme="dark"
              onClick={() => removeTrait(traitId)}
            />
          </HStack>
        </TraitTile>
      );
    },
    [currency, traitSalesByTraitId, traitsById, removeTrait]
  );

  return (
    <>
      <FloatingPanel>
        {hasChangedTraits && (
          <Panel bg="dark.800" outline="2px solid" outlineColor="dark.500">
            <VStack spacing="3">
              <SelectedTraitsContainer renderItem={renderItem} />

              {totalPrice && (
                <Button
                  w="full"
                  onClick={handlePurchase}
                  isDisabled={isOneSoldOutOrUnknown || isTooExpensive}
                >
                  {translate(
                    (areAllTraitsFree ? 'bag:submit:free' : 'bag:submit') +
                      (hasMultipleTraitsSelected ? ':multi' : ''),
                    {
                      count: changedTraitIds.length,
                      price: formatEtherBalance(totalPrice),
                      currency: getCurrencySymbol(currency),
                    }
                  )}
                </Button>
              )}
            </VStack>
          </Panel>
        )}
      </FloatingPanel>

      {changedTraitIds && isPurchasePopupOpen && (
        <PurchasePopup
          isOpen
          onClose={setIsPurchasePopupOpen.off}
          onSuccess={handlePurchaseSuccess}
        />
      )}

      {selectedTraitIds && isStakeTraitsPopupOpen && (
        <StakeTraitsPopup
          isOpen
          currentTraitIds={originalTraitIds}
          newTraitIds={newTraitIds}
          traitIdsToStake={selectedTraitIds}
          dynamicNftTokenId={dynamicNft.tokenId}
          onClose={handleStakeTraitsPopupClose}
          onSuccess={handleStakeSuccess}
        />
      )}

      {selectedTraitIds && isStakeTraitsSuccessPopupOpen && (
        <StakeTraitsSuccessPopup
          isOpen
          title={translate('stake:success:title')}
          description={translate('stake:success:description', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
          dynamicNftTokenId={dynamicNft.tokenId}
          newTraitIds={newTraitIds}
          withConfetti
        />
      )}
    </>
  );
};

export default StoreBagContainer;

const baseTranslations = {
  'common:claimed': 'Claimed',
  'common:claim': 'Claim',
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:back': 'Back',
  'common:reset': 'Reset',
  'common:max': 'Max',
  'common:buy': 'Buy',
  'common:view': 'View',
  'common:stake': 'Stake',
  'common:unstake': 'Unstake',
  'common:noResults': "There's nothing in here",
  'common:undo': 'Undo',
  'common:redo': 'Redo',
  'common:buyOnOpenSea': 'Buy on OpenSea',
  'common:viewOnOpenSea': 'View on OpenSea',
  'common:close': 'Close',
  'common:ok': 'OK',
  'common:refresh': 'Refresh',
  'common:level': 'Level',
  'common:incentives': 'Incentives',
  'common:contracts': 'Contracts',
  'common:price': 'Price',
  'common:reward': 'Reward',
  'common:discount': 'Discount',
  'common:mint': 'Mint',
  'common:minted': 'Minted',
  'common:or': 'Or',
  'common:zoom': 'Zoom',
  'common:gallery': 'Gallery',
  'common:grid': 'Grid',
  'common:staked': 'Staked',
  'common:nextLevel': 'Next LVL',
  'common:honeyJars': 'Honey Jars',
  'common:vesting': 'Vesting',
  'common:hive': 'Hive',
  'common:fancyBears': 'Fancy Bears',

  'common:agree': 'Agree',
  'common:sign': 'Sign',
  'common:cancel': 'Cancel',
  'common:get': 'Get',
  'common:free': 'Free',

  'bearSelect:owned': 'Yours',
  'bearSelect:community': 'Fancy Bears',
  'bearSelect:other': 'Other',
  'bearSelect:placeholder': '#???',

  'v2Select:owned': 'Yours',
  'v2Select:community': "NFT's",
  'v2Select:other': 'Other',
  'v2Select:placeholder': '#???',

  'error:default': 'Oops! Something went wrong.',

  'error:forbidden:title': 'Oops…',
  'error:forbidden:description': "You don't have access to this page.",
  'error:forbidden:backToHome': 'Back to Home',

  'error:notFound:title': '404',
  'error:notFound:description': 'Page not found.',
  'error:notFound:backToHome': 'Back to Home',

  'success:default': 'Success! Thank you.',

  'connect:title': 'Connect Your Wallet',
  'connect:button:label': 'Connect with {connector}',
  'connect:appTitle': 'Trait Swap App',
  'connect:appDescription':
    'Buy new traits, swap them and Level Up your Bear! Make your NFT unique, one-and-only, resembling your true digital identity.',

  'nfts:reserve:info':
    'Do you have {staticNftName} in a different wallet? We will mint a {dynamicNftName} for you.',
  'nfts:reserve:trigger': 'Reserve {dynamicNftName}',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',

  'unsupportedCurrency:title': 'Unsupported Currency',
  'unsupportedCurrency:description':
    '{collectionName} collection supports only {currencies}.',
  'unsupportedCurrency:switch': 'Switch to {currency}',

  'dynamicNfts:title': "Dynamic NFT's",
  'dynamicNfts:description':
    'Click on one of your {dynamicNftName} to see the details.',

  'staticNfts:title': "Static NFT's",
  'staticNfts:description': "Select NFT's to mint their Dynamic equivalents.",
  'staticNfts:mint': 'Mint #{tokenId}',
  'staticNfts:mint:multi': 'Mint {count} {dynamicNftName} ({price} {currency})',
  'staticNfts:mint:one': 'Mint {dynamicNftName} ({price} {currency})',
  'staticNfts:mint:none': 'Mint {dynamicNftName}',

  'walletPopup:title': 'Your Wallet',

  'honeyPopup:title': 'Your $HONEY',
  'honeyPopup:honeyInWallet:title': '$HONEY in Wallet',
  'honeyPopup:honeyInHive:title': '$HONEY in Hive',
  'honeyPopup:honeyInJars:title': '$HONEY in Jars',
  'honeyPopup:honeyInBears:title': '$HONEY in Bears',

  'mainMenu:nfts': "NFT's",
  'mainMenu:store': 'Store',
  'mainMenu:wardrobe': 'Wardrobe',

  'terms:title': 'Terms of Use',

  'reserve:title:disconnected':
    'Get {dynamicNftName} without connecting your wallet with {staticNftName}.',
  'reserve:title:connected':
    'Do you have {staticNftName} in a different wallet? We will mint a {dynamicNftName} for you.',
  'reserve:trigger': 'Reserve {dynamicNftName}',

  'reserve:confirm:title': 'Reserve a {dynamicNftName}',
  'reserve:confirm:description':
    'Use this option only if you don’t want to connect your {staticNftName} wallet. We will verify your ownership in a few simple steps:',
  'reserve:confirm:step:1':
    'Give us your {staticNftName}s numbers of which you want to mint a {dynamicNftName}s',
  'reserve:confirm:step:2':
    "Make a {currency} transfer from the wallet with your {staticNftName} to the wallet that will appear in Step 2 (so we can verify you're the true owner)",
  'reserve:confirm:step:3':
    'Please send the exact amount necessary for the minting ({price} {currency} for each {staticNftName} you want to V2, you can do multiple in one transaction)',
  'reserve:confirm:step:4':
    'We will mint your {dynamicNftName} directly to your wallet in max. 24h after the transfer',
  'reserve:confirm:address:placeholder': 'Your {staticNftName} wallet address…',
  'reserve:confirm:tokenIds:placeholder': 'Select {staticNftName} you own…',
  'reserve:confirm:submit': 'Confirm',
  'reserve:confirm:invalid': 'Enter a valid ethereum address',

  'reserve:success:title': 'Almost Done…',
  'reserve:success:description':
    'Transfer <strong>{price} {currency}</strong> from your {staticNftName} wallet to the address below:',
  'reserve:success:note': "We'll handle the rest.",
  'reserve:success:ok': 'Okay, Got It',

  'joinTheClub:title': 'Join the {dynamicNftName} Club!',

  'sales:title:1': 'Pre-sale I',
  'sales:title:2': 'Pre-sale II',
  'sales:title:3': 'Pre-sale III',
  'sales:title:4': 'Public Sale',

  'bag:title': 'Selected Traits',
  'bag:toggle:on': 'Show',
  'bag:toggle:off': 'Hide',
  'bag:submit': 'Buy the Trait ({price} {currency})',
  'bag:submit:free': 'Get the Trait',
  'bag:submit:multi': 'Buy {count} Traits ({price} {currency})',
  'bag:submit:free:multi': 'Get {count} Traits',

  'hanger:title': 'Selected Traits',
  'hanger:stakeAll': 'Stake {count} Traits',

  'stakedTraits:title': 'V2 Traits',
  'stakedTraits:stakeTraits': 'Stake Traits',
  'stakedTraits:unstakeAll': 'Unstake {count} Traits',
  'stakedTraits:swap': 'Swap Traits',
  'stakedTraits:buy': 'Buy Traits',

  'stakedFancyBearTraits:title': 'V2 Traits (Old Contract)',

  'nftAttrs:label': 'Attributes ({count})',

  'purchase:title:single': 'Get the Trait',
  'purchase:title:multi': 'Get {count} Traits',
  'purchase:wallet:title': 'HONEY in Wallet',
  'purchase:traits:title': 'Traits You Selected',
  'purchase:dynamicNft:title': '{dynamicNftName} to Level Up',
  'purchase:dynamicNft:draw': 'Draw',
  'purchase:honeyInJars:title': 'HONEY in Honey Jars',
  'purchase:dynamicNfts:title': 'HONEY in {dynamicNftName}',
  'purchase:honeyInDynamicNfts:title': 'HONEY in Fancy Bear #{tokenId}',
  'purchase:submit': 'Pay ({price} {currency})',
  'purchase:submit:free': 'Get It',
  'purchase:approve': 'Approve',

  'stats:honeyInHive': 'Honey in Hive',
  'stats:honeyInVesting': 'Honey in Vesting',

  'stake:title:single': 'Stake the Trait',
  'stake:title:multi': 'Stake {count} Traits',
  'stake:submit': 'Stake',
  'stake:approve': 'Approve',

  'stake:success:title': 'Woohoo!',
  'stake:success:description':
    "Congratulations! That's your {dynamicNftName} new look!",

  'unstake:title:single': 'Unstake the Trait',
  'unstake:title:multi': 'Unstake {count} Traits',
  'unstake:submit': 'Unstake',
  'unstake:success:title': 'Success!',
  'unstake:success:description': 'Traits has been successfully unstaked.',

  'mint:success:title': 'Whoopee!',
  'mint:success:description': 'Your new, awesome {dynamicNftName} #{tokenId}',
  'mint:success:description:multi': 'Your new, awesome {dynamicNftName}',

  'incentives:title': 'Sale Incentives',
  'incentives:bearHolder:title': '{discount}% off for Fancy Bear holders',
  'incentives:bearHolder:description:done': 'You own {amount} Fancy Bears',
  'incentives:bearHolder:description': "Don't own a Fancy Bear yet?",
  'incentives:bearHolder:badge': '{discount}% off',

  'howItWorks:title': 'Curious to See How It Works? ',
  'howItWorks:video': 'Watch the Video',
  'howItWorks:incentives': 'Incentives',

  'footer:vestingApp': 'Vesting',
  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',

  'refreshCountdown:message':
    'Please wait a few seconds for the metadata to update.',

  'fancyBearLevel:title': 'Level {level}',
  'fancyBearLevel:honeyEaten': 'Honey Eaten',
};

export default baseTranslations;

import * as dynamicNftCollectionsApi from '../../api/dynamicNftCollectionsApi';
import { Trait } from '../../types';
import useApiCall from '../useApiCall';

const useFetchCollectionTraitsApiCall = (collectionAddress: string) =>
  useApiCall<Trait[], string>(
    dynamicNftCollectionsApi.fetchTraits,
    collectionAddress
  );

export default useFetchCollectionTraitsApiCall;

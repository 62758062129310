import { Center } from '@chakra-ui/react';
import BoldSpinner from '../components/BoldSpinner';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTokenIdParamUtil from '../hooks/useTokenIdParamUtil';

type DynamicNftExistsGuardProps = {
  yeyElement: JSX.Element;
  neyElement: JSX.Element;
};

const DynamicNftExistsGuard = ({
  yeyElement,
  neyElement,
}: DynamicNftExistsGuardProps) => {
  const tokenId = useTokenIdParamUtil();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();

  if (!dynamicNftTokenIds) {
    return (
      <Center h="50vh">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return tokenId !== undefined && dynamicNftTokenIds.includes(tokenId)
    ? yeyElement
    : neyElement;
};

export default DynamicNftExistsGuard;

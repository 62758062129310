import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import StoreContext, { StoreContextValue } from '../contexts/StoreContext';

import BoldSpinner from '../components/BoldSpinner';
import { Center } from '@chakra-ui/react';
import { TraitSale } from '../types';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useFetchTraitSalesApiCall from '../hooks/api/useFetchTraitSalesApiCall';

type StoreProviderProps = {};

const StoreProvider: FunctionComponent<StoreProviderProps> = ({ children }) => {
  const { dynamicNftCollection } = useDynamicNftCollectionContext();
  const [walletAddress] = useConnectedAccount();

  const [traitSales, fetchTraitSalesPromise, isTraitSalesLoading] =
    useFetchTraitSalesApiCall({
      walletAddress: walletAddress!,
      collectionAddress: dynamicNftCollection.contractAddress,
    });

  const traitSalesByTraitId = useMemo(
    () =>
      traitSales?.reduce(
        (prev, sale) => ({
          ...prev,
          [sale.traitId]: sale,
        }),
        {}
      ),
    [traitSales]
  );

  const traitSalesByTraitCollectionId = useMemo(() => {
    const result: Record<string, TraitSale[]> = {};

    traitSales?.forEach(traitSale => {
      if (result[traitSale.traitCollectionId]) {
        result[traitSale.traitCollectionId].push(traitSale);
      } else {
        result[traitSale.traitCollectionId] = [traitSale];
      }
    });

    return result;
  }, [traitSales]);

  const fetchTraitSales = useCallback(() => {
    fetchTraitSalesPromise({
      walletAddress: walletAddress!,
      collectionAddress: dynamicNftCollection.contractAddress,
    });
  }, [
    walletAddress,
    dynamicNftCollection.contractAddress,
    fetchTraitSalesPromise,
  ]);

  const contextValue = useMemo<StoreContextValue>(
    () => ({
      traitSales: traitSales || [],
      traitSalesByTraitId: traitSalesByTraitId || {},
      traitSalesByTraitCollectionId: traitSalesByTraitCollectionId || {},
      fetchTraitSales,
      isLoading: isTraitSalesLoading,
    }),
    [
      traitSales,
      traitSalesByTraitId,
      fetchTraitSales,
      isTraitSalesLoading,
      traitSalesByTraitCollectionId,
    ]
  );

  useEffect(() => {
    fetchTraitSales();
  }, [fetchTraitSales]);

  return (
    <StoreContext.Provider value={contextValue}>
      {isTraitSalesLoading ? (
        <Center h="50vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </StoreContext.Provider>
  );
};

export default StoreProvider;

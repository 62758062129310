import { BigNumber, BigNumberish } from 'ethers';

const honeyJarSize = BigNumber.from('71000000000000000000000');

export const calculateLevel = (
  honeyEaten: BigNumberish,
  jarSize: BigNumberish = honeyJarSize
): number => {
  let requiredHoney = BigNumber.from('0');
  let currentLevel = 0;

  while (requiredHoney.lte(honeyEaten)) {
    ++currentLevel;
    requiredHoney = requiredHoney.add(
      BigNumber.from(currentLevel).mul(jarSize)
    );
  }

  return currentLevel - 1;
};

export const calculateHoneyConsumptionRequirement = (
  level: number,
  jarSize: BigNumberish = honeyJarSize
): BigNumber => {
  let requiredHoney = BigNumber.from('0');
  let currentLevel = 0;

  while (currentLevel < level) {
    ++currentLevel;
    requiredHoney = requiredHoney.add(
      BigNumber.from(currentLevel).mul(jarSize)
    );
  }

  return requiredHoney;
};

import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useDynamicNftSaleHoneyRewardContractCall = (): [
  honeyReward: BigNumber | undefined,
  readHoneyReward: () => void
] => {
  const [{ data: honeyRewardData }, readHoneyReward] = useContractRead(
    {
      addressOrName: config.contracts.dynamicNftSaleContract.address,
      contractInterface: config.contracts.dynamicNftSaleContract.interface,
    },
    'honeyReward'
  );

  if (honeyRewardData === undefined) {
    return [undefined, readHoneyReward];
  }

  return [honeyRewardData as any as BigNumber, readHoneyReward];
};

export default useDynamicNftSaleHoneyRewardContractCall;

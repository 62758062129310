import { Box, Center, HStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '../components/Avatar';
import BoldSpinner from '../components/BoldSpinner';
import DynamicNftImage from '../components/DynamicNftImage';
import DynamicNftSelect from '../components/DynamicNftSelect';
import useAllOwnedDynamicNftsTokenIdsUtil from '../hooks/useAllOwnedDynamicNftsTokenIdsUtil';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';

type DynamicNftSelectorContainerProps = {
  path: string;
};

const DynamicNftSelectorContainer = ({
  path,
}: DynamicNftSelectorContainerProps) => {
  const { dynamicNft, isLoading } = useDynamicNftContext();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const ownedTokenIds = useAllOwnedDynamicNftsTokenIdsUtil();

  const navigate = useNavigate();

  const handleChange = useCallback(
    (tokenId?: number) => {
      if (Number.isInteger(tokenId)) {
        navigate(`/nft/${tokenId}/${path}`);
      }
    },
    [path, navigate]
  );

  return (
    <HStack spacing="3">
      <Avatar size="10" position="relative">
        <DynamicNftImage
          tokenId={dynamicNft.tokenId}
          objectFit="cover"
          opacity={isLoading ? 0.5 : 1}
          transition="all 0.15s"
        />

        {isLoading && (
          <Center
            position="absolute"
            w="full"
            h="full"
            zIndex="1"
            top="0"
            left="0"
          >
            <BoldSpinner size="md" thickness="3px" />
          </Center>
        )}
      </Avatar>

      <Box w="10rem">
        <DynamicNftSelect
          tokenId={dynamicNft.tokenId}
          ownedTokenIds={ownedTokenIds}
          allTokenIds={dynamicNftTokenIds}
          onChange={handleChange}
          chakraStyles={{
            control: provided => ({
              ...provided,
              fontWeight: 'bold',
              border: 'none',
              borderBottomRadius: 'none',
              fontSize: '2xl',
              p: '0',
              lineHeight: '1',
              borderBottom: '2px solid',
              ':hover': {
                borderBottomColor: 'primary.500',
              },
            }),
            valueContainer: provided => ({
              ...provided,
              p: '0',
            }),
            dropdownIndicator: provided => ({
              ...provided,
              px: '0',
              bg: 'transparent',
            }),
            downChevron: provided => ({
              ...provided,
              width: '9',
              height: 'auto',
            }),
          }}
        />
      </Box>
    </HStack>
  );
};

export default DynamicNftSelectorContainer;

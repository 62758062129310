import { useBoolean } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import UnsupportedCurrencyPopup from '../components/UnsupportedCurrencyPopup';
import useCurrencyContext from '../hooks/useCurrencyContext';
import useTraitCollectionContext from '../hooks/useTraitCollectionContext';

const StoreErrorsContainer: React.FC = () => {
  const { traitCollection } = useTraitCollectionContext();
  const { currency } = useCurrencyContext();
  const [isUnsupportedCurrencyPopupOpen, setIsUnsupportedCurrencyPopupOpen] =
    useBoolean();

  useEffect(() => {
    if (
      traitCollection.currencies.length &&
      !traitCollection.currencies.includes(currency)
    ) {
      setIsUnsupportedCurrencyPopupOpen.on();
    }
  }, [currency, traitCollection, setIsUnsupportedCurrencyPopupOpen]);

  return isUnsupportedCurrencyPopupOpen ? (
    <UnsupportedCurrencyPopup
      isOpen
      onClose={setIsUnsupportedCurrencyPopupOpen.off}
    />
  ) : null;
};

export default StoreErrorsContainer;

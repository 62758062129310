import React from 'react';

const GalleryModeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.5"
      y="20.0007"
      width="7.5"
      height="7.5"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="2.5"
      y="2.49927"
      width="25"
      height="15"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="11.2495"
      y="20.0007"
      width="7.5"
      height="7.5"
      rx="2"
      fill="currentColor"
    />
    <rect
      x="20"
      y="20.0007"
      width="7.5"
      height="7.5"
      rx="2"
      fill="currentColor"
    />
  </svg>
);

export default GalleryModeIcon;

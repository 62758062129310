import { Center } from '@chakra-ui/react';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import BoldSpinner from '../components/BoldSpinner';
import WardrobeContext, {
  WardrobeContextValue,
} from '../contexts/WardrobeContext';
import useFetchTraitIdsInWalletApiCall from '../hooks/api/useFetchTraitIdsInWalletApiCall';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';

type WardrobeProviderProps = {};

const WardrobeProvider: FunctionComponent<WardrobeProviderProps> = ({
  children,
}) => {
  const { dynamicNftCollection } = useDynamicNftCollectionContext();
  const [walletAddress] = useConnectedAccount();

  const [ownedTraits, fetchTraits, isOwnedTraitsLoading] =
    useFetchTraitIdsInWalletApiCall({
      walletAddress: walletAddress!,
      dynamicNftCollectionAddress: dynamicNftCollection.contractAddress,
    });

  const ownedTraitsById = useMemo(
    () =>
      ownedTraits?.reduce(
        (prev, trait) => ({
          ...prev,
          [trait.traitId]: trait,
        }),
        {}
      ),
    [ownedTraits]
  );

  const fetchOwnedTraits = useCallback(() => {
    fetchTraits({
      walletAddress: walletAddress!,
      dynamicNftCollectionAddress: dynamicNftCollection.contractAddress,
    });
  }, [walletAddress, dynamicNftCollection.contractAddress, fetchTraits]);

  const contextValue = useMemo<WardrobeContextValue>(
    () => ({
      ownedTraits: ownedTraits || [],
      ownedTraitsById: ownedTraitsById || {},
      fetchOwnedTraits,
      isLoading: isOwnedTraitsLoading,
    }),
    [ownedTraits, ownedTraitsById, fetchOwnedTraits, isOwnedTraitsLoading]
  );

  useEffect(() => {
    fetchOwnedTraits();
  }, [fetchOwnedTraits]);

  return (
    <WardrobeContext.Provider value={contextValue}>
      {isOwnedTraitsLoading ? (
        <Center h="50vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </WardrobeContext.Provider>
  );
};

export default WardrobeProvider;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import config from '../../constants/baseConfig';
import useCaptureRpcException from '../useCaptureRpcException';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';

const useUnstakeFancyBearTraitsContractCall = (
  dynamicNftTokenId: number,
  categoriesToUnstake: string[],
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const [{ data, loading: isLoading, error: writeError }, unstake] =
    useContractWrite(
      {
        addressOrName: config.contracts.traitStakingContract.address,
        contractInterface: config.contracts.traitStakingContract.interface,
      },
      'unstakeTraits',
      {
        args: [dynamicNftTokenId, categoriesToUnstake],
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      confirmations: 1,
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [unstake, isLoading || isWaiting];
};

export default useUnstakeFancyBearTraitsContractCall;

import { BigNumber } from 'ethers';
import config from '../../constants/baseConfig';
import { useContractRead } from 'wagmi';

const useVestingAmountRemainingInBearContractCall = (
  tokenId: number
): [
  remaining: BigNumber | undefined,
  readVestingAmountRemaining: () => void
] => {
  const [{ data: remaining }, readVestingAmountRemaining] = useContractRead(
    {
      addressOrName: config.contracts.honeyVestingContract.address,
      contractInterface: config.contracts.honeyVestingContract.interface,
    },
    'getVestingAmountRemainingInBearById',
    {
      args: tokenId,
    }
  );

  return [remaining as any as BigNumber, readVestingAmountRemaining];
};

export default useVestingAmountRemainingInBearContractCall;

import { Badge, BadgeProps, Text } from '@chakra-ui/react';

type SupplyBadgeProps = {
  totalSupply: number;
  maxSupply: number;
} & BadgeProps;

const SupplyBadge = ({
  totalSupply,
  maxSupply,
  size = 'md',
  ...rest
}: SupplyBadgeProps) => {
  const isSoldOut = totalSupply >= maxSupply;

  return (
    <Badge
      borderRadius="full"
      fontWeight="semibold"
      bg="dark.700"
      color="bright.50"
      px="0.5em"
      lineHeight="1.5em"
      fontSize={size === 'sm' ? '0.625rem' : undefined}
      {...rest}
    >
      <Text as="span">{isSoldOut ? 'S' : `${totalSupply} `}</Text>
      <Text as="span" fontWeight="normal" color="dark.50">
        /
      </Text>
      <Text as="span">{isSoldOut ? 'O' : ` ${maxSupply}`}</Text>
    </Badge>
  );
};

export default SupplyBadge;

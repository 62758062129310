import { useMemo } from 'react';
import * as dynamicNftCollectionsApi from '../../api/dynamicNftCollectionsApi';
import { FetchDynamicNftReqData } from '../../api/dynamicNftCollectionsApi';
import V2 from '../../types/dynamicNft';
import useApiCall from '../useApiCall';

const useFetchDynamicNftApiCall = (data: Partial<FetchDynamicNftReqData>) => {
  const memoizedData = useMemo(
    () => data,
    [data.collectionAddress, data.dynamicNftTokenId]
  );

  const promise = useMemo(
    () => (data: Partial<FetchDynamicNftReqData>) => {
      if (data.collectionAddress && data.dynamicNftTokenId !== undefined) {
        return dynamicNftCollectionsApi.fetchDynamicNft(
          data as FetchDynamicNftReqData
        );
      }
      return Promise.resolve(undefined);
    },
    []
  );

  return useApiCall<V2 | undefined, Partial<FetchDynamicNftReqData>>(
    promise,
    memoizedData
  );
};

export default useFetchDynamicNftApiCall;

import { BigNumber } from 'ethers';
import sumBigNumbers from '../utils/bigNumberUtils';
import { useMemo } from 'react';

const useBigNumbersSumUtil = (bigNumbers?: BigNumber[]) =>
  useMemo(
    () => (bigNumbers ? sumBigNumbers(bigNumbers) : BigNumber.from('0')),
    [bigNumbers]
  );

export default useBigNumbersSumUtil;

import {
  Badge,
  Box,
  Button,
  HStack,
  Icon,
  Progress,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { parseEther } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import { Currency, DynamicNftSaleTier } from '../types';
import EtherPrice from './EtherPrice';
import IncentiveTile, { IncentiveTileProps } from './IncentiveTile';

type IncentivesContainerProps = StackProps & {
  fancyBears?: number[];
};

const IncentivesContainer = ({
  fancyBears = [],
  ...rest
}: IncentivesContainerProps) => {
  const translate = useTranslate();
  const { dynamicNftTokenIds } = useDynamicNftCollectionContext();
  const { fancyBearsInWallet } = useWalletAssetsContext();
  const [saleTiers, setSaleTiers] = useState<DynamicNftSaleTier[]>();

  const isBearHolder = Boolean(fancyBearsInWallet?.length);

  let v2TotalSupply = dynamicNftTokenIds.length || 0;
  if (
    config.dynamicNft.reservedSalesCount &&
    v2TotalSupply < config.dynamicNft.reservedSalesCount
  ) {
    v2TotalSupply = config.dynamicNft.reservedSalesCount;
  }

  useEffect(() => {
    const fetchSaleTiers = async () => {
      const res = await axios.get<DynamicNftSaleTier[]>(
        `${config.urls.publicUrl}/sale-tiers.json`
      );
      setSaleTiers(
        res.data.map(tier => ({
          ...tier,
          honeyReward: parseEther(tier.honeyReward.toString()),
          price: parseEther(tier.price.toString()),
        }))
      );
    };

    fetchSaleTiers();
  }, []);

  return (
    <VStack w="full" align="stretch" spacing="2px" {...rest}>
      {saleTiers &&
        saleTiers.map(({ maxSupply, minSupply, price, honeyReward }, index) => {
          const pheaseMin = Math.min(
            Math.max(v2TotalSupply - (minSupply - 1), 0),
            maxSupply
          );
          const pheaseMax = maxSupply - (minSupply - 1);
          const progress = (pheaseMin / pheaseMax) * 100;

          const progressLabel = `${pheaseMin} / ${pheaseMax}`;
          const displayTitle = `${translate(
            `sales:title:${index + 1}`
          )}: ${minSupply} - ${maxSupply} sales`;

          let status: IncentiveTileProps['status'] = 'active';
          if (v2TotalSupply < maxSupply && v2TotalSupply >= minSupply - 1) {
            status = 'done';
          } else if (v2TotalSupply >= maxSupply) {
            status = 'inactive';
          }

          return (
            <Box key={index}>
              <IncentiveTile
                title={displayTitle}
                status={status}
                icon={
                  status === 'active' ? (
                    <Icon as={FaCircle} w="2" h="2" />
                  ) : undefined
                }
                details={
                  progress !== undefined ? (
                    <HStack align="center" mt={1}>
                      <Progress
                        value={progress}
                        h="2"
                        colorScheme="primary"
                        w="20"
                      />

                      {progressLabel && (
                        <Text fontSize="xs" opacity="0.6">
                          {progressLabel}
                        </Text>
                      )}
                    </HStack>
                  ) : undefined
                }
              >
                <HStack spacing="0">
                  <VStack spacing="0" align="flex-start" w="20">
                    <Text
                      opacity="0.6"
                      fontSize="0.625rem"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      {translate('common:reward')}
                    </Text>
                    <EtherPrice
                      opacity={status === 'inactive' ? '0.6' : undefined}
                      currency={Currency.Honey}
                      amount={honeyReward}
                      iconColor="primary.500"
                      fontWeight="semibold"
                      fontSize="sm"
                      iconSize="4"
                    />
                  </VStack>
                  <VStack spacing="0" align="flex-start" w="12">
                    <Text
                      opacity="0.6"
                      fontSize="0.625rem"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      {translate('common:price')}
                    </Text>
                    <EtherPrice
                      opacity={status === 'inactive' ? '0.6' : undefined}
                      currency={Currency.Eth}
                      amount={price}
                      asPrice
                      iconColor="primary.500"
                      fontWeight="semibold"
                      fontSize="sm"
                      iconSize="4"
                    />
                  </VStack>
                </HStack>
              </IncentiveTile>
            </Box>
          );
        })}

      <IncentiveTile
        mt="3"
        status={isBearHolder ? 'done' : 'active'}
        icon={
          !isBearHolder ? <Icon as={FaCircle} w="2.5" h="2.5" /> : undefined
        }
        title={translate('incentives:bearHolder:title', {
          discount: config.dynamicNft.fancyBearHolderSaleDiscount,
        })}
        details={
          <Text fontSize="xs" opacity="0.6" mt="0.5">
            {isBearHolder
              ? translate('incentives:bearHolder:description:done', {
                  discount: config.dynamicNft.fancyBearHolderSaleDiscount,
                  amount: fancyBearsInWallet?.length,
                })
              : translate('incentives:bearHolder:description')}
          </Text>
        }
      >
        {isBearHolder ? (
          <VStack spacing="1" align="flex-start" mr="1">
            <Text
              opacity="0.6"
              fontSize="0.625rem"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              {translate('common:discount')}
            </Text>
            <Badge bg="primary.500" color="dark.900" px="2">
              {translate('incentives:bearHolder:badge', {
                discount: config.dynamicNft.fancyBearHolderSaleDiscount,
              })}
            </Badge>
          </VStack>
        ) : (
          <Button size="xs" variant="outline">
            {translate('common:buy')}
          </Button>
        )}
      </IncentiveTile>
    </VStack>
  );
};

export default IncentivesContainer;

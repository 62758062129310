import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../../constants/baseConfig';

const useErc20BalanceContractCall = (
  walletAddress?: string
): [honeyBalance: BigNumber | undefined, readErc20Balance: () => void] => {
  const { erc20Contract } = config.contracts;
  const [{ data }, readErc20Balance] = useContractRead(
    {
      addressOrName: erc20Contract.address,
      contractInterface: erc20Contract.interface,
    },
    'balanceOf',
    {
      args: walletAddress,
    }
  );

  return [data as BigNumber | undefined, readErc20Balance];
};

export default useErc20BalanceContractCall;

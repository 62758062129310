import { Box, Button, Input, Text, useBoolean, VStack } from '@chakra-ui/react';
import { plural } from 'pluralize';
import React, { useCallback, useState } from 'react';
import * as reserveApi from '../api/reserveApi';
import CopyInput from '../components/CopyInput';
import InfoTile from '../components/InfoTile';
import SimplePopup from '../components/SimplePopup';
import StaticNftMultiselect from '../components/StaticNftMultiselect';
import TermsPopup from '../components/TermsPopup';
import config from '../constants/baseConfig';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';
import { Currency } from '../types';
import { getCurrencySymbol } from '../utils/currencyUtils';
import { formatEtherBalance } from '../utils/numberUtils';

type ReserveDynamicNftContainerProps = {
  trigger: React.ReactElement;
};

const ReserveDynamicNftContainer = ({
  trigger,
}: ReserveDynamicNftContainerProps) => {
  const translate = useTranslate();
  const { dynamicNftCollection } = useDynamicNftCollectionContext();
  const [tokenIds, setTokenIds] = useState<number[]>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const snackbar = useSnackbar();

  const [isInstructionsPopupOpen, setIsInstructionsPopup] = useBoolean();
  const [isTermsPopupOpen, setIsTermsPopupOpen] = useBoolean();
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useBoolean();

  const handleConfirm = useCallback(() => {
    if (!tokenIds || !walletAddress || walletAddress.length !== 42) {
      snackbar(
        'error',
        translate('reserve:confirm:invalid', {
          staticNftName: config.staticNft.staticNftName,
        })
      );
      return;
    }

    setIsInstructionsPopup.off();
    setIsTermsPopupOpen.on();
  }, [
    tokenIds,
    translate,
    walletAddress,
    snackbar,
    setIsInstructionsPopup,
    setIsTermsPopupOpen,
  ]);

  const handleTermsConsent = useCallback(async () => {
    setIsTermsPopupOpen.off();

    if (!tokenIds || !walletAddress) {
      return;
    }

    try {
      const collectionAddress = dynamicNftCollection.contractAddress;

      await reserveApi.reserveTokens({
        tokenIds,
        walletAddress,
        collectionAddress,
      });

      setIsSuccessPopupOpen.on();
    } catch (error: any) {
      snackbar('error', error?.message || translate('error:default'));
    }

    setIsSuccessPopupOpen.on();
  }, [
    tokenIds,
    walletAddress,
    dynamicNftCollection,
    setIsSuccessPopupOpen,
    setIsTermsPopupOpen,
    snackbar,
    translate,
  ]);

  return (
    <>
      {React.cloneElement(trigger, { onClick: setIsInstructionsPopup.on })}

      {isInstructionsPopupOpen && (
        <SimplePopup
          isOpen
          title={translate('reserve:confirm:title', {
            dynamicNftName: config.dynamicNft.dynamicNftName,
          })}
          onClose={setIsInstructionsPopup.off}
        >
          <VStack mt="2" spacing="4">
            <Text textAlign="center" fontSize="sm" opacity="0.8" maxW="25em">
              {translate('reserve:confirm:description', {
                staticNftName: config.staticNft.staticNftName,
              })}
            </Text>

            <VStack w="full" spacing="2px">
              {new Array(4).fill('reserve:confirm:step').map((key, index) => (
                <InfoTile
                  key={index + 1}
                  index={index + 1}
                  text={translate(`${key}:${index + 1}`, {
                    price: formatEtherBalance(config.dynamicNft.reservePrice),
                    currency: getCurrencySymbol(Currency.Eth),
                    staticNftName: config.staticNft.staticNftName,
                    dynamicNftName: config.dynamicNft.dynamicNftName,
                  })}
                />
              ))}
            </VStack>

            <VStack w="full" spacing="3">
              <Box w="full">
                <StaticNftMultiselect
                  placeholder={translate(
                    'reserve:confirm:tokenIds:placeholder',
                    {
                      staticNftName: plural(config.staticNft.staticNftName),
                    }
                  )}
                  onChange={tokenIds => setTokenIds(tokenIds)}
                />
              </Box>

              <Input
                placeholder={translate('reserve:confirm:address:placeholder', {
                  staticNftName: config.staticNft.staticNftName,
                })}
                size="lg"
                onChange={event => setWalletAddress(event.target.value)}
              />
            </VStack>

            <Button w="full" onClick={handleConfirm}>
              {translate('reserve:confirm:submit')}
            </Button>
          </VStack>
        </SimplePopup>
      )}

      {tokenIds && walletAddress && isTermsPopupOpen && (
        <TermsPopup
          isOpen
          isSignatureRequired={false}
          walletAddress={walletAddress}
          tokenIds={tokenIds}
          onSuccess={handleTermsConsent}
          onClose={setIsTermsPopupOpen.off}
        />
      )}

      {isSuccessPopupOpen && (
        <SimplePopup
          isOpen
          title={translate('reserve:success:title')}
          onClose={setIsSuccessPopupOpen.off}
        >
          <Box>
            <VStack p="6" bg="dark.800" borderRadius="md" spacing="4" mt="5">
              <Text
                textAlign="center"
                maxW="20em"
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: translate('reserve:success:description', {
                    price: formatEtherBalance(
                      config.dynamicNft.reservePrice.mul(tokenIds?.length || 1)
                    ),
                    currency: getCurrencySymbol(Currency.Eth),
                    staticNftName: config.staticNft.staticNftName,
                  }),
                }}
              />

              <CopyInput
                value={config.dynamicNft.reserveWalletAddress}
                bg="dark.900"
                borderRadius="lg"
              />

              <Text textAlign="center" maxW="20em" fontSize="sm" opacity="0.6">
                {translate('reserve:success:note')}
              </Text>
            </VStack>

            <Button onClick={setIsSuccessPopupOpen.off} mt="4" w="full">
              {translate('reserve:success:ok')}
            </Button>
          </Box>
        </SimplePopup>
      )}
    </>
  );
};

export default ReserveDynamicNftContainer;

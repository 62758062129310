import { useEffect } from 'react';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';

const useSnackbarSuccess = (data?: { message?: string }) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (data) {
      snackbar('success', data?.message || translate('success:default'));
    }
  }, [data, snackbar, translate]);
};

export default useSnackbarSuccess;

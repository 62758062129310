import { Heading } from '@chakra-ui/react';
import { plural } from 'pluralize';
import config from '../constants/baseConfig';
import useTranslate from '../hooks/useTranslate';
import MembersSwiper from './MembersSwiper';
import Panel, { PanelProps } from './Panel';

type JoinTheClubPanelProps = Omit<PanelProps, 'children'> & {
  slidesPerView?: number;
};

const JoinTheClubPanel = ({
  slidesPerView = 3.25,
  ...props
}: JoinTheClubPanelProps) => {
  const translate = useTranslate();

  return (
    <Panel py="6" px="0" {...props}>
      <Heading fontSize="lg" px="6" mb="3">
        {translate('joinTheClub:title', {
          dynamicNftName: plural(config.dynamicNft.dynamicNftName),
        })}
      </Heading>

      <MembersSwiper
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
        slidesPerView={slidesPerView}
      />
    </Panel>
  );
};

export default JoinTheClubPanel;
